import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

export const filterParameters = payload => ({
	charge_rate: charge_rate => {
		const selected_rate = payload.filters.charge_rate.filter(
			charge_rate => {
				return charge_rate.isChecked === true;
			},
		);
		if (selected_rate.length !== 1) {
			return true;
		} else {
			return charge_rate === selected_rate[0].value;
		}
	},
	property_class: property_class => true,
	property_type: property_type => {
		if (payload.filters.property_type === '') {
			return property_type.includes(payload.filters.property_type);
		} else {
			return property_type === payload.filters.property_type;
		}
	},
	no_of_bedrooms: no_of_bedrooms => {
		if (payload.filters.no_of_bedrooms === '') {
			return String(no_of_bedrooms).includes(
				payload.filters.no_of_bedrooms,
			);
		} else {
			return (
				Number(no_of_bedrooms) ===
				Number(payload.filters.no_of_bedrooms)
			);
		}
	},
	price: price => {
		if (parseFloat(payload.filters.price_max) >= 50000) {
			return price >= payload.filters.price_min;
		} else {
			return (
				price >= payload.filters.price_min &&
				price <= payload.filters.price_max
			);
		}
	},
	town: town => true,
	region: region => region === payload.filters.region,
});

export const isBookable = (property, checkIn, checkOut) => {
	const bookings = property.bookings ?? [];
	const reservedDates = property.reservedDates ?? [];
	const blockedDates = [...bookings, ...reservedDates];
	if (blockedDates.length === 0) return true;

	for (let blockedDate of blockedDates) {
		const startDate = new Date(blockedDate.startDate);
		const endDate = new Date(blockedDate.endDate);
		const checkInDate = new Date(checkIn);
		const checkOutDate = new Date(checkOut);

		const range = moment.range(startDate, endDate);
		const checkRange = moment.range(checkInDate, checkOutDate);

		if (
			range.contains(new Date(checkIn)) ||
			range.contains(new Date(checkOut)) ||
			checkRange.contains(new Date(blockedDate.startDate)) ||
			checkRange.contains(new Date(blockedDate.endDate))
		) {
			return false;
		}
	}

	return true;
};
