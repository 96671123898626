import HostProfileTypes from './host-profile.types';

export const fetchHostProfileDetailsStart = uid => ({
	type: HostProfileTypes.FETCH_HOST_PROFILE_DETAILS_START,
	payload: uid,
});

export const fetchHostProfileDetailsSuccess = userDetails => ({
	type: HostProfileTypes.FETCH_HOST_PROFILE_DETAILS_SUCCESS,
	payload: userDetails,
});

export const fetchHostProfileDetailsFailure = error => ({
	type: HostProfileTypes.FETCH_HOST_PROFILE_DETAILS_FAILURE,
	payload: error,
});

export const fetchAllUserPropertiesStart = uid => ({
	type: HostProfileTypes.FETCH_ALL_USER_PROPERTIES_START,
	payload: uid,
});

export const fetchAllUserPropertiesSuccess = properties => ({
	type: HostProfileTypes.FETCH_ALL_USER_PROPERTIES_SUCCESS,
	payload: properties,
});

export const fetchAllUserPropertiesFailure = error => ({
	type: HostProfileTypes.FETCH_ALL_USER_PROPERTIES_FAILURE,
	payload: error,
});
