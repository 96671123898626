import styled, { css } from 'styled-components';
import { palette, typeScale } from '../utils';
import { Link } from 'react-router-dom';

//Divs & Containers
export const Container = styled.div`
	flex-grow: 1;
	margin: 0 auto;
	padding: 0 32px;
	position: relative;
	width: ${props => (props.hundred ? '100%' : 'auto')};
	height: 100%;
	align-items: center;
	justify-content: center;

	@media (min-width: 1024px) {
		max-width: 960px;
	}
	@media (min-width: 1216px) {
		max-width: 1120px;
	}
	@media (min-width: 1408px) {
		max-width: 1300px;
	}
	@media (min-width: 1700px) {
		max-width: 1600px;
	}
	@media (max-width: 600px) {
		padding: 0 5vw;
	}
	${props =>
		props.fluid &&
		css`
			padding: 0;
			margin: 0;
			max-width: 100% !important;
		`}
`;

export const Flex = styled.div`
	display: ${props => (props.inline ? 'inline-flex' : 'flex')};
	position: relative;
	align-items: center;

	${({ fullHeight }) =>
		fullHeight &&
		css`
			height: 100%;
		`}

	${props =>
		props.vertical &&
		css`
			flex-direction: column;
		`};
	${props =>
		props.top &&
		css`
			align-items: flex-start;
		`};
	${props =>
		props.wrapContainer &&
		css`
			flex-wrap: wrap;
		`};

	${props =>
		props.vLeft &&
		css`
			align-items: flex-start;
		`};

	${props =>
		props.spaceBetween &&
		css`
			justify-content: space-between;
		`};

	${props =>
		props.center &&
		css`
			justify-content: center;
		`};
	@media only screen and (max-width: 600px) {
		${props =>
			props.verticalRPhone &&
			css`
				flex-direction: column-reverse;
			`};
	}
`;

export const Section = styled.section`
	padding: 100px 0 0;
	display: flex;
	align-items: ${props => (props.left ? 'flex-start' : 'center')};
	flex-direction: column;
	@media only screen and (min-width: 600px) {
		padding: 100px 0 0;
	}
`;

export const Grid = styled.div`
	display: grid;
	grid-gap: ${props => props.gapPhone || '0'};
	grid-template-rows: auto;
	grid-template-columns: ${props =>
		typeof props.phoneNum === 'object'
			? `${props.phoneNum.toString()}`
			: `repeat(${props.phoneNum}, 1fr)`};
	align-items: ${props => (props.top ? 'flex-start' : 'center')};
	justify-items: ${props => (props.phoneLeft ? 'flex-start' : 'center')};
	@media only screen and (min-width: 600px) {
		grid-gap: ${props => props.gap || '150px'};
		grid-template-columns: ${props =>
			typeof props.num === 'object'
				? `${props.num.toString()}`
				: `repeat(${props.num}, 1fr)`};
		${props =>
			props.auto &&
			css`
				grid-template-columns: auto;
			`};
		width: 100%;
		justify-items: ${props => (props.left ? 'flex-start' : 'center')};
	}
	${({ oneColumnIPad }) =>
		oneColumnIPad &&
		css`
			@media only screen and (max-width: 992px) {
				grid-template-columns: 1fr;
			}
		`}
`;

//Texts
export const HeaderOne = styled.h1`
	font-size: ${typeScale.headerTwo};
	display: block;
	line-height: 120%;
	font-weight: bold;
	@media only screen and (min-width: 600px) {
		font-size: ${typeScale.headerOne};
	}
`;

export const HeaderTwo = styled.h2`
	font-size: ${typeScale.headerThree};
	display: block;
	line-height: 120%;
	font-weight: bold;
	@media only screen and (min-width: 600px) {
		font-size: ${typeScale.headerTwo};
	}
`;

export const HeaderThree = styled.h3`
	font-size: ${typeScale.headerFour};
	text-align: left;
	display: block;
	line-height: 140%;
	font-weight: bold;
	@media only screen and (min-width: 600px) {
		font-size: ${typeScale.headerThree};
	}
`;

export const HeaderFour = styled.h4`
	font-size: ${typeScale.headerFive};
	text-align: left;
	display: block;
	line-height: 140%;
	font-weight: 600;
	@media only screen and (min-width: 600px) {
		font-size: ${typeScale.headerFour};
	}
`;

export const HeaderFive = styled.h5`
	font-size: ${typeScale.paragraph};
	text-align: left;
	display: block;
	line-height: 150%;
	font-weight: 600;
	@media only screen and (min-width: 600px) {
		font-size: ${typeScale.headerFive};
	}
`;

export const Paragraph = styled.p`
	font-size: ${typeScale.paragraph};
	display: block;
	line-height: 180%;
	${props =>
		props.right &&
		css`
			text-align: right;
		`}

	${props =>
		props.bold &&
		css`
			font-weight: 500;
		`}
`;

export const Small = styled.small`
	font-size: ${typeScale.small};
	display: block;
	line-height: 150%;
`;

export const Overlay = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	background-color: rgba(0, 0, 0, 0.6);
`;

export const HorizontalLine = styled.div`
	width: 100%;
	height: 1px;
	background-color: ${palette.lightGrey};
	margin: 20px 0;
	${({ light }) =>
		light &&
		css`
			background-color: ${palette.lightestGrey};
			height: 2px;
		`}
`;

export const ShadowCardHorizontalLine = styled(HorizontalLine)`
	margin-left: -30px;
	width: calc(100% + 60px);
`;

export const DecoratedHeader = styled(HeaderOne)`
	position: relative;
	display: inline-block;
	text-align: center;

	&::after {
		content: '';
		position: absolute;
		height: 8px;
		width: 40%;
		max-width: 120px;
		background-color: black;
		top: 100%;
		left: 0;
	}

	@media only screen and (max-width: 600px) {
		&::after {
			display: none;
		}
	}
`;

export const UnderlineLink = styled(Link)`
	text-decoration: underline;
	font-size: 1rem;
	color: black;

	&:hover {
		color: ${palette.grey};
	}
`;

export const HiddenLink = styled.p`
	color: ${palette.grey};
	text-decoration: underline;
	font-size: 1rem;
	cursor: pointer;

	&:hover {
		color: black;
	}
`;

export const Sub = styled.span`
	color: ${palette.darkGrey};
`;

export const ModalWrapper = styled.div`
	background: rgba(0, 0, 0, 0.7);
	height: 100vh;
	width: 100vw;
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow-y: hidden;

	span {
		cursor: pointer;
	}
`;

export const ModalCard = styled.div`
	width: 100%;
	height: auto;
	background-color: white;
	border-radius: 8px;
	margin-top: -60px;
	transform: translateY(200vh);
	@media only screen and (min-width: 600px) {
		width: 576px;
		margin-top: 0;
		transform: translateY(200vh);
	}
`;

export const ModalHead = styled.div`
	border-bottom: 1px solid ${palette.lightGrey};
	padding: 15px 30px;
	position: relative;

	h5 {
		margin: 0;
		padding: 0;
		text-align: center;
	}
`;

export const Close = styled.div`
	position: absolute;
	font-size: 1.4rem;
	top: calc(27px - 0.7rem);
	right: 30px;
	cursor: pointer;
`;

export const ModalBody = styled.div`
	padding: 10px 5vw 20px;
	max-height: 70vh;
	overflow-y: auto;

	p {
		margin: 0 0 10px 0;
	}

	${({ numbers }) =>
		numbers &&
		css`
			padding: 0 !important;

			p {
				padding: 15px 5vw;
				margin: 0;
			}

			a {
				color: black;
			}

			a:nth-child(2n - 1) {
				p {
					background-color: rgba(33, 130, 132, 0.2);
				}
			}
		`}
	button {
		margin-bottom: 20px;
	}

	@media only screen and (min-width: 600px) {
		padding: 10px 30px 10px;
	}
`;

export const Banner = styled.div`
	padding: 30px 0 0;
	@media only screen and (min-width: 600px) {
		padding: 90px 0 0;
	}
`;

export const ShadowCard = styled.div`
	padding: 30px;
	border-radius: 8px;
	background-color: white;
	-webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;

export const ProfileAvatar = styled.img`
	width: ${({ size }) => (size ? size : '50px')};
	height: ${({ size }) => (size ? size : '50px')};
	border-radius: 50%;
	object-fit: cover;
`;

export const BannerWrapper = styled.div`
	height: 100vh;
	background-color: #4f0226;
	width: 100%;
	background-image: ${({ image }) => `url(${image})`};
	background-position: top center;
	background-repeat: no-repeat;
	background-size: cover;

	&::before {
		content: '';
		height: 100%;
		width: 100%;
		z-index: 1;
		left: 0;
		top: 0;
		background-color: black;
		opacity: ${({ opacity }) => opacity};
		position: absolute;
	}

	& > div {
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 2;
	}
`;

export const HorizontalSpacedDivider = styled(Flex)`
	> * {
		position: relative;
		margin: 0 ${({ right }) => (right ? right + 'px' : '14px')} 0 0 !important;
	}

	> *::after {
		position: absolute;
		right: ${({ right }) =>
			right ? `-${right / 2}px !important` : '-7px'};
		top: 0;
		height: 100%;
		width: 2px;
		background-color: ${palette.green};
		opacity: 0.6;
		content: '';
		border-radius: 5px;
	}

	> *:last-of-type {
		margin-right: 0 !important;
		margin-left: 0 !important;

		&::after {
			content: none;
		}
	}

	@media (max-width: 600px) {
		${({ phoneVertical }) =>
			phoneVertical &&
			css`
				flex-direction: column !important;
				> * {
					margin: 0 0
						${({ right }) => (right ? right + 'px' : '14px')} 0 !important;
				}
				> *::after {
					left: 0;
					top: ${({ right }) =>
						right
							? `calc(100% + ${right / 2}px) !important`
							: 'calc(100% + 7px)'};
					height: 2px;
					width: 100%;
				}
				> *:last-of-type {
					margin: 0 !important;
				}
			`}
	}
`;

export const LikeSvg = styled.svg`
	cursor: pointer;
	${({ light }) =>
		light &&
		css`
			path:first-of-type {
				fill: ${palette.grey} !important;
			}

			path:last-of-type {
				fill: transparent !important;
			}
		`};
	${({ liked }) =>
		liked &&
		css`
			path:last-of-type {
				fill: ${palette.green} !important;
			}
		`};
	${({ liked, light }) =>
		light &&
		liked &&
		css`
			path:first-of-type {
				fill: ${palette.green} !important;
			}
		`}
`;

export const DirtyHash = styled.div`
	margin-top: ${({ size }) => `-${size}`};
	padding-bottom: ${({ size }) => size};
	display: block;
`;

//variables
const tooltipColor = 'rgba(0, 0, 0, .8)';
const tooltipArrowSize = '7px';
let tooltipMargin = '100%';

export const ToolTip = styled.div`
	position: relative;
	//data tooltip
	@media only screen and (min-width: ${({ phoneIncluded }) =>
			phoneIncluded ? '0' : '1200px'}) {
		&::before,
		&::after {
			position: absolute;
			${({ top }) =>
				!top &&
				css`
					top: 50%;
				`};
			transform-origin: ${({ top, left, right }) =>
				top && left
					? 'bottom left'
					: top && right
					? 'bottom right'
					: top
					? 'bottom center'
					: left
					? 'right center'
					: 'left center'};
			transition: 150ms transform;
			z-index: 5;
		}

		&::before {
			content: attr(data-tooltip);
			padding: 0.5rem;
			transform: ${({ top, right, left }) =>
				top && right
					? 'translateX(-100%) scale(0)'
					: top && left
					? 'translateX(0) scale(0)'
					: top
					? 'translateX(-50%) scale(0)'
					: left
					? 'translate(-100%, -50%) scale(0)'
					: 'translateY(-50%) scale(0)'};
			left: ${({ active, top, right, left }) =>
				top && right
					? 'calc(100% + 5px)'
					: top && left
					? '-5px'
					: top
					? '50%'
					: left
					? `-${tooltipArrowSize}`
					: active
					? `calc(${tooltipMargin} + 10px + ${tooltipArrowSize})`
					: `calc(${tooltipMargin} + ${tooltipArrowSize})`};
			bottom: ${({ top }) =>
				top && `calc(${tooltipMargin} + 5px + ${tooltipArrowSize})`};
			background-color: ${tooltipColor};
			width: max-content;
			max-width: 200px;
			//max-height: 100%;
			color: white;
			border-radius: 0.3rem;
			text-align: center;
		}

		&::after {
			content: '';
			border: ${tooltipArrowSize} solid transparent;
			transform: ${({ top, right, left }) =>
				top && right
					? 'translateX(-100%) scale(0)'
					: top && left
					? 'translateX(0) scale(0)'
					: top
					? 'translateX(-50%) scale(0)'
					: left
					? 'translate(-100%, -50%) scale(0)'
					: 'translateY(-50%) scale(0)'};
			left: ${({ active, top, right, left }) =>
				top && right
					? '100%'
					: top && left
					? '0'
					: top
					? '50%'
					: left
					? `calc(${tooltipArrowSize})`
					: active
					? `calc(${tooltipMargin} + 10px - ${tooltipArrowSize})`
					: `calc(${tooltipMargin} - ${tooltipArrowSize})`};
			bottom: ${({ top }) =>
				top && `calc(${tooltipMargin} + 5px - ${tooltipArrowSize})`};
			${({ top, left }) =>
				top
					? css`
							border-top-color: ${tooltipColor};
					  `
					: left
					? css`
							border-left-color: ${tooltipColor};
					  `
					: css`
							border-right-color: ${tooltipColor};
					  `};
			${({ top }) => top && css`margin-top: -${tooltipArrowSize})`};
			transform-origin: ${({ top, left }) =>
				top ? 'top center' : left ? 'left center' : 'right center'};
		}

		&:hover::before,
		&:hover::after {
			transform: ${({ top, right, left }) =>
				top && right
					? 'translateX(-100%) scale(1)'
					: top && left
					? 'translateX(0) scale(1)'
					: top
					? 'translateX(-50%) scale(1)'
					: left
					? 'translate(-100%, -50%) scale(1)'
					: 'translateY(-50%) scale(1)'};
		}
	}
`;
