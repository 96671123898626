import { createSelector } from 'reselect';

const selectModal = state => state.modal;

export const selectShowModal = createSelector(
	[selectModal],
	modal => modal.showModal,
);

export const selectShowSignUp = createSelector(
	[selectModal],
	modal => modal.showSignUp,
);

export const selectShowSignIn = createSelector(
	[selectModal],
	modal => modal.showSignIn,
);
