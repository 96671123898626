import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './contexts/authIsLoaded';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import MapKeyProvider from './contexts/mapKeyProvider';

ReactDOM.render(
	<AuthProvider>
		<MapKeyProvider>
			<Provider store={store}>
				<PersistGate persistor={persistor}>
					<BrowserRouter>
						<App />
					</BrowserRouter>
				</PersistGate>
			</Provider>
		</MapKeyProvider>
	</AuthProvider>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
