import { takeLatest, call, put, all } from 'redux-saga/effects';
import { firestore } from '../../firebase/firebase.config';
import {
	fetchPropertyDetailsFailure,
	fetchSimilarPropertiesFailure,
	fetchSimilarPropertiesStart,
	fetchSimilarPropertiesSuccess,
	getUserData,
} from './property-details.actions';
import PropertyDetailsTypes from './property-details.types';
import { convertPropertySnapshotToMap } from '../../firebase/firebase.helpers';

//  Fetch property details
function* fetchDetails({ payload }) {
	try {
		const allPropertiesRef = firestore.collection('properties');
		const propertyRef = allPropertiesRef.doc(payload);
		const doc = yield propertyRef.get();
		// yield put(
		// 	fetchPropertyDetailsSuccess({
		// 		...doc.data(),
		// 	}),
		// );

		//  Get ads hosted by owner
		const ownerAds = yield allPropertiesRef
			.where('user_id', '==', doc.data().user_id)
			.where('isComplete', '==', true);
		const ownerSnapshot = yield ownerAds.get();
		const transformedData = yield call(
			convertPropertySnapshotToMap,
			ownerSnapshot,
		);
		const hostedProperties = transformedData.length;

		// Get user data
		const userRef = firestore.collection('users').doc(doc.data().user_id);
		const userDoc = yield userRef.get();
		const userData = yield userDoc.data();
		yield put(
			getUserData({
				hostedProperties,
				hostedTenants: userData.hostedTenants || 0,
				date_user_joined: userData.joined,
			}),
		);

		yield put(
			fetchSimilarPropertiesStart({
				region: doc.data().region,
				price: doc.data().price,
			}),
		);
	} catch (error) {
		yield put(fetchPropertyDetailsFailure(error.message));
	}
}

function* onFetchDetailsStart() {
	yield takeLatest(
		PropertyDetailsTypes.FETCH_PROPERTY_DETAILS_START,
		fetchDetails,
	);
}

//  Fetch similar properties
function* fetchSimilarProperties({ payload }) {
	try {
		const propertiesRef = firestore.collection('properties');
		const query = yield propertiesRef
			.where('region', '==', payload.region)
			.where('price', '>=', parseFloat(payload.price) - 300)
			.where('price', '<=', parseFloat(payload.price) + 300)
			.where('isComplete', '==', true)
			.limit(9);
		const snapshot = yield query.get();
		const transformedData = yield call(
			convertPropertySnapshotToMap,
			snapshot,
		);
		yield put(fetchSimilarPropertiesSuccess(transformedData));
	} catch (error) {
		yield put(fetchSimilarPropertiesFailure(error.message));
	}
}

function* onFetchSimilarPropertiesStart() {
	yield takeLatest(
		PropertyDetailsTypes.FETCH_SIMILAR_PROPERTIES_START,
		fetchSimilarProperties,
	);
}

export function* propertyDetailsSagas() {
	yield all([call(onFetchDetailsStart), call(onFetchSimilarPropertiesStart)]);
}
