import styled from 'styled-components';
import { Container } from '../../../globals/appGlobal';
import { palette } from '../../../utils';

export const FooterWrapper = styled(Container)`
	color: ${palette.grey};
	padding-top: 30px;
	padding-bottom: 30px;
	* {
		padding: 0;
		margin: 0;
	}
	div:first-child {
		margin-bottom: 30px;
	}
	p {
		cursor: pointer;
	}
	p,
	svg {
		transition: 400ms ease-in-out;
	}
	p:hover,
	svg:hover {
		color: ${palette.green};
	}
	a {
		color: ${palette.grey};
	}
`;

export const Social = styled.div`
	font-size: 1.2rem;
	svg {
		cursor: pointer;
		margin-left: 20px;
	}
`;
