import SavedAdTypes from './save-ad.types';
import { filterSavedData } from './save-ad.utils';

const INITIAL_STATE = {
	saved: [],
	savedProperties: [],
	isSaving: false,
	isFetchingSaved: true,
	error: '',
};

const saveAdReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SavedAdTypes.SET_SAVED_ADS:
			return {
				...state,
				saved: action.payload,
				isFetchingSaved: false,
			};

		case SavedAdTypes.SAVE_AD_START:
			return {
				...state,
				isSaving: true,
				error: '',
				saved: state.saved.includes(action.payload.uid)
					? state.saved.filter(ad => ad !== action.payload.uid)
					: [...state.saved, action.payload.uid],
				savedProperties: filterSavedData(
					state.savedProperties,
					action.payload.uid,
				),
			};

		case SavedAdTypes.SAVE_AD_SUCCESS:
			return {
				...state,
				isSaving: false,
				error: '',
			};

		case SavedAdTypes.SAVE_AD_FAILURE:
			return {
				...state,
				isSaving: false,
				error: action.payload,
			};

		case SavedAdTypes.FETCH_SAVED_START:
			return {
				...state,
				isFetchingSaved: true,
				error: '',
			};

		case SavedAdTypes.FETCH_SAVED_SUCCESS:
			return {
				...state,
				isFetchingSaved: false,
				error: '',
				savedProperties: action.payload,
			};

		case SavedAdTypes.FETCH_SAVED_FAILURE:
			return {
				...state,
				isFetchingSaved: false,
				error: action.payload,
			};

		default:
			return state;
	}
};

export default saveAdReducer;
