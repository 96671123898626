import React, { createContext, useState, useEffect } from 'react';
import { functions } from '../firebase/firebase.config';

export const MapContext = createContext({
	key: '',
});

const MapKeyProvider = ({ children }) => {
	const [key, setKey] = useState('');

	const getKey = async () => {
		try {
			const response = await functions.httpsCallable('mapKey')();
			const { data } = response;
			setKey(data);
		} catch (e) {
			console.log(e.message);
		}
	};

	useEffect(() => {
		getKey();
	}, []);

	return (
		<MapContext.Provider
			value={{
				key,
			}}
		>
			{children}
		</MapContext.Provider>
	);
};

export default MapKeyProvider;
