import styled, { css } from 'styled-components';
import { palette } from '../../../utils';
import { NavLink } from 'react-router-dom';

export const PhoneHeaderWrapper = styled.div`
	width: 100vw;
	padding: 10px 7vw;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 99;
	transition: 400ms;
	background-color: white;
	border-top: 1px solid ${palette.lightGrey};
	-webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
	display: flex;
	justify-content: space-between;
	svg {
		width: 37px;
		height: 37px;
		font-size: 1.5rem;
		color: ${palette.grey};
		* {
			fill: ${palette.grey};
		}
	}
`;

const NavTemplate = css`
	color: ${palette.grey};
	&.active {
		color: black;
		font-weight: 500;
		svg {
			color: ${palette.green};
			* {
				fill: ${palette.green};
			}
		}
	}
`;

export const NavItem = styled(NavLink)`
	${NavTemplate};
`;

export const NavDiv = styled.div`
	${NavTemplate};
`;
