import React, { lazy, Suspense, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { GlobalStyle } from './globals/global';
import { Switch, Route, useLocation } from 'react-router-dom';
import PageLoader from './components/loaders/page-loader/pageLoader';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import { checkUserSession } from './redux/user/user.actions';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from './redux/user/user.selectors';
import { auth } from './firebase/firebase.config';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './react_dates_overrides.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ErrorBoundary from './components/error-boundary-components/error-boundary';
import { MapContext } from './contexts/mapKeyProvider';
import moment from 'moment-timezone';
import ReactGA from 'react-ga';

const HomePage = lazy(() => import('./pages/home'));
const AboutPage = lazy(() => import('./pages/about'));
const EdanraNssPage = lazy(() => import('./pages/edanraNss'));
const PhoneSearch = lazy(() => import('./components/phone-search/phoneSearch'));
const RegionPage = lazy(() => import('./pages/region'));
const TermsOfUse = lazy(() => import('./pages/termsOfUsePage'));
const DataPrivacy = lazy(() => import('./pages/dataPrivacyPage'));
const CookiePolicy = lazy(() => import('./pages/cookiePolicyPage'));
const TermsIntro = lazy(() => import('./pages/termsIntro'));
const HostProperty = lazy(() => import('./pages/hostProperty'));
const PasswordReset = lazy(() => import('./pages/passwordReset'));
const Dashboard = lazy(() => import('./pages/dashboard'));
const EditProfile = lazy(() => import('./pages/editProfile'));
const PropertyDetails = lazy(() => import('./pages/propertyDetails'));
const ExploreProperties = lazy(() => import('./pages/exploreProperties'));
const Book = lazy(() => import('./pages/book'));
const HostCheckout = lazy(() => import('./pages/checkoutHomeOwner'));
const EdanraStay = lazy(() => import('./pages/edanraStay'));
const EditListing = lazy(() => import('./pages/editListing'));
const PaymentSuccess = lazy(() => import('./pages/paymentSuccess'));
const Review = lazy(() => import('./pages/review'));
const ErrorPage = lazy(() => import('./pages/errorPage'));
const BookedDetails = lazy(() => import('./pages/bookedDetails'));
const SafetyPage = lazy(() => import('./pages/safety'));
const SearchPage = lazy(() => import('./pages/search'));
const HostProfilePage = lazy(() => import('./pages/hostProfile'));

ReactGA.initialize('UA-188387510-1');

function App({ checkUserSession }) {
	moment.tz.setDefault('Africa/Accra');

	useEffect(() => {
		checkUserSession();
	}, [checkUserSession]);

	// use location
	const location = useLocation();

	const checkDynamicLocation = (location, path) => {
		const splitLocation = location.split('/');
		return splitLocation[1] !== path;
	};

	const { key } = useContext(MapContext);

	const setGA = () => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	};

	useEffect(() => {
		setGA();
	});

	return key ? (
		<div className='App'>
			<GlobalStyle />
			<ErrorBoundary>
				<Suspense fallback={<PageLoader />}>
					{checkDynamicLocation(location.pathname, 'edanra-stay') && (
						<Header />
					)}
					<Switch>
						<Route exact path='/' component={HomePage} />
						<Route path='/search' component={PhoneSearch} />
						<Route exact path='/terms' component={TermsIntro} />
						<Route
							exact
							path='/host-profile/:uid'
							component={HostProfilePage}
						/>
						<Route
							path='/terms/terms-of-use'
							component={TermsOfUse}
						/>
						<Route
							path='/terms/data-privacy'
							component={DataPrivacy}
						/>
						<Route
							path='/terms/cookie-policy'
							component={CookiePolicy}
						/>
						<Route path='/edanra-stay' component={EdanraStay} />
						<Route path='/about' component={AboutPage} />
						<Route path='/edanra-nss' component={EdanraNssPage} />
						<Route path='/region/:id' component={RegionPage} />
						<Route
							path='/host-property'
							render={() =>
								auth.currentUser ? (
									<HostProperty />
								) : (
									<HomePage />
								)
							}
						/>
						<Route
							path='/password-reset'
							component={PasswordReset}
						/>
						<Route
							exact={true}
							path='/dashboard/:nav'
							component={Dashboard}
						/>
						<Route
							path='/dashboard/:nav/:id/:guests'
							component={Dashboard}
						/>
						<Route
							path='/edit-profile'
							render={() =>
								auth.currentUser ? (
									<EditProfile />
								) : (
									<HomePage />
								)
							}
						/>
						<Route
							path='/property-details/:id'
							component={PropertyDetails}
						/>
						<Route
							path='/explore-properties/:id'
							component={ExploreProperties}
						/>
						<Route
							path='/book/:id/:startDate/:endDate'
							component={Book}
						/>
						<Route path='/checkout/:id' component={HostCheckout} />
						<Route
							path='/payment-success'
							component={PaymentSuccess}
						/>
						<Route
							path='/review/:id'
							render={props =>
								auth.currentUser ? (
									<Review {...props} />
								) : (
									<HomePage />
								)
							}
						/>
						<Route
							path='/old-review/:id/:previous'
							render={props =>
								auth.currentUser ? (
									<Review {...props} />
								) : (
									<HomePage />
								)
							}
						/>
						<Route
							path='/edit-listing/:isComplete/:id'
							component={
								auth.currentUser ? EditListing : HomePage
							}
						/>
						<Route
							path='/dashboard/booking-details/:id'
							component={BookedDetails}
						/>
						<Route path='/safety' component={SafetyPage} />

						<Route
							exact
							path={[
								'/ahafo/:desc/:id',
								'/ashanti/:desc/:id',
								'/bono/:desc/:id',
								'/bono-east/:desc/:id',
								'/central/:desc/:id',
								'/eastern/:desc/:id',
								'/greater-accra/:desc/:id',
								'/north-east/:desc/:id',
								'/northern/:desc/:id',
								'/oti/:desc/:id',
								'/savannah/:desc/:id',
								'/upper-east/:desc/:id',
								'/upper-west/:desc/:id',
								'/volta/:desc/:id',
								'/western/:desc/:id',
								'/western-north/:desc/:id',
							]}
							component={PropertyDetails}
						/>

						<Route
							exact
							path={[
								'/ahafo/:desc',
								'/ashanti/:desc',
								'/bono/:desc',
								'/bono-east/:desc',
								'/central/:desc',
								'/eastern/:desc',
								'/greater-accra/:desc',
								'/north-east/:desc',
								'/northern/:desc',
								'/oti/:desc',
								'/savannah/:desc',
								'/upper-east/:desc',
								'/upper-west/:desc',
								'/volta/:desc',
								'/western/:desc',
								'/western-north/:desc',
								'/ahafo',
								'/ashanti',
								'/bono',
								'/bono-east',
								'/central',
								'/eastern',
								'/greater-accra',
								'/north-east',
								'/northern',
								'/oti',
								'/savannah',
								'/upper-east',
								'/upper-west',
								'/volta',
								'/western',
								'/western-north',
							]}
							component={SearchPage}
						/>

						<Route component={ErrorPage} />
					</Switch>
					{checkDynamicLocation(location.pathname, 'dashboard') && (
						<Footer />
					)}
				</Suspense>
			</ErrorBoundary>
		</div>
	) : (
		<div className='App'>
			<PageLoader />
		</div>
	);
}

const mapStateToProps = createStructuredSelector({
	currentUser: selectCurrentUser,
});

const mapDispatchToProps = dispatch => ({
	checkUserSession: () => dispatch(checkUserSession()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
