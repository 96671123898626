import { auth, firestore } from './firebase.config';
import { timestamp } from './firebase.config';

export const createUserProfileDocument = async (userAuth, additionalData) => {
	if (!userAuth) return;

	const userRef = firestore.doc(`users/${userAuth.uid}`);
	const snapshot = await userRef.get();

	if (!snapshot.exists) {
		const { displayName, email, photoURL } = userAuth;

		try {
			await userRef.set({
				displayName,
				email,
				profile_img: photoURL,
				saved: [],
				booked: [],
				joined: timestamp,
				...additionalData,
			});
		} catch (e) {
			console.log('Error creating user', e.message); // eslint-disable-line no-console
		}
	}

	return userRef;
};

export const getCurrentUser = () => {
	return new Promise((resolve, reject) => {
		const unsubscribe = auth.onAuthStateChanged(userAuth => {
			unsubscribe();
			resolve(userAuth);
		}, reject);
	});
};

export const convertPropertySnapshotToMap = properties => {
	return properties.docs.map(doc => {
		const {
			last_date_paid,
			property_class,
			subscription_type,
			date_uploaded,
		} = doc.data();

		const last_modified = doc.data().last_modified ?? date_uploaded;

		return {
			...doc.data(),
			last_date_paid: last_date_paid ?? '',
			last_modified,
			subscription_type:
				property_class === 'Regular Ad'
					? subscription_type
						? subscription_type
						: 'free_four'
					: 'edanra_stay',
		};
	});
};
