const PropertyUploadTypes = {
	CREATE_PROPERTY_START: 'CREATE_PROPERTY_START',
	CREATE_PROPERTY_SUCCESS: 'CREATE_PROPERTY_SUCCESS',
	CREATE_PROPERTY_FAILURE: 'CREATE_PROPERTY_FAILURE',
	SET_UPLOAD_SUCCESS_MESSAGE: 'SET_UPLOAD_SUCCESS_MESSAGE',
	SET_FINAL_UPLOAD_SUCCESS_MESSAGE: 'SET_FINAL_UPLOAD_SUCCESS_MESSAGE',
	CLEAR_UPLOAD_SUCCESS_MESSAGE: 'CLEAR_UPLOAD_SUCCESS_MESSAGE',
	GET_PROPERTY_START: 'GET_PROPERTY_START',
	GET_PROPERTY_SUCCESS: 'GET_PROPERTY_SUCCESS',
	GET_PROPERTY_FAILURE: 'GET_PROPERTY_FAILURE',
	PROPERTY_EDIT_START: 'PROPERTY_EDIT_START',
	PROPERTY_EDIT_SUCCESS: 'PROPERTY_EDIT_SUCCESS',
	PROPERTY_EDIT_FAILURE: 'PROPERTY_EDIT_FAILURE',
	REMOVE_PROPERTY_START: 'REMOVE_PROPERTY_START',
	REMOVE_PROPERTY_SUCCESS: 'REMOVE_PROPERTY_SUCCESS',
	REMOVE_PROPERTY_FAILURE: 'REMOVE_PROPERTY_FAILURE',
	CLEAR_CURRENT_PROPERTY: 'CLEAR_CURRENT_PROPERTY',
	GET_BANKS_START: 'GET_BANKS_START',
	GET_BANKS_SUCCESS: 'GET_BANKS_SUCCESS',
	GET_BANKS_FAILURE: 'GET_BANKS_FAILURE',
};

export default PropertyUploadTypes;
