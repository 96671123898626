import ManageAdTypes from './manage-ad.types';

const INITIAL_STATE = {
	userProperties: [],
	incompleteProperties: [],
	isFetchingUserProperties: true,
	error: '',
	successMessage: '',
};

const manageAdReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ManageAdTypes.FETCH_USER_PROPERTIES_START:
			return {
				...state,
				error: '',
				isFetchingUserProperties: true,
			};

		case ManageAdTypes.FETCH_USER_PROPERTIES_SUCCESS:
			return {
				...state,
				error: '',
				userProperties: action.payload,
				isFetchingUserProperties: false,
			};

		case ManageAdTypes.FETCH_USER_PROPERTIES_FAILURE:
			return {
				...state,
				error: action.payload,
				isFetchingUserProperties: false,
				successMessage: '',
			};

		case ManageAdTypes.FETCH_INCOMPLETE_PROPERTIES:
			return {
				...state,
				incompleteProperties: action.payload,
			};

		case ManageAdTypes.DELETE_PROPERTY_START:
			return {
				...state,
				error: '',
				userProperties: state.userProperties.filter(
					property => property.uid !== action.payload,
				),
				incompleteProperties: state.incompleteProperties.filter(
					property => property.uid !== action.payload,
				),
			};

		case ManageAdTypes.DELETE_PROPERTY_SUCCESS:
			return {
				...state,
				error: '',
			};

		case ManageAdTypes.DELETE_PROPERTY_FAILURE:
			return {
				...state,
				error: action.payload,
			};

		default:
			return state;
	}
};

export default manageAdReducer;
