import styled, { css } from 'styled-components';
import { Paragraph } from '../../../globals/appGlobal';
import { palette } from '../../../utils';
import { Link, NavLink } from 'react-router-dom';

export const HeaderWrapper = styled.header`
	display: none;
	padding: 15px 0;
	height: 85px !important;
	position: fixed;
	width: 100vw;
	z-index: 99;
	transition: background 400ms ease-in-out;
	border-bottom: 1px solid transparent;
	top: 0;
	left: 0;

	> div {
		display: flex;
		align-items: center;
		justify-content: space-between;

		p,
		div,
		h5 {
			padding: 0;
		}
	}

	@media only screen and (min-width: 600px) {
		display: inline-block;
	}
`;

const HeaderItem = css`
	font-weight: 600;
	${Paragraph};
	margin: 0;
	color: black;
	position: relative;

	&.active {
		&::after {
			content: '';
			position: absolute;
			height: 3px;
			width: 40%;
			max-width: 120px;
			background-color: black;
			top: 85%;
			left: 10px;
		}
	}
`;

export const HeaderLink = styled(NavLink)`
	${HeaderItem};
`;

export const HeaderText = styled.p`
	${HeaderItem};
`;

export const Brand = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	color: black;

	img {
		width: 30px;
		margin-right: 10px;
	}

	h5 {
		margin: 0;
	}
`;

export const Nav = styled.nav`
	display: flex;
	align-items: center;
	cursor: pointer;

	> * {
		margin-left: 40px !important;
		padding: 8px 10px !important;
		border-radius: 500px;
		transition: 400ms ease-in-out;

		&:hover {
			background-color: ${palette.lightestGrey};
		}
	}

	> p:first-of-type {
		margin-left: 0;
	}
`;

export const SearchButton = styled.div`
	display: none;
	border: 1px solid black;
	border-radius: 500px;
	background-color: white;
	cursor: pointer;
	padding: 5px 10px 5px 50px !important;
	align-items: center;
	color: ${palette.grey};
	outline: none;
	margin-left: 30px;

	button {
		margin-left: 50px;
	}

	&:hover button {
		background-color: white;

		svg {
			path {
				fill: ${palette.green};
			}
		}
	}
	@media (min-width: 1408px) {
		margin-left: 10%;
	}
`;

export const UserButton = styled.div`
	border-radius: 500px;
	padding: 5px 10px !important;
	border: 1px solid ${palette.lightGrey};
	display: flex;
	align-items: center;

	svg {
		font-size: 1rem;
		color: ${palette.green};
		margin-left: 5px;
	}
`;
