import ExploreActionTypes from './explore.types';

const INITIAL_STATE = {
	regionProperties: {
		'Greater Accra': [],
		Ashanti: [],
		Central: [],
		Eastern: [],
	},
	isFetchingRegionProperties: false,
	error: '',
};

const exploreReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ExploreActionTypes.FETCH_REGION_PROPERTIES_START:
			return {
				...state,
				isFetchingRegionProperties: true,
				error: '',
			};

		case ExploreActionTypes.FETCH_REGION_PROPERTIES_SUCCESS:
			return {
				...state,
				isFetchingRegionProperties: false,
				regionProperties: {
					...state.regionProperties,
					...action.payload,
				},
				error: '',
			};

		case ExploreActionTypes.FETCH_REGION_PROPERTIES_FAILURE:
			return {
				...state,
				error: action.payload,
				isFetchingRegionProperties: false,
			};

		case ExploreActionTypes.EMPTY_REGION_PROPERTIES:
			return {
				...state,
				regionProperties: [],
			};

		default:
			return state;
	}
};

export default exploreReducer;
