const SavedAdTypes = {
	SET_SAVED_ADS: 'SET_SAVED_ADS',
	SAVE_AD_START: 'SAVE_AD_START',
	SAVE_AD_SUCCESS: 'SAVE_AD_SUCCESS',
	SAVE_AD_FAILURE: 'SAVE_AD_FAILURE',
	FETCH_SAVED_START: 'FETCH_SAVED_START',
	FETCH_SAVED_SUCCESS: 'FETCH_SAVED_SUCCESS',
	FETCH_SAVED_FAILURE: 'FETCH_SAVED_FAILURE',
};

export default SavedAdTypes;
