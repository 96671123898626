import PropertyDetailsTypes from './property-details.types';

const INITIAL_STATE = {
	fetchingDetails: true,
	currentProperty: null,
	error: '',
	similarProperties: [],
	similarPropertiesError: '',
	fetchingSimilarProperties: false,
};

const propertyDetailsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case PropertyDetailsTypes.FETCH_PROPERTY_DETAILS_START:
			return {
				...state,
				fetchingDetails: true,
				error: '',
				currentProperty: null,
			};

		case PropertyDetailsTypes.FETCH_PROPERTY_DETAILS_SUCCESS:
			return {
				...state,
				fetchingDetails: false,
				currentProperty: action.payload,
				error: '',
			};

		case PropertyDetailsTypes.FETCH_PROPERTY_DETAILS_FAILURE:
			return {
				...state,
				fetchingDetails: false,
				error: action.payload,
			};

		case PropertyDetailsTypes.SET_PROPERTY:
			return {
				...state,
				fetchingDetails: false,
				currentProperty: action.payload,
			};

		case PropertyDetailsTypes.CLEAR_CURRENT_PROPERTY:
			return {
				...state,
				currentProperty: null,
				similarProperties: [],
			};

		case PropertyDetailsTypes.CLEAR_SIMILAR_PROPERTIES:
			return {
				...state,
				similarProperties: [],
			};

		case PropertyDetailsTypes.FETCH_SIMILAR_PROPERTIES_START:
			return {
				...state,
				fetchingSimilarProperties: true,
				similarPropertiesError: '',
			};

		case PropertyDetailsTypes.FETCH_SIMILAR_PROPERTIES_SUCCESS:
			return {
				...state,
				fetchingSimilarProperties: false,
				similarProperties: action.payload,
				similarPropertiesError: '',
			};

		case PropertyDetailsTypes.FETCH_SIMILAR_PROPERTIES_FAILURE:
			return {
				...state,
				similarPropertiesError: action.payload,
				fetchingSimilarProperties: false,
			};

		case PropertyDetailsTypes.GET_USER_DATA:
			return {
				...state,
				currentProperty: {
					...state.currentProperty,
					...action.payload,
				},
			};

		default:
			return state;
	}
};

export default propertyDetailsReducer;
