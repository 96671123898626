const INITIAL_STATE = {
	priceRange: {
		min: 50,
		max: 50000,
	},
};

const rangeReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		default:
			return state;
	}
};

export default rangeReducer;
