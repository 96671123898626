import styled from 'styled-components';
import { Flex } from '../../../globals/appGlobal';
import { palette } from '../../../utils';

export const LoaderContainer = styled(Flex)`
	width: 100vw;
	height: 100vh;
	> div {
		width: fit-content;
		position: relative;
		svg {
			width: 80px;
			* {
				fill: ${palette.green};
			}
			animation: moveLogo 2s ease-in-out infinite alternate both;
		}
		&::after {
			content: '';
			position: absolute;
			bottom: -50px;
			left: 2.5%;
			width: 92%;
			height: 20px;
			background: rgba(0, 0, 0, 0.3);
			border-radius: 50%;
			z-index: -1;
			filter: blur(2px);
			animation: animateShadow 2s ease-in-out infinite alternate both;
		}
	}
	@keyframes moveLogo {
		from {
			transform: translateY(-30px);
		}
		to {
			transform: translateY(30px);
		}
	}
	@keyframes animateShadow {
		from {
			transform: scale(0.3);
		}
		to {
			transform: scale(1);
		}
	}
`;
