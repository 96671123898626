import { all, put, call, takeLatest } from 'redux-saga/effects';
import { firestore, functions } from '../../firebase/firebase.config';
import BookingTypes from './book.types';
import {
	cancelBookingFailure,
	cancelBookingSuccess,
	checkInFailure,
	checkInSuccess,
	checkOutFailure,
	checkOutSuccess,
	userBookingFailure,
	userBookingSuccess,
} from './book.actions';
import { setGuestBooked } from '../booking-data/booking-data.actions';

//  INITIAL BOOK
function* bookHelper(collectionName, documentId, array, bookingDetails) {
	const { propertyID, userID, txnRef, ...otherDetails } = bookingDetails;
	const startDate = String(otherDetails.startDate._d);
	const endDate = String(otherDetails.endDate._d);
	const ref = firestore.collection(collectionName).doc(documentId);
	const doc = yield ref.get();
	const docData = doc.data();
	const newUserBooking = bookingDetails.propertyID;

	if (array === 'booked') {
		if (docData[array]) {
			const { booked } = docData;
			const updatedBooked = [newUserBooking, ...booked];
			yield ref.update({
				booked: updatedBooked,
			});
		} else {
			yield ref.update({
				booked: [newUserBooking],
			});
		}
	} else if (array === 'bookings') {
		if (docData[array]) {
			const { bookings } = docData;
			const updatedBookings = [
				...bookings,
				{
					...otherDetails,
					startDate,
					endDate,
					hasPaid: true,
					userID,
					ref: txnRef,
				},
			];
			yield ref.update({
				bookings: updatedBookings,
			});
		} else {
			yield ref.update({
				bookings: [
					{
						...otherDetails,
						userID,
						startDate,
						endDate,
						hasPaid: true,
						hasReviewed: false,
						ref: txnRef,
					},
				],
			});
		}
	}
}

function* book({ payload }) {
	const { propertyID, userID } = payload;

	try {
		yield bookHelper('users', userID, 'booked', payload);
		yield bookHelper('properties', propertyID, 'bookings', payload);
		yield put(userBookingSuccess('Booking successful'));
	} catch (error) {
		yield put(userBookingFailure(error.message));
	}
}

function* onBookStart() {
	yield takeLatest(BookingTypes.USER_BOOKING_START, book);
}

async function propertyToUpdateHelper(uid, ref) {
	const propertyRef = firestore.collection('properties');
	const propertyDoc = await propertyRef.doc(uid);
	const propertyDocData = await propertyDoc.get();
	const { bookings } = propertyDocData.data();

	const propertyToUpdate = bookings.find(booking => booking.ref === ref);
	const otherBookings = bookings.filter(booking => booking.ref !== ref);

	return { propertyDoc, propertyToUpdate, otherBookings };
}

//  CHECK IN
function* checkIn({ payload }) {
	try {
		const {
			propertyDoc,
			propertyToUpdate,
			otherBookings,
		} = yield propertyToUpdateHelper(payload.propertyID, payload.ref);

		//	Check if guest did not review previous stay
		const guestRef = yield firestore
			.collection('users')
			.doc(payload.userID);
		const guestSnapshot = yield guestRef.get();
		const guestData = yield guestSnapshot.data();
		const previousStay = guestData.currentlyOccupying ?? null;

		if (previousStay) {
			const guestBookings = guestData.booked;
			const previousStayId = previousStay.propertyID;
			const otherGuestBookings = guestBookings.filter(
				booking => booking !== previousStayId,
			);
			yield guestRef.update({
				booked: otherGuestBookings,
				currentlyOccupying: {
					propertyID: payload.propertyID,
					stayEnded: false,
					hasCheckedIn: true,
					hasCheckedOut: false,
					...propertyToUpdate,
				},
			});
		} else {
			yield guestRef.update({
				currentlyOccupying: {
					propertyID: payload.propertyID,
					hasCheckedIn: true,
					hasCheckedOut: false,
					stayEnded: false,
					...propertyToUpdate,
				},
			});
		}

		yield propertyDoc.update({
			bookings: [
				...otherBookings,
				{
					...propertyToUpdate,
					hasCheckedIn: true,
					hasCheckedOut: false,
				},
			],
			currentTenant: payload.userID,
		});

		yield put(checkInSuccess('Check In Successful'));
	} catch (error) {
		yield put(checkInFailure(error.message));
	}
}

function* onCheckInStart() {
	yield takeLatest(BookingTypes.CHECK_IN_START, checkIn);
}

//  CHECK OUT
function* checkOut({ payload }) {
	try {
		const {
			propertyDoc,
			propertyToUpdate,
			otherBookings,
		} = yield propertyToUpdateHelper(payload.propertyID, payload.ref);

		yield firestore
			.collection('users')
			.doc(payload.userID)
			.update({
				currentlyOccupying: {
					...propertyToUpdate,
					propertyID: payload.propertyID,
					stayEnded: true,
					hasCheckedOut: true,
				},
			});

		yield propertyDoc.update({
			bookings: [
				...otherBookings,
				{
					...propertyToUpdate,
					hasCheckedOut: true,
				},
			],
		});

		yield put(checkOutSuccess('Guest checked out'));
	} catch (error) {
		yield put(checkOutFailure(error.message));
	}
}

function* onCheckOutStart() {
	yield takeLatest(BookingTypes.CHECK_OUT_START, checkOut);
}

//	CANCEL BOOKING
function* cancelBooking({ payload }) {
	const { userID, propertyID, cancelBookingData } = payload;
	const reverseTransfer = functions.httpsCallable('reverseTransaction');

	try {
		//  Update property
		const propertyRef = yield firestore
			.collection('properties')
			.doc(propertyID);
		const propertySnapshot = yield propertyRef.get();
		const propertyData = yield propertySnapshot.data();
		const { bookings } = propertyData;

		const otherPropertyBookings = bookings.filter(
			booking => booking.userID !== userID,
		);

		yield propertyRef.update({
			bookings: otherPropertyBookings,
		});

		// Update guest data
		const userRef = yield firestore.collection('users').doc(userID);
		const userSnapshot = yield userRef.get();
		const userData = yield userSnapshot.data();
		const userBookings = userData.booked;

		const otherBookings = userBookings.filter(
			booking => booking !== propertyID,
		);

		if (userBookings.includes(propertyID)) {
			yield reverseTransfer(cancelBookingData);
		}

		yield userRef.update({
			currentlyOccupying: null,
			booked: otherBookings,
		});

		yield put(setGuestBooked(otherBookings));

		yield put(cancelBookingSuccess('Booking has been cancelled'));
	} catch (error) {
		yield put(cancelBookingFailure(error.message));
	}
}

function* onCancelBookingStart() {
	yield takeLatest(BookingTypes.CANCEL_BOOKING_START, cancelBooking);
}

export function* bookSagas() {
	yield all([
		call(onBookStart),
		call(onCheckInStart),
		call(onCheckOutStart),
		call(onCancelBookingStart),
	]);
}
