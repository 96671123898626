import { useEffect, useState } from 'react';
import { useInitAutocomplete } from './useInitAutocomplete';

export const useAutocomplete = (setValues, ids, types, geoPoint) => {
	// load google autocomplete
	const [autocomplete, setAutocomplete] = useState(null);

	const { loadAutoComplete, listenForChange } = useInitAutocomplete(
		ids,
		types,
		geoPoint,
	);

	useEffect(() => {
		loadAutoComplete().then(obj => {
			setAutocomplete(obj);
		});
	}, [loadAutoComplete, setAutocomplete]);

	useEffect(() => {
		autocomplete &&
			autocomplete.forEach(autocompleteObj => {
				listenForChange(
					autocompleteObj.autocomplete,
					autocompleteObj.autocompleteRef,
					autocompleteObj.geoPoints,
					setValues,
				);
			});
	}, [autocomplete, setValues, listenForChange]);
};
