import styled from 'styled-components';
import { palette } from '../../../utils';
import { ShadowCard } from '../../../globals/appGlobal';

export const ProfileDropdownWrapper = styled.div`
	position: fixed;
	z-index: 9999;
	top: 110px;
	margin-right: 32px;
	@media (min-width: 992px) {
		right: 5vw;
	}
	@media (min-width: 1024px) {
		right: calc((100vw - 960px) / 2);
	}
	@media (min-width: 1216px) {
		right: calc((100vw - 1120px) / 2);
	}
	@media (min-width: 1408px) {
		right: calc((100vw - 1300px) / 2);
	}
	@media (min-width: 1700px) {
		right: calc((100vw - 1600px) / 2);
	}
	@media (max-width: 992px) {
		width: 100vw !important;
		z-index: 99;
		bottom: 70px;
		top: initial;
		height: auto;
	}

	ul {
		padding: 10px 0;
		margin: 0;
		list-style-type: none;
	}

	a {
		color: black;
	}

	li {
		padding: 15px 30px;
		cursor: pointer;
	}

	li:hover {
		background-color: ${palette.lightestGrey};
	}

	> div {
		width: 270px;
		padding: 0;
	}

	p {
		margin: 0;
		padding: 0;
		line-height: 1rem;
	}

	.hr {
		margin: 10px 0;
	}

	@media (max-width: 992px) {
		transition: 300ms ease-in-out;
		transform: ${props =>
			props.showProfileDropdown ? 'translateY(0)' : 'translateY(200vh)'};
	}
`;

export const DropDownCard = styled(ShadowCard)`
  border: 1px solid ${palette.lightestGrey};
  margin-left: auto;
  @media (max-width: 992px) {
    width: 100vw !important;
    box-shadow: none;
`;
