const UserActionTypes = {
	SIGN_UP_START: 'SIGN_UP_START',
	SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
	SIGN_UP_FAILURE: 'SIGN_UP_FAILURE',
	CLEAR_SIGN_UP_SUCCESS: 'CLEAR_SIGN_UP_SUCCESS',
	GOOGLE_SIGN_IN_START: 'GOOGLE_SIGN_IN_START',
	EMAIL_SIGN_IN_START: 'EMAIL_SIGN_IN_START',
	SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
	SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
	SIGN_OUT_START: 'SIGN_OUT_START',
	SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
	SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',
	CHECK_USER_SESSION: 'CHECK_USER_SESSION',
	PASSWORD_RESET_START: 'PASSWORD_RESET_START',
	PASSWORD_RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
	PASSWORD_RESET_FAILURE: 'PASSWORD_RESET_FAILURE',
	CLEAR_PASSWORD_RESET_SUCCESS: 'CLEAR_PASSWORD_RESET_SUCCESS',
	SEND_VERIFICATION_START: 'SEND_VERIFICATION_START',
	SEND_VERIFICATION_SUCCESS: 'SEND_VERIFICATION_SUCCESS',
	SEND_VERIFICATION_FAILURE: 'SEND_VERIFICATION_FAILURE',
	EDIT_USER_PROFILE_START: 'EDIT_USER_PROFILE_START',
	EDIT_USER_PROFILE_SUCCESS: 'EDIT_USER_PROFILE_SUCCESS',
	EDIT_USER_PROFILE_FAILURE: 'EDIT_USER_PROFILE_FAILURE',
	CLEAR_EDIT_SUCCESS: 'CLEAR_EDIT_SUCCESS',
	DELETE_USER_PROFILE_IMAGE_START: 'DELETE_USER_PROFILE_IMAGE_START',
	DELETE_USER_PROFILE_IMAGE_SUCCESS: 'DELETE_USER_PROFILE_IMAGE_SUCCESS',
	DELETE_USER_PROFILE_IMAGE_FAILURE: 'DELETE_USER_PROFILE_IMAGE_FAILURE',
	UPDATE_PROPERTY_PROFILE_IMAGES_START:
		'UPDATE_PROPERTY_PROFILE_IMAGES_START',
	UPDATE_PROPERTY_PROFILE_IMAGES_SUCCESS:
		'UPDATE_PROPERTY_PROFILE_IMAGES_SUCCESS',
	UPDATE_PROPERTY_PROFILE_IMAGES_FAILURE:
		'UPDATE_PROPERTY_PROFILE_IMAGES_FAILURE',
	DELETE_USER_ACCOUNT_START: 'DELETE_USER_ACCOUNT_START',
	DELETE_USER_ACCOUNT_SUCCESS: 'DELETE_USER_ACCOUNT_SUCCESS',
	DELETE_USER_ACCOUNT_FAILURE: 'DELETE_USER_ACCOUNT_FAILURE',
	SET_USER_ERROR_FALSE: 'SET_USER_ERROR_FALSE',
	CLEAR_USER_STATUS: 'CLEAR_USER_STATUS',
};

export default UserActionTypes;
