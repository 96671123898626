import modalTypes from './modal.types';

export const openLogModal = () => ({
	type: modalTypes.OPEN_LOG_MODAL,
});

export const closeLogModal = () => ({
	type: modalTypes.CLOSE_LOG_MODAL,
});

export const toggleLogModal = () => ({
	type: modalTypes.TOGGLE_LOG_MODAL,
});

export const showSignUpOnly = () => ({
	type: modalTypes.SHOW_SIGN_UP_ONLY,
});

export const showSignInOnly = () => ({
	type: modalTypes.SHOW_SIGN_IN_ONLY,
});
