import PaymentActionTypes from './payment.types';

export const updateLastDatePaidStart = paymentData => ({
	type: PaymentActionTypes.UPDATE_LAST_DATE_PAID_START,
	payload: paymentData,
});

export const updateLastDatePaidSuccess = paymentData => ({
	type: PaymentActionTypes.UPDATE_LAST_DATE_PAID_SUCCESS,
	payload: paymentData,
});

export const updateLastDatePaidFailure = error => ({
	type: PaymentActionTypes.UPDATE_LAST_DATE_PAID_FAILURE,
	payload: error,
});

export const fetchCheckoutPropertyStart = payload => ({
	type: PaymentActionTypes.FETCH_CHECKOUT_PROPERTY_START,
	payload,
});

export const fetchCheckoutPropertySuccess = checkoutProperty => ({
	type: PaymentActionTypes.FETCH_CHECKOUT_PROPERTY_SUCCESS,
	payload: checkoutProperty,
});

export const fetchCheckoutPropertyFailure = error => ({
	type: PaymentActionTypes.FETCH_CHECKOUT_PROPERTY_FAILURE,
	payload: error,
});
