import React, { useEffect, useState } from 'react';
import PageLoader from '../components/loaders/page-loader/pageLoader';
import firebase from '../firebase/firebase.config';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
	const [currentUser, setCurrentUser] = useState(null);
	const [pending, setPending] = useState(true);

	useEffect(() => {
		firebase.auth().onAuthStateChanged(user => {
			setCurrentUser(user);
			setPending(false);
		});
	}, []);

	if (pending) {
		return <PageLoader />;
	}

	return (
		<AuthContext.Provider value={{ currentUser }}>
			{children}
		</AuthContext.Provider>
	);
};
