import { useCallback, useContext } from 'react';
import { validateName } from '../utils/validation';
import { MapContext } from '../contexts/mapKeyProvider';

export const useInitAutocomplete = (inputIds, types, geoPoint) => {
	const { key } = useContext(MapContext);

	// params
	const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? key;
	const mapApiJs = 'https://maps.googleapis.com/maps/api/js';
	const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';

	// load google map api js
	const loadAsyncScript = src => {
		return new Promise(resolve => {
			const script = document.createElement('script');
			Object.assign(script, {
				type: 'text/javascript',
				async: true,
				src,
			});
			script.addEventListener('load', () => resolve(script));
			document.head.appendChild(script);
		});
	};

	// location error is not being catered for by the find my location function
	// so set it here on change of values

	const checkLocationError = useCallback((id, setError, val, setVal) => {
		const locationElement = document.querySelector(id);
		const focusSetter = true;
		validateName(
			locationElement,
			locationElement.id + 'Error',
			focusSetter,
		);
		setError(val, setVal);
		// eslint-disable-next-line
	}, []);

	// reverse geocode with lat and lng
	const reverseGeocode = (
		{ latitude: lat, longitude: lng },
		setValues,
		coordinates,
		id,
		compassId,
		setError,
		val,
		setVal,
	) => {
		const ref = document.querySelector(id);
		const compassRef = document.querySelector(compassId);
		const url = `${geocodeJson}?key=${apiKey}&latlng=${lat},${lng}`;
		let place;
		fetch(url)
			.then(response => response.json())
			.then(location => {
				place = location.results[1].formatted_address;
			})
			.then(() => {
				ref.value = place;
				ref.disabled = false;
				compassRef.classList.remove('wobble-hor-bottom');
				coordinates
					? setValues(undefined, undefined, ref, lat, lng)
					: setValues(undefined, undefined, ref);
				checkLocationError(id, setError, val, setVal);
			})
			.catch(error => {
				console.log(error);
				ref.disabled = false;
			});
	};

	// find my location
	const findMyLocation = (
		setValues,
		coordinates,
		id,
		compassId,
		setError,
		val,
		setVal,
	) => {
		const ref = document.querySelector(id);
		const compassRef = document.querySelector(compassId);
		ref.disabled = true;
		compassRef.classList.add('wobble-hor-bottom');
		navigator.geolocation.getCurrentPosition(position =>
			reverseGeocode(
				position.coords,
				setValues,
				coordinates,
				id,
				compassId,
				setError,
				val,
				setVal,
			),
		);
	};

	const geocode = async address => {
		let latitude;
		let longitude;
		const url = `${geocodeJson}?key=${apiKey}&address=${address}`;
		await fetch(url)
			.then(response => response.json())
			.then(location => {
				if (location.results[0]) {
					const { lat, lng } = location.results[0].geometry.location;
					latitude = lat;
					longitude = lng;
				}
			})
			.then(() => {
				return { lat: latitude, lng: longitude };
			});
		return { lat: latitude, lng: longitude };
	};

	// geocoding
	const geocoding = (address, id, setValues) => {
		const ref = document.querySelector(id);
		const url = `${geocodeJson}?key=${apiKey}&address=${address}`;
		fetch(url)
			.then(response => response.json())
			.then(location => {
				if (location.results[0]) {
					const { lat, lng } = location.results[0].geometry.location;
					setValues(undefined, undefined, ref, lat, lng);
				}
			});
	};

	const geocodeReverser = ({ lat, lng }) => {
		const url = `${geocodeJson}?key=${apiKey}&latlng=${lat},${lng}`;
		return fetch(url).then(response => response.json());
	};

	// area change location for those who want to type everything
	const handleAreaLocationChange = (e, setValues) => {
		const { value, id } = e.target;
		geocoding(value, `#${id}`, setValues);
		setValues(e);
	};

	// call on change when a place is selected
	let onChangeAddress = (autocomplete, ref, geoPoints, setValues) => {
		const location = autocomplete.getPlace();
		const lat = location.geometry.location.lat();
		const lng = location.geometry.location.lng();
		geoPoints
			? setValues(undefined, undefined, ref, lat, lng)
			: setValues(undefined, undefined, ref);
	};

	const listenForChange = (
		autocomplete,
		autocompleteRef,
		geoPoints,
		setValues,
	) => {
		autocomplete.addListener('place_changed', () =>
			onChangeAddress(
				autocomplete,
				autocompleteRef,
				geoPoints,
				setValues,
			),
		);
	};

	// init autocomplete
	const initAutocomplete = useCallback((id, types, geoPoints) => {
		// autocomplete options
		const center = { lat: 7.9465, lng: -1.0232 };
		// Create a bounding box with sides ~10km away from the center point
		const defaultBounds = {
			north: center.lat + 0.1,
			south: center.lat - 0.1,
			east: center.lng + 0.1,
			west: center.lng - 0.1,
		};

		const options = !types
			? {
					bounds: defaultBounds,
					componentRestrictions: { country: 'gh' },
					fields: ['address_components', 'geometry'],
					strictBounds: false,
			  }
			: {
					bounds: defaultBounds,
					componentRestrictions: { country: 'gh' },
					fields: ['address_components', 'geometry'],
					strictBounds: false,
					types,
			  };

		const autocompleteRef = document.querySelector(id);
		if (!autocompleteRef) return;
		const autocomplete = new window.google.maps.places.Autocomplete(
			autocompleteRef,
			options,
		);
		autocomplete.setFields(['ALL']);
		return { autocomplete, autocompleteRef, geoPoints };
	}, []);

	const initMapScript = useCallback(() => {
		// if script already loaded
		if (window.google) {
			return Promise.resolve();
		}
		const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
		return loadAsyncScript(src);
	}, [apiKey]);

	const loadAutoComplete = useCallback(
		() => {
			return initMapScript().then(() => {
				return inputIds.map((id, index) =>
					initAutocomplete(id, types[index], geoPoint[index]),
				);
			});
		},
		// eslint-disable-next-line
		[initMapScript, initAutocomplete],
	);

	return {
		loadAutoComplete,
		listenForChange,
		findMyLocation,
		geocoding,
		handleAreaLocationChange,
		geocodeReverser,
		geocode,
	};
};
