import { useState, useCallback } from 'react';

export const useProfileDropdownFunctions = () => {
	//show or un-show profile dropdown
	const [showProfileDropdown, setShowProfileDropdown] = useState(false);

	//function to toggle dropdown
	const toggleDropDown = () => {
		setShowProfileDropdown(!showProfileDropdown);
	};

	const closeDropdown = () => {
		setShowProfileDropdown(false);
	};

	const checkClick = useCallback((e, elementClass, closeFunc) => {
		if (e.target && e.target.parentElement) {
			// !(e.target.classList.contains(elementClass) || e.target.parentElement.classList.contains(elementClass)) && closeFunc();
			if (
				!(
					e.target.classList.contains(elementClass) ||
					e.target.parentElement.classList.contains(elementClass)
				)
			) {
				closeFunc();
			}
		}
	}, []);

	const hideDropDownOnClick = (elementClass, closeFunc) => {
		window.addEventListener('click', e => {
			checkClick(e, elementClass, closeFunc);
		});
	};

	const cleanUpDropDownOnClick = (elementClass, closeFunc) => {
		window.removeEventListener('click', e => {
			checkClick(e, elementClass, closeFunc);
		});
	};

	return {
		showProfileDropdown,
		setShowProfileDropdown,
		toggleDropDown,
		closeDropdown,
		hideDropDownOnClick,
		cleanUpDropDownOnClick,
		checkClick,
	};
};
