import { takeLatest, call, put, all } from 'redux-saga/effects';
import PropertyDataActionTypes from './properties-data.types';
import {
	fetchPropertiesFailure,
	fetchPropertiesSuccess,
	refetchPropertiesFailure,
	refetchPropertiesSuccess,
	setDataEmpty,
	setLatestDoc,
} from './properties-data.actions';
import { firestore } from '../../firebase/firebase.config';
import { convertPropertySnapshotToMap } from '../../firebase/firebase.helpers';

//  Fetch Region Data
function* fetchProperties({ payload }) {
	try {
		const propertiesRef = firestore.collection('properties');
		const query = yield propertiesRef
			.where('region', '==', payload.region)
			.where('isComplete', '==', true)
			.orderBy('timestamp', 'desc')
			.limit(payload.limit);
		const snapshot = yield query.get();
		const transformedData = yield call(
			convertPropertySnapshotToMap,
			snapshot,
		);
		let lastVisible = snapshot.docs[snapshot.docs.length - 1];
		yield put(setLatestDoc(lastVisible));
		yield put(fetchPropertiesSuccess(transformedData));
	} catch (error) {
		yield put(fetchPropertiesFailure(error.message));
	}
}

function* onFetchRegionPropertiesStart() {
	yield takeLatest(
		PropertyDataActionTypes.FETCH_PROPERTIES_START,
		fetchProperties,
	);
}

//  Refetch properties
function* refetchProperties({ payload }) {
	try {
		const propertiesRef = firestore.collection('properties');
		const query = yield propertiesRef
			.where('region', '==', payload.region)
			.startAfter(payload.latestDoc)
			.limit(payload.limit);
		const snapshot = yield query.get();
		const transformedData = yield call(
			convertPropertySnapshotToMap,
			snapshot,
		);
		let lastVisible = snapshot.docs[snapshot.docs.length - 1];

		yield put(setLatestDoc(lastVisible));
		yield put(refetchPropertiesSuccess(transformedData));
		if (payload.limit > transformedData.length || snapshot.empty) {
			return yield put(setDataEmpty());
		}
	} catch (error) {
		yield put(refetchPropertiesFailure(error.message));
	}
}

function* onRefetchRegionProperties() {
	yield takeLatest(
		PropertyDataActionTypes.REFETCH_PROPERTIES_START,
		refetchProperties,
	);
}

export function* propertyDataSagas() {
	yield all([
		call(onFetchRegionPropertiesStart),
		call(onRefetchRegionProperties),
	]);
}
