import UserActionTypes from './user.types';

//  Sign up
export const signUpStart = userCredentials => ({
	type: UserActionTypes.SIGN_UP_START,
	payload: userCredentials,
});

export const signUpSuccess = ({ user, additionalData }) => ({
	type: UserActionTypes.SIGN_UP_SUCCESS,
	payload: { user, additionalData },
});

export const signUpFailure = error => ({
	type: UserActionTypes.SIGN_UP_FAILURE,
	payload: error,
});

export const clearSignUpSuccess = () => ({
	type: UserActionTypes.CLEAR_SIGN_UP_SUCCESS,
});

//  Sign in
export const googleSignInStart = () => ({
	type: UserActionTypes.GOOGLE_SIGN_IN_START,
});

export const emailSignInStart = emailAndPassword => ({
	type: UserActionTypes.EMAIL_SIGN_IN_START,
	payload: emailAndPassword,
});

export const signInSuccess = user => ({
	type: UserActionTypes.SIGN_IN_SUCCESS,
	payload: user,
});

export const signInFailure = error => ({
	type: UserActionTypes.SIGN_IN_FAILURE,
	payload: error,
});

//  Sign Out
export const signOutStart = () => ({
	type: UserActionTypes.SIGN_OUT_START,
});

export const signOutSuccess = () => ({
	type: UserActionTypes.SIGN_OUT_SUCCESS,
});

export const signOutFailure = error => ({
	type: UserActionTypes.SIGN_OUT_FAILURE,
	payload: error,
});

//  Check user session
export const checkUserSession = () => ({
	type: UserActionTypes.CHECK_USER_SESSION,
});

//  Reset password
export const passwordResetStart = email => ({
	type: UserActionTypes.PASSWORD_RESET_START,
	payload: email,
});

export const passwordResetSuccess = successMessage => ({
	type: UserActionTypes.PASSWORD_RESET_SUCCESS,
	payload: successMessage,
});

export const passwordResetFailure = error => ({
	type: UserActionTypes.PASSWORD_RESET_FAILURE,
	payload: error,
});

export const clearPasswordResetSuccess = () => ({
	type: UserActionTypes.CLEAR_PASSWORD_RESET_SUCCESS,
});

//  Send verification
export const sendVerificationStart = user => ({
	type: UserActionTypes.SEND_VERIFICATION_START,
	payload: user,
});

export const sendVerificationSuccess = () => ({
	type: UserActionTypes.SEND_VERIFICATION_SUCCESS,
});

export const sendVerificationFailure = error => ({
	type: UserActionTypes.SEND_VERIFICATION_FAILURE,
	payload: error,
});

//  Edit profile
export const editUserStart = ({ userDetails, user }) => ({
	type: UserActionTypes.EDIT_USER_PROFILE_START,
	payload: { userDetails, user },
});

export const editUserSuccess = successMessage => ({
	type: UserActionTypes.EDIT_USER_PROFILE_SUCCESS,
	payload: successMessage,
});

export const editUserFailure = error => ({
	type: UserActionTypes.EDIT_USER_PROFILE_FAILURE,
	payload: error,
});

export const clearEditSuccess = () => ({
	type: UserActionTypes.CLEAR_EDIT_SUCCESS,
});

//  Delete profile Image
export const deleteProfileImageStart = profileUrl => ({
	type: UserActionTypes.DELETE_USER_PROFILE_IMAGE_START,
	payload: profileUrl,
});

export const deleteProfileImageSuccess = successMessage => ({
	type: UserActionTypes.DELETE_USER_PROFILE_IMAGE_SUCCESS,
	payload: successMessage,
});

export const deleteProfileImageFailure = error => ({
	type: UserActionTypes.DELETE_USER_PROFILE_IMAGE_FAILURE,
	payload: error,
});

//  Delete user account
export const deleteUserAccountStart = ({ user, password }) => ({
	type: UserActionTypes.DELETE_USER_ACCOUNT_START,
	payload: { user, password },
});

export const deleteUserAccountSuccess = successMessage => ({
	type: UserActionTypes.DELETE_USER_ACCOUNT_SUCCESS,
	payload: successMessage,
});

export const deleteUserAccountFailure = error => ({
	type: UserActionTypes.DELETE_USER_ACCOUNT_FAILURE,
	payload: error,
});

// Update Image Property Profile
export const updateProfileImagesStart = profileInfo => ({
	type: UserActionTypes.UPDATE_PROPERTY_PROFILE_IMAGES_START,
	payload: profileInfo,
});

export const updateProfileImagesSuccess = successMessage => ({
	type: UserActionTypes.UPDATE_PROPERTY_PROFILE_IMAGES_SUCCESS,
	payload: successMessage,
});

export const updateProfileImagesFailure = error => ({
	type: UserActionTypes.UPDATE_PROPERTY_PROFILE_IMAGES_FAILURE,
	payload: error,
});

//error
export const setUserErrorFalse = () => ({
	type: UserActionTypes.SET_USER_ERROR_FALSE,
});

export const clearUserStatus = () => ({
	type: UserActionTypes.CLEAR_USER_STATUS,
});
