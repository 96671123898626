import ExploreActionTypes from './explore.types';

export const fetchRegionPropertiesStart = ({ region, id }) => ({
	type: ExploreActionTypes.FETCH_REGION_PROPERTIES_START,
	payload: { region, id },
});

export const fetchRegionPropertiesSuccess = properties => ({
	type: ExploreActionTypes.FETCH_REGION_PROPERTIES_SUCCESS,
	payload: properties,
});

export const fetchRegionPropertiesFailure = error => ({
	type: ExploreActionTypes.FETCH_REGION_PROPERTIES_FAILURE,
	payload: error,
});

export const emptyRegionProperties = () => ({
	type: ExploreActionTypes.EMPTY_REGION_PROPERTIES,
});
