import React from 'react';
import { ErrorWrapper } from './errorStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Small } from '../../globals/appGlobal';

const Error = ({ message }) => {
	return (
		<ErrorWrapper top>
			<FontAwesomeIcon icon={faExclamationCircle} />
			<Small>{message}</Small>
		</ErrorWrapper>
	);
};

export default Error;
