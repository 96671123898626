const BookingTypes = {
	USER_BOOKING_START: 'USER_BOOKING_START',
	USER_BOOKING_SUCCESS: 'USER_BOOKING_SUCCESS',
	USER_BOOKING_FAILURE: 'USER_BOOKING_FAILURE',
	CHECK_IN_START: 'CHECK_IN_START',
	CHECK_IN_SUCCESS: 'CHECK_IN_SUCCESS',
	CHECK_IN_FAILURE: 'CHECK_IN_FAILURE',
	CHECK_OUT_START: 'CHECK_OUT_START',
	CHECK_OUT_SUCCESS: 'CHECK_OUT_SUCCESS',
	CHECK_OUT_FAILURE: 'CHECK_OUT_FAILURE',
	CLEAR_BOOKING_SUCCESS_MESSAGE: 'CLEAR_BOOKING_SUCCESS_MESSAGE',
	CANCEL_BOOKING_START: 'CANCEL_BOOKING_START',
	CANCEL_BOOKING_SUCCESS: 'CANCEL_BOOKING_SUCCESS',
	CANCEL_BOOKING_FAILURE: 'CANCEL_BOOKING_FAILURE',
	CLEAR_CANCEL_BOOKING_SUCCESS: 'CLEAR_CANCEL_BOOKING_SUCCESS',
	CLEAR_BOOKING_STATUS: 'CLEAR_BOOKING_STATUS',
};

export default BookingTypes;
