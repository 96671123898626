import React from 'react';
import DesktopFooter from './desktop-footer/desktopFooter';
import PhoneFooter from './phone-footer/phoneFooter';

const Footer = () => {
	const x = window.matchMedia('(max-width: 900px)');

	if (x.matches) {
		return <PhoneFooter />;
	}
	return <DesktopFooter />;
};

export default Footer;
