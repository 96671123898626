import { takeLatest, call, put, all } from 'redux-saga/effects';
import PaymentActionTypes from './payment.types';
import { firestore } from '../../firebase/firebase.config';
import {
	fetchCheckoutPropertyFailure,
	fetchCheckoutPropertySuccess,
	updateLastDatePaidFailure,
	updateLastDatePaidSuccess,
} from './payment.actions';

//	Fetch checkout property
function* fetchCheckoutProperty({ payload }) {
	try {
		const propertyDoc = firestore.collection('properties').doc(payload);
		const propertySnapshot = yield propertyDoc.get();
		const propertyData = yield propertySnapshot.data();

		yield put(fetchCheckoutPropertySuccess(propertyData));
	} catch (error) {
		yield put(fetchCheckoutPropertyFailure(error.message));
	}
}

function* onFetchCheckoutPropertyStart() {
	yield takeLatest(
		PaymentActionTypes.FETCH_CHECKOUT_PROPERTY_START,
		fetchCheckoutProperty,
	);
}

//  Update date after putting up ad
function* updateLastPaidDate({ payload }) {
	const { property, lastDatePaid, subscription_type } = payload;
	const { uid } = property;
	const last_date_paid = property.last_date_paid ?? '';

	try {
		last_date_paid
			? yield firestore
					.collection('properties')
					.doc(uid)
					.update({
						...payload,
						last_date_paid: lastDatePaid,
						subscription_type,
					})
			: yield firestore.collection('properties').doc(uid).set(
					{
						last_date_paid: lastDatePaid,
						subscription_type,
					},
					{ merge: true },
			  );

		yield put(
			updateLastDatePaidSuccess({ lastDatePaid, subscription_type }),
		);
	} catch (error) {
		yield put(updateLastDatePaidFailure(error.message));
	}
}

function* onUpdateLastDatePaidStart() {
	yield takeLatest(
		PaymentActionTypes.UPDATE_LAST_DATE_PAID_START,
		updateLastPaidDate,
	);
}

export function* paymentSagas() {
	yield all([
		call(onUpdateLastDatePaidStart),
		call(onFetchCheckoutPropertyStart),
	]);
}
