import BookingTypes from './book.types';

export const userBookingStart = bookingDetails => ({
	type: BookingTypes.USER_BOOKING_START,
	payload: bookingDetails,
});

export const userBookingSuccess = successMessage => ({
	type: BookingTypes.USER_BOOKING_SUCCESS,
	payload: successMessage,
});

export const userBookingFailure = error => ({
	type: BookingTypes.USER_BOOKING_FAILURE,
	payload: error,
});

export const checkInStart = payload => ({
	type: BookingTypes.CHECK_IN_START,
	payload, // userId, propertyDetails
});

export const checkInSuccess = successMessage => ({
	type: BookingTypes.CHECK_IN_SUCCESS,
	payload: successMessage,
});

export const checkInFailure = error => ({
	type: BookingTypes.CHECK_IN_FAILURE,
	payload: error,
});

export const checkOutStart = payload => ({
	type: BookingTypes.CHECK_OUT_START,
	payload, // userId, propertyDetails
});

export const checkOutSuccess = successMessage => ({
	type: BookingTypes.CHECK_OUT_SUCCESS,
	payload: successMessage,
});

export const checkOutFailure = error => ({
	type: BookingTypes.CHECK_OUT_FAILURE,
	payload: error,
});

export const clearBookingSuccess = () => ({
	type: BookingTypes.CLEAR_BOOKING_SUCCESS_MESSAGE,
});

export const cancelBookingStart = payload => ({
	type: BookingTypes.CANCEL_BOOKING_START,
	payload,
});

export const cancelBookingSuccess = successMessage => ({
	type: BookingTypes.CANCEL_BOOKING_SUCCESS,
	payload: successMessage,
});

export const cancelBookingFailure = error => ({
	type: BookingTypes.CANCEL_BOOKING_FAILURE,
	payload: error,
});

export const clearCancelBookingSuccess = () => ({
	type: BookingTypes.CLEAR_CANCEL_BOOKING_SUCCESS,
});

export const clearBookingStatus = () => ({
	type: BookingTypes.CLEAR_BOOKING_STATUS,
});
