import React from 'react';
import { ErrorPageWrapper } from '../error-page-components/errorPageStyles';
import ErrorSvg from '../../assets/svg/wrong.svg';
import { Flex, HeaderTwo, Paragraph, Sub } from '../../globals/appGlobal';
import Button from '../button/button';

const ErrorBoundaryMain = () => {
	return (
		<ErrorPageWrapper num={['1fr 1fr']} gap='50px' left>
			<img src={ErrorSvg} alt='error' />
			<div>
				<HeaderTwo className='make-green'>
					Something went wrong
				</HeaderTwo>
				<Paragraph>
					<Sub>
						We're constantly working to get issues fixed to give you
						the best experience. But do feel free to contact us if
						refreshing doesn't fix things. Email us via{' '}
						<a
							href='mailto:info@edanra.com'
							target='_blank'
							rel='noreferrer'
							style={{ color: 'teal' }}
						>
							info@edanra.com
						</a>
					</Sub>
				</Paragraph>
				<Flex>
					<Button anchor='/' noTarget={true}>
						Take me home
					</Button>
					<Button
						anchor='/host-property'
						noTarget={true}
						type='secondary'
					>
						Host a property
					</Button>
				</Flex>
			</div>
		</ErrorPageWrapper>
	);
};

export default ErrorBoundaryMain;
