import { all, put, call, takeLatest } from 'redux-saga/effects';
import HostProfileTypes from './host-profile.types';
import { firestore } from '../../firebase/firebase.config';
import {
	fetchAllUserPropertiesFailure,
	fetchAllUserPropertiesSuccess,
	fetchHostProfileDetailsFailure,
	fetchHostProfileDetailsSuccess,
} from './host-profile.actions';
import { convertPropertySnapshotToMap } from '../../firebase/firebase.helpers';

//	Fetch host details
function* fetchHostProfileDetails({ payload }) {
	try {
		const userRef = yield firestore.collection('users').doc(payload);
		const userSnapshot = yield userRef.get();
		const userData = yield userSnapshot.data();
		yield put(fetchHostProfileDetailsSuccess(userData));
	} catch (error) {
		yield put(fetchHostProfileDetailsFailure(error.message));
	}
}

function* onFetchHostProfileDetailsStart() {
	yield takeLatest(
		HostProfileTypes.FETCH_HOST_PROFILE_DETAILS_START,
		fetchHostProfileDetails,
	);
}

//	Fetch All Users Properties
function* fetchAllUserProperties({ payload }) {
	try {
		const propertiesRef = firestore.collection('properties');
		const userProperties = yield propertiesRef
			.where('user_id', '==', payload)
			.where('isComplete', '==', true)
			.orderBy('timestamp', 'desc');
		const userPropertiesSnapshot = yield userProperties.get();
		const transformedData = yield call(
			convertPropertySnapshotToMap,
			userPropertiesSnapshot,
		);
		const completeProperties = transformedData.filter(
			property => property.isComplete,
		);

		yield put(fetchAllUserPropertiesSuccess(completeProperties));
	} catch (error) {
		yield put(fetchAllUserPropertiesFailure(error.message));
	}
}

function* onFetchAllUserPropertiesStart() {
	yield takeLatest(
		HostProfileTypes.FETCH_ALL_USER_PROPERTIES_START,
		fetchAllUserProperties,
	);
}

export function* hostProfileSagas() {
	yield all([
		call(onFetchHostProfileDetailsStart),
		call(onFetchAllUserPropertiesStart),
	]);
}
