import AvailabilityTypes from './availability.types';

const INITIAL_STATE = {
	success: '',
	error: '',
	removeReservedSuccess: '',
	removeReservedError: '',
	removeSingleReservedSuccess: '',
	removeSingleReservedError: '',
	isLoading: false,
	isClearing: false,
};

const availabilityReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case AvailabilityTypes.RESERVE_DATES_START:
			return {
				...state,
				success: '',
				error: '',
				isLoading: true,
			};

		case AvailabilityTypes.RESERVE_DATES_SUCCESS:
			return {
				...state,
				success: action.payload,
				error: '',
				isLoading: false,
			};

		case AvailabilityTypes.RESERVE_DATES_FAILURE:
			return {
				...state,
				success: '',
				error: action.payload,
				isLoading: false,
			};

		case AvailabilityTypes.REMOVE_RESERVED_START:
			return {
				...state,
				removeReservedError: '',
				removeReservedSuccess: '',
				isClearing: true,
			};

		case AvailabilityTypes.REMOVE_RESERVED_SUCCESS:
			return {
				...state,
				removeReservedError: '',
				removeReservedSuccess: action.payload,
				isClearing: false,
			};

		case AvailabilityTypes.REMOVE_RESERVED_FAILURE:
			return {
				...state,
				removeReservedError: action.payload,
				removeReservedSuccess: '',
				isClearing: false,
			};

		case AvailabilityTypes.REMOVE_SINGLE_RESERVED_START:
			return {
				...state,
				removeSingleReservedSuccess: '',
				removeSingleReservedError: '',
				isClearing: true,
			};

		case AvailabilityTypes.REMOVE_SINGLE_RESERVED_SUCCESS:
			return {
				...state,
				removeSingleReservedSuccess: action.payload,
				removeSingleReservedError: '',
				isClearing: false,
			};

		case AvailabilityTypes.REMOVE_SINGLE_RESERVED_FAILURE:
			return {
				...state,
				removeSingleReservedSuccess: '',
				removeSingleReservedError: action.payload,
				isClearing: false,
			};

		case AvailabilityTypes.CLEAR_RESERVED_STATUS:
			return {
				...state,
				success: '',
				error: '',
				removeReservedSuccess: '',
				removeReservedError: '',
				removeSingleReservedSuccess: '',
				removeSingleReservedError: '',
			};

		default:
			return state;
	}
};

export default availabilityReducer;
