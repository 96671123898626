const AvailabilityTypes = {
	RESERVE_DATES_START: 'RESERVE_DATES_START',
	RESERVE_DATES_SUCCESS: 'RESERVE_DATES_SUCCESS',
	RESERVE_DATES_FAILURE: 'RESERVE_DATES_FAILURE',
	REMOVE_RESERVED_START: 'REMOVE_RESERVED_START',
	REMOVE_RESERVED_SUCCESS: 'REMOVE_RESERVED_SUCCESS',
	REMOVE_RESERVED_FAILURE: 'REMOVE_RESERVED_FAILURE',
	CLEAR_RESERVED_STATUS: 'CLEAR_RESERVED_STATUS',
	REMOVE_SINGLE_RESERVED_START: 'REMOVE_SINGLE_RESERVED_START',
	REMOVE_SINGLE_RESERVED_SUCCESS: 'REMOVE_SINGLE_RESERVED_SUCCESS',
	REMOVE_SINGLE_RESERVED_FAILURE: 'REMOVE_SINGLE_RESERVED_FAILURE',
};

export default AvailabilityTypes;
