import ManageAdTypes from './manage-ad.types';

export const fetchUserPropertiesStart = () => ({
	type: ManageAdTypes.FETCH_USER_PROPERTIES_START,
});

export const fetchUserPropertiesSuccess = properties => ({
	type: ManageAdTypes.FETCH_USER_PROPERTIES_SUCCESS,
	payload: properties,
});

export const fetchUserPropertiesFailure = error => ({
	type: ManageAdTypes.FETCH_USER_PROPERTIES_FAILURE,
	payload: error,
});

export const fetchIncompleteProperties = properties => ({
	type: ManageAdTypes.FETCH_INCOMPLETE_PROPERTIES,
	payload: properties,
});

export const deletePropertyStart = uid => ({
	type: ManageAdTypes.DELETE_PROPERTY_START,
	payload: uid,
});

export const deletePropertySuccess = () => ({
	type: ManageAdTypes.DELETE_PROPERTY_SUCCESS,
});

export const deletePropertyFailure = error => ({
	type: ManageAdTypes.DELETE_PROPERTY_FAILURE,
	payload: error,
});
