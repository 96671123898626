import React from 'react';
import { ButtonWrapper } from './buttonStyles';
import { Paragraph } from '../../globals/appGlobal';
import { HashLink as Hash } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

const Button = ({
	type,
	children,
	icon,
	circular,
	size,
	aria,
	iconRight,
	clickEvent,
	link,
	hashLink,
	anchor,
	disabled,
	id,
	className,
	noTarget,
}) => {
	if (link) {
		return (
			<Link to={link}>
				<ButtonWrapper
					type={type}
					circular={circular}
					size={size}
					aria-label={aria}
					iconRight={iconRight}
					onClick={clickEvent}
					disabled={disabled}
					id={id}
					className={className}
				>
					{!iconRight && icon && icon}
					<Paragraph
						style={{ margin: 0, lineHeight: '1.15' }}
						className={className}
					>
						{children}
					</Paragraph>
					{iconRight && icon && icon}
				</ButtonWrapper>
			</Link>
		);
	} else if (hashLink) {
		return (
			<Hash to={hashLink}>
				<ButtonWrapper
					type={type}
					circular={circular}
					size={size}
					aria-label={aria}
					iconRight={iconRight}
					onClick={clickEvent}
					disabled={disabled}
					id={id}
					className={className}
				>
					{!iconRight && icon && icon}
					<Paragraph
						style={{ margin: 0, lineHeight: '1.15' }}
						className={className}
					>
						{children}
					</Paragraph>
					{iconRight && icon && icon}
				</ButtonWrapper>
			</Hash>
		);
	} else if (anchor) {
		return (
			<a
				href={anchor}
				target={!noTarget ? '_blank' : ''}
				rel='noreferrer'
				className='button-a'
			>
				<ButtonWrapper
					type={type}
					circular={circular}
					size={size}
					aria-label={aria}
					iconRight={iconRight}
					onClick={clickEvent}
					disabled={disabled}
					id={id}
					className={className}
				>
					{!iconRight && icon && icon}
					<Paragraph
						style={{ margin: 0, lineHeight: '1.15' }}
						className={className}
					>
						{children}
					</Paragraph>
					{iconRight && icon && icon}
				</ButtonWrapper>
			</a>
		);
	} else {
		return (
			<ButtonWrapper
				type={type}
				circular={circular}
				size={size}
				aria-label={aria}
				iconRight={iconRight}
				onClick={clickEvent}
				disabled={disabled}
				id={id}
				className={className}
			>
				{!iconRight && icon && icon}
				<Paragraph
					style={{ margin: 0, lineHeight: '1.15' }}
					className={className}
				>
					{children}
				</Paragraph>
				{iconRight && icon && icon}
			</ButtonWrapper>
		);
	}
};

export default Button;
