import PropertyDataActionTypes from './properties-data.types';

const INITIAL_STATE = {
	propertyItems: [],
	limit: 0,
	lastVisible: null,
	isFetching: true,
	reFetching: false,
	dataEmpty: false,
	error: '',
};

const propertiesDataReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case PropertyDataActionTypes.FETCH_PROPERTIES_START:
			return {
				...state,
				isFetching: true,
				limit: action.payload.limit,
				error: '',
			};

		case PropertyDataActionTypes.FETCH_PROPERTIES_SUCCESS:
			return {
				...state,
				isFetching: false,
				error: '',
				propertyItems: action.payload,
			};

		case PropertyDataActionTypes.FETCH_PROPERTIES_FAILURE:
			return {
				...state,
				isFetching: false,
				error: action.payload,
			};

		case PropertyDataActionTypes.SET_LATEST_DOC:
			return {
				...state,
				lastVisible: action.payload,
			};

		case PropertyDataActionTypes.SET_DATA_EMPTY:
			return {
				...state,
				dataEmpty: true,
				reFetching: false,
			};

		case PropertyDataActionTypes.REFETCH_PROPERTIES_START:
			return {
				...state,
				reFetching: true,
				limit: action.payload.limit,
				lastVisible: action.payload.latestDoc,
				error: '',
			};

		case PropertyDataActionTypes.REFETCH_PROPERTIES_SUCCESS:
			return {
				...state,
				reFetching: false,
				error: '',
				propertyItems: [...state.propertyItems, ...action.payload],
			};

		case PropertyDataActionTypes.REFETCH_PROPERTIES_FAILURE:
			return {
				...state,
				reFetching: false,
				error: action.payload,
			};

		case PropertyDataActionTypes.CLEAR_PROPERTIES:
			return {
				...state,
				propertyItems: [],
				dataEmpty: false,
			};

		default:
			return state;
	}
};

export default propertiesDataReducer;
