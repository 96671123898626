import { all, call } from 'redux-saga/effects';
import { userSagas } from './user/user.sagas';
import { propertyUploadSagas } from './property-upload/property-upload.sagas';
import { propertyDataSagas } from './properties-data/properties-data.sagas';
import { saveAdSagas } from './save-ad/save-ad.sagas';
import { manageAdSagas } from './manage-ad/manage-ad.sagas';
import { propertyDetailsSagas } from './property-details/property-details.sagas';
import { reviewSagas } from './review/review.sagas';
import { searchSagas } from './search/search.sagas';
import { exploreSagas } from './explore/explore.sagas';
import { bookSagas } from './book/book.sagas';
import { bookingDataSagas } from './booking-data/booking-data.sagas';
import { paymentSagas } from './payment/payment.sagas';
import { reserveDatesSagas } from './availability/availability.sagas';
import { hostProfileSagas } from './host-profile/host-profile.sagas';

export default function* rootSaga() {
	yield all([
		call(userSagas),
		call(propertyUploadSagas),
		call(propertyDataSagas),
		call(saveAdSagas),
		call(manageAdSagas),
		call(propertyDetailsSagas),
		call(reviewSagas),
		call(searchSagas),
		call(exploreSagas),
		call(bookSagas),
		call(bookingDataSagas),
		call(paymentSagas),
		call(reserveDatesSagas),
		call(hostProfileSagas),
	]);
}
