import { all, put, call, takeLatest } from 'redux-saga/effects';
import BookingDataTypes from './booking-data.types';
import { firestore, functions } from '../../firebase/firebase.config';
import {
	fetchGuestBookedFailure,
	fetchGuestBookedSuccess,
} from './booking-data.actions';
import { convertPropertySnapshotToMap } from '../../firebase/firebase.helpers';

function* fetchGuestBookings({ payload }) {
	try {
		if (payload.length > 0 && payload.length <= 10) {
			const propertiesRef = firestore.collection('properties');
			const guestBooked = yield propertiesRef
				.where('uid', 'in', payload)
				.where('isComplete', '==', true);
			const guestBookedSnapshot = yield guestBooked.get();
			const transformedGuestBookedData = yield call(
				convertPropertySnapshotToMap,
				guestBookedSnapshot,
			);
			yield put(fetchGuestBookedSuccess(transformedGuestBookedData));
		} else if (payload.length > 10) {
			const fetchArrayProperties = functions.httpsCallable(
				'fetchArrayProperties',
			);
			const {
				data: { properties },
			} = yield fetchArrayProperties({ array: payload });
			yield put(fetchGuestBookedSuccess(properties));
		} else {
			yield put(fetchGuestBookedSuccess([]));
		}
	} catch (error) {
		yield put(fetchGuestBookedFailure(error.message));
	}
}

function* onFetchGuestBookingsStart() {
	yield takeLatest(
		BookingDataTypes.FETCH_GUEST_BOOKED_START,
		fetchGuestBookings,
	);
}

export function* bookingDataSagas() {
	yield all([call(onFetchGuestBookingsStart)]);
}
