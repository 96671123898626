import BookingDataTypes from './booking-data.types';

export const setGuestBooked = booked => ({
	type: BookingDataTypes.SET_BOOKED_PROPERTIES,
	payload: booked,
});

export const fetchGuestBookedStart = booked => ({
	type: BookingDataTypes.FETCH_GUEST_BOOKED_START,
	payload: booked,
});

export const fetchGuestBookedSuccess = bookedProperties => ({
	type: BookingDataTypes.FETCH_GUEST_BOOKED_SUCCESS,
	payload: bookedProperties,
});

export const fetchGuestBookedFailure = error => ({
	type: BookingDataTypes.FETCH_GUEST_BOOKED_FAILURE,
	payload: error,
});

export const fetchHomeOwnerBookedSuccess = properties => ({
	type: BookingDataTypes.FETCH_HOME_OWNERS_BOOKED_SUCCESS,
	payload: properties,
});
