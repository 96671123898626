//default values
let message = '';
let errorReturn = '';
let proofPassword = '';

//create focusable
let focusable = false;

// Error object to be returned
export const errorObject = {
	error: errorReturn,
	message: message,
};

const checkFocus = field => {
	if (focusable) {
		field.focus();
	}
};

// valid and invalid decoration

const makeValid = (field, error) => {
	const array = field.classList;
	errorObject.error = error;
	errorObject.message = '';
	array.remove('error-border');
};

const makeInvalid = (field, message, error) => {
	errorObject.error = error;
	errorObject.message = message;
	field.classList.add('error-border');
	checkFocus(field);
};

// UTILS
const validateEmpty = (field, error) => {
	const value = field.value;
	if (value.trim() === '') {
		makeInvalid(field, 'Please do not leave blank', error);
		return false;
	} else {
		makeValid(field, error);
		return true;
	}
};

const containsCharacters = (field, code, error) => {
	let regEx;
	switch (code) {
		case 1:
			//contains letters
			regEx = /(?=.*[a-zA-Z])/;
			return matchWithRegEx(
				field,
				regEx,
				'Must contain at least one letter',
				error,
			);
		case 2:
			// contain letters and numbers
			regEx = /(?=.*[a-zA-Z])(?=.*\d)/;
			return matchWithRegEx(
				field,
				regEx,
				'Must contain at least one letter and one number',
				error,
			);
		case 3:
			//contain lower case, uppercase and numbers
			regEx = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/;
			return matchWithRegEx(
				field,
				regEx,
				'Must contain at least one lower case letter, one upper case letter and one number',
				error,
			);
		case 4:
			//contain lowercase, uppercase, numbers and symbols
			regEx = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)/;
			return matchWithRegEx(
				field,
				regEx,
				'Must contain at least one lower case letter, one upper case letter, one number and one special character',
				error,
			);
		case 5:
			// for emails
			// eslint-disable-next-line no-useless-escape
			regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return matchWithRegEx(field, regEx, 'Invalid Email', error);
		case 6:
			// check if only numbers
			// eslint-disable-next-line no-useless-escape
			regEx = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
			return matchWithRegEx(
				field,
				regEx,
				'Please enter a valid phone number',
				error,
			);
		default:
			return false;
	}
};

const matchWithRegEx = (field, regEx, message, error) => {
	if (field.value.toLowerCase().match(regEx)) {
		makeValid(field, error);
		return true;
	} else {
		makeInvalid(field, message, error);
		return false;
	}
};

const notOnlyLetters = field => {
	return !/^[a-zA-Z ]+$/.test(field.value);
};

//INDIVIDUAL VAL
export const validateNameStrict = (field, error, focusSetter) => {
	focusable = !!focusSetter;
	if (!validateEmpty(field, error)) {
		return false;
	} else if (notOnlyLetters(field)) {
		makeInvalid(field, 'Should only contain letters', error);
		return false;
	} else {
		makeValid(field, error);
		return true;
	}
};
export const validateName = (field, error, focusSetter) => {
	focusable = !!focusSetter;
	if (!validateEmpty(field, error)) {
		return false;
	} else {
		makeValid(field, error);
		return true;
	}
};

export const validateEMail = (field, error, focusSetter) => {
	focusable = !!focusSetter;
	if (!containsCharacters(field, 5, error)) {
	} else {
		makeValid(field, error);
		return true;
	}
};

export const validateContact = (field, error, focusSetter) => {
	focusable = !!focusSetter;
	const contact = field.value;
	if (isNaN(contact)) {
		makeInvalid(field, 'Please enter numbers only', error);
		return false;
	} else if (contact.length < 10 || contact.length > 15) {
		makeInvalid(field, 'Should be between 10 - 15', error);
		return false;
	} else {
		makeValid(field, error);
		return true;
	}
};

export const validatePassword = (field, error, focusSetter) => {
	focusable = !!focusSetter;
	return validateEmpty(field, error);
};

export const validateSpecialPassword = (field, error, focusSetter) => {
	focusable = !!focusSetter;
	if (!validateEmpty(field, error)) {
		return false;
	} else if (field.value.length < 6) {
		makeInvalid(field, 'Password should be at least 6 characters', error);
		return false;
	} else {
		makeValid(field, error);
		proofPassword = field.value;
		return true;
	}
};

export const validateConfirmPassword = (field, error, focusSetter) => {
	focusable = !!focusSetter;
	if (!validateEmpty(field, error)) {
		return false;
	} else if (field.value !== proofPassword) {
		makeInvalid(field, 'Passwords do not match', error);
		return false;
	} else {
		makeValid(field, error);
		return true;
	}
};

export const validateSelection = (field, error, focusSetter) => {
	focusable = !!focusSetter;
	if (field.value === 'select') {
		makeInvalid(field, 'Please make a selection', error);
		return false;
	} else {
		makeValid(field, error);
		return true;
	}
};

export const validateNumber = (field, error, focusSetter) => {
	focusable = !!focusSetter;
	if (!validateEmpty(field, error)) {
		return false;
	} else if (field.value < 1) {
		makeInvalid(field, 'Should be greater than 1', error);
		return false;
	} else {
		makeValid(field, error);
		return true;
	}
};

export const validateDescription = (field, error, focusSetter) => {
	focusable = !!focusSetter;
	if (!validateEmpty(field, error)) {
		return false;
	} else if (field.value.trim().length < 20) {
		makeInvalid(field, 'Should be more descriptive', error);
		return false;
	} else {
		makeValid(field, error);
		return true;
	}
};

export const checkImagesLength = (len, limit, error) => {
	if (len < limit) {
		const message = 'Images should not be less than ' + limit;
		errorObject.error = error;
		errorObject.message = message;
		return false;
	} else {
		errorObject.error = error;
		errorObject.message = '';
		return true;
	}
};
