import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
	Close,
	HeaderFive,
	ModalBody,
	ModalCard,
	ModalHead,
	Paragraph,
	Sub,
	UnderlineLink,
} from '../../globals/appGlobal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Input from '../input/input';
import Button from '../button/button';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { gsap } from 'gsap/all';
import { createStructuredSelector } from 'reselect';
import {
	selectError,
	selectLoadingGoogleUser,
	selectLoadingUser,
	selectSuccessMessage,
} from '../../redux/user/user.selectors';
import {
	clearUserStatus,
	emailSignInStart,
	googleSignInStart,
} from '../../redux/user/user.actions';
import { useForm } from '../../custom-hooks/useForm';
import LoginLoader from '../loaders/login-loader/loginLoader';
import Error from '../error/error';
import { useValidationFunctions } from '../../custom-hooks/validationFunctions';
import firebase from 'firebase/app';
import { analytics } from '../../firebase/firebase.config';
import {
	closeLogModal,
	openLogModal,
	showSignUpOnly,
} from '../../redux/modal/modal.actions';
import {
	selectShowModal,
	selectShowSignIn,
	selectShowSignUp,
} from '../../redux/modal/modal.selectors';

const SignIn = ({
	showModal,
	showSignUp,
	showSignIn,
	closeLogModal,
	showSignUpOnly,
	loadingUser,
	loadingGoogleUser,
	error,
	successMessage,
	emailSignInStart,
	googleSignInStart,
	clearUserStatus,
}) => {
	const signInRef = useRef(null);
	const { validateSignIn, setError, val, setVal } = useValidationFunctions();

	useEffect(() => {
		showModal &&
			gsap.to(signInRef.current, {
				duration: 0.3,
				delay: 0.2,
				y: '50%',
			});
	}, [showModal]);
	//
	useEffect(() => {
		showSignUp &&
			gsap.to(signInRef.current, {
				duration: 0,
				y: '-100vh',
			});
		clearUserStatus();
	}, [showSignUp, clearUserStatus]);

	useEffect(() => {
		showSignIn &&
			gsap.to(signInRef.current, {
				duration: 0.5,
				delay: 0.2,
				y: '50%',
			});
	}, [showSignIn]);

	useEffect(() => {
		if (successMessage) {
			closeLogModal();
		}
	}, [successMessage, closeLogModal]);

	const [values, setValues] = useState({
		email: '',
		password: '',
	});
	const { form, handleChange } = useForm(values, setValues);

	function userSignIn() {
		const params1 = {
			user_email: form.email,
		};
		// Log event
		analytics.logEvent(firebase.analytics.EventName.LOGIN, params1);
		// [END analytics_ecommerce_view_item_list]
	}

	const handleSubmit = e => {
		e.preventDefault();
		const isValid = validateSignIn(e);
		setError(val, setVal);
		isValid && emailSignInStart(form.email, form.password);
		isValid && userSignIn();
	};

	const continueWithGoogle = () => {
		googleSignInStart();
	};

	const switchCards = () => {
		showSignUpOnly();
	};

	return (
		<ModalCard ref={signInRef}>
			<ModalHead>
				<HeaderFive>Sign In</HeaderFive>
				<Close onClick={() => closeLogModal()}>
					<FontAwesomeIcon icon={faTimes} />
				</Close>
			</ModalHead>
			<ModalBody>
				<UnderlineLink
					to='/password-reset'
					onClick={() => closeLogModal()}
				>
					<Sub>Forgot password?</Sub>
				</UnderlineLink>
				<Paragraph>
					Don’t have an account?{' '}
					<span className='make-green' onClick={switchCards}>
						Sign up
					</span>
				</Paragraph>
				<form onSubmit={handleSubmit}>
					<Input
						type='email'
						label='Email'
						name='email'
						id='signInMail'
						onChange={handleChange}
						val={val}
						setVal={setVal}
						validate={true}
					/>
					<Input
						type='password'
						label='Password'
						name='password'
						id='signInPass'
						onChange={handleChange}
						val={val}
						setVal={setVal}
						validate={true}
						special='raw'
					/>
					{error && <Error message={error} />}
					{loadingUser ? (
						<LoginLoader />
					) : (
						<Button size='fluid'>Sign in</Button>
					)}
				</form>
				{loadingGoogleUser ? (
					<LoginLoader />
				) : (
					<Button
						type='secondary'
						clickEvent={continueWithGoogle}
						size='fluid'
						icon={<FontAwesomeIcon icon={faGoogle} />}
					>
						Continue with Google
					</Button>
				)}
			</ModalBody>
		</ModalCard>
	);
};

const mapStateToProps = createStructuredSelector({
	error: selectError,
	loadingUser: selectLoadingUser,
	loadingGoogleUser: selectLoadingGoogleUser,
	successMessage: selectSuccessMessage,
	showModal: selectShowModal,
	showSignUp: selectShowSignUp,
	showSignIn: selectShowSignIn,
});

const mapDispatchToProps = dispatch => ({
	googleSignInStart: () => dispatch(googleSignInStart()),
	emailSignInStart: (email, password) =>
		dispatch(emailSignInStart({ email, password })),
	openLogModal: () => dispatch(openLogModal()),
	closeLogModal: () => dispatch(closeLogModal()),
	showSignUpOnly: () => dispatch(showSignUpOnly()),
	clearUserStatus: () => dispatch(clearUserStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
