import BookingDataTypes from './booking-data.types';

const INITIAL_STATE = {
	booked: [],
	guestBookedProperties: [],
	isFetchingGuestBooked: true,
	fetchGuestError: '',
	homeOwnerBookedProperties: [],
};

const bookingDataReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case BookingDataTypes.SET_BOOKED_PROPERTIES:
			return {
				...state,
				booked: action.payload,
				isFetchingGuestBooked: false,
			};

		case BookingDataTypes.FETCH_GUEST_BOOKED_START:
			return {
				...state,
				isFetchingGuestBooked: true,
				fetchGuestError: '',
			};

		case BookingDataTypes.FETCH_GUEST_BOOKED_SUCCESS:
			return {
				...state,
				isFetchingGuestBooked: false,
				fetchGuestError: '',
				guestBookedProperties: action.payload,
			};

		case BookingDataTypes.FETCH_HOME_OWNERS_BOOKED_SUCCESS:
			return {
				...state,
				homeOwnerBookedProperties: action.payload,
			};

		case BookingDataTypes.FETCH_GUEST_BOOKED_FAILURE:
			return {
				...state,
				isFetchingGuestBooked: false,
				fetchGuestError: action.payload,
			};

		default:
			return state;
	}
};

export default bookingDataReducer;
