import PropertyUploadTypes from './property-upload.types';

const INITIAL_STATE = {
	isUploading: false,
	isEditing: false,
	isFetching: false,
	isDeleting: false,
	currentProperty: null,
	editingCurrentProperty: null,
	error: '',
	successMessage: '',
	finalSuccessMessage: '',
	editingSuccessMessage: '',
	banks: [],
	banksSuccessMessage: '',
	banksErrorMessage: '',
	isFetchingBanks: false,
};

const propertyUploadReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case PropertyUploadTypes.CREATE_PROPERTY_START:
			return {
				...state,
				error: '',
				isUploading: true,
				successMessage: '',
				finalSuccessMessage: '',
				currentProperty: action.payload.propertyDetails,
			};

		case PropertyUploadTypes.CREATE_PROPERTY_SUCCESS:
			return {
				...state,
				error: '',
				isUploading: false,
				successMessage: 'uploaded successfully',
				currentProperty: action.payload,
			};

		case PropertyUploadTypes.SET_UPLOAD_SUCCESS_MESSAGE:
			return {
				...state,
				successMessage: action.payload,
			};

		case PropertyUploadTypes.SET_FINAL_UPLOAD_SUCCESS_MESSAGE:
			return {
				...state,
				finalSuccessMessage: action.payload,
			};

		case PropertyUploadTypes.CLEAR_UPLOAD_SUCCESS_MESSAGE:
			return {
				...state,
				successMessage: '',
				finalSuccessMessage: '',
				editingSuccessMessage: '',
			};

		case PropertyUploadTypes.PROPERTY_EDIT_START:
			return {
				...state,
				isEditing: true,
				error: '',
				editingCurrentProperty: action.payload,
				editingSuccessMessage: '',
			};

		case PropertyUploadTypes.PROPERTY_EDIT_SUCCESS:
			return {
				...state,
				isEditing: false,
				editingCurrentProperty: action.payload,
				editingSuccessMessage: 'Changes made',
				error: '',
			};

		case PropertyUploadTypes.GET_PROPERTY_START:
			return {
				...state,
				isFetching: true,
				error: '',
				editingCurrentProperty: null,
			};

		case PropertyUploadTypes.CLEAR_CURRENT_PROPERTY:
			return {
				...state,
				editingCurrentProperty: null,
				isFetching: true,
				currentProperty: null,
			};

		case PropertyUploadTypes.GET_PROPERTY_SUCCESS:
			return {
				...state,
				isFetching: false,
				error: '',
				editingCurrentProperty: action.payload,
			};

		case PropertyUploadTypes.REMOVE_PROPERTY_START:
			return {
				...state,
				isDeleting: true,
				error: '',
			};

		case PropertyUploadTypes.REMOVE_PROPERTY_SUCCESS:
			return {
				...state,
				isDeleting: false,
				error: '',
			};

		case PropertyUploadTypes.CREATE_PROPERTY_FAILURE:
		case PropertyUploadTypes.PROPERTY_EDIT_FAILURE:
		case PropertyUploadTypes.GET_PROPERTY_FAILURE:
		case PropertyUploadTypes.REMOVE_PROPERTY_FAILURE:
			return {
				...state,
				isUploading: false,
				isEditing: false,
				isFetching: false,
				error: action.payload,
				isDeleting: false,
				successMessage: '',
				finalSuccessMessage: '',
				editingSuccessMessage: '',
			};

		case PropertyUploadTypes.GET_BANKS_START:
			return {
				...state,
				banksSuccessMessage: '',
				banksErrorMessage: '',
				isFetchingBanks: true,
			};

		case PropertyUploadTypes.GET_BANKS_SUCCESS:
			return {
				...state,
				banks: action.payload,
				banksSuccessMessage: 'Fetched banks',
				banksErrorMessage: '',
				isFetchingBanks: false,
			};

		case PropertyUploadTypes.GET_BANKS_FAILURE:
			return {
				...state,
				banksErrorMessage: action.payload,
				banksSuccessMessage: '',
				isFetchingBanks: false,
			};

		default:
			return state;
	}
};

export default propertyUploadReducer;
