import { all, call, put, takeLatest } from 'redux-saga/effects';
import SearchActionTypes from './search.types';
import { firestore } from '../../firebase/firebase.config';
import {
	fetchSearchResultsFailure,
	fetchSearchResultsSuccess,
	setCurrentRegion,
	setCurrentTown,
} from './search.actions';
import { convertPropertySnapshotToMap } from '../../firebase/firebase.helpers';
import _ from 'lodash';
import { filterParameters, isBookable } from './search.utils';

//  Fetch results
function* fetchResultsStart({ payload }) {
	try {
		const propertiesRef = firestore.collection('properties');
		let query;

		if (parseFloat(payload.filters.price_max) >= 50000) {
			query = yield propertiesRef
				.where('region', '==', payload.filters.region)
				.where('price', '>=', parseFloat(payload.filters.price_min))
				.where('isComplete', '==', true);
		} else {
			query = yield propertiesRef
				.where('region', '==', payload.filters.region)
				.where('price', '>=', parseFloat(payload.filters.price_min))
				.where('price', '<=', parseFloat(payload.filters.price_max))
				.where('isComplete', '==', true);
		}

		const snapshot = yield query.get();
		const transformedData = yield call(
			convertPropertySnapshotToMap,
			snapshot,
		);
		const filteredData = _.filter(
			transformedData,
			_.conforms(filterParameters(payload)),
		).sort((a, b) => b.timestamp - a.timestamp);

		let reArrangedData;

		if (payload.filters.town === '') {
			reArrangedData = filteredData;
		} else {
			const townsProperties = filteredData.filter(property =>
				property.town
					.toLowerCase()
					.includes(
						payload.filters.town
							.replace(/, Ghana/gi, '')
							.toLowerCase(),
					),
			);
			const otherTownsProperties = filteredData.filter(
				property =>
					!property.town
						.toLowerCase()
						.includes(
							payload.filters.town
								.replace(/, Ghana/gi, '')
								.toLowerCase(),
						),
			);
			reArrangedData = [...townsProperties, ...otherTownsProperties];
		}

		//	Temporary search change
		//	If property class is Edanra Stay only
		if (
			payload.filters.property_class[0].isChecked &&
			!payload.filters.property_class[1].isChecked
		) {
			reArrangedData = reArrangedData.filter(property => {
				return (
					property.property_class === 'Edanra Stay' ||
					property.charge_rate === 'Per night'
				);
			});
		} else if (
			!payload.filters.property_class[0].isChecked &&
			payload.filters.property_class[1].isChecked
		) {
			// If property class is Regular
			reArrangedData = reArrangedData.filter(property => {
				return property.property_class !== 'Edanra Stay';
			});
		}

		//	If Charge rate === 'Per night' and Edanra Stay is unchecked
		if (
			!payload.filters.property_class[0].isChecked &&
			payload.filters.charge_rate[0].isChecked &&
			!payload.filters.charge_rate[1].isChecked
		) {
			reArrangedData = reArrangedData.filter(property => {
				return (
					property.property_class === 'Edanra Stay' ||
					property.charge_rate === 'Per night'
				);
			});
		}

		const checkIn = new Date(payload.filters.check_in);
		const checkOut = new Date(payload.filters.check_out);

		if (checkIn || checkOut) {
			reArrangedData = reArrangedData.filter(property =>
				isBookable(property, checkIn, checkOut),
			);
		}

		yield put(
			setCurrentTown(payload.filters.town.replace(/, Ghana/gi, '')),
		);
		yield put(setCurrentRegion(payload.filters.region));
		yield put(fetchSearchResultsSuccess(reArrangedData));
	} catch (error) {
		yield put(fetchSearchResultsFailure(error.message));
	}
}

function* onFetchSearchResultsStart() {
	yield takeLatest(
		SearchActionTypes.FETCH_SEARCH_RESULTS_START,
		fetchResultsStart,
	);
}

export function* searchSagas() {
	yield all([call(onFetchSearchResultsStart)]);
}
