import SavedAdTypes from './save-ad.types';

export const setSavedAds = uid => ({
	type: SavedAdTypes.SET_SAVED_ADS,
	payload: uid,
});

export const saveAd = ({ saved, uid }) => ({
	type: SavedAdTypes.SAVE_AD_START,
	payload: { saved, uid },
});

export const saveAdSuccess = () => ({
	type: SavedAdTypes.SAVE_AD_SUCCESS,
});

export const saveAdFailure = error => ({
	type: SavedAdTypes.SAVE_AD_FAILURE,
	payload: error,
});

export const fetchSavedStart = savedAds => ({
	type: SavedAdTypes.FETCH_SAVED_START,
	payload: savedAds,
});

export const fetchSavedSuccess = savedProperties => ({
	type: SavedAdTypes.FETCH_SAVED_SUCCESS,
	payload: savedProperties,
});

export const fetchSavedFailure = error => ({
	type: SavedAdTypes.FETCH_SAVED_FAILURE,
	payload: error,
});
