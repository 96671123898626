import styled, { css } from 'styled-components';
import { palette } from '../../utils';

export const ButtonWrapper = styled.button`
	padding: 15px;
	outline: none;
	cursor: pointer;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-content: center;
	transition: 300ms ease-in-out;
	background-color: ${palette.green};
	color: white;
	border: 1px solid ${palette.green};
	svg,
	img {
		width: 15px !important;
		height: 15px !important;
		font-size: 1rem;
	}
	p {
		font-weight: 500;
		text-decoration: none !important;
	}
	&:hover {
		background-color: white;
		color: ${palette.green};
		svg {
			* {
				fill: ${palette.green};
			}
		}
	}
	svg {
		margin: 1px 10px 0 0;
	}
	${props =>
		props.size === 'fluid' &&
		css`
			width: 100%;
			padding: 20px;
		`};
	${props =>
		props.type === 'secondary' &&
		css`
			background-color: white;
			color: black;
			border: 1px solid black;
			svg {
				* {
					fill: black;
				}
			}
			&:hover {
				background-color: black;
				color: white;
				svg {
					* {
						fill: white;
					}
				}
			}
		`}
	${props =>
		props.type === 'tertiary' &&
		css`
			background-color: white;
			color: black;
			border: 1px solid white;
			-webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
			box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
			svg {
				* {
					fill: black;
				}
			}
			&:hover {
				background-color: black;
				border: 1px solid black;
				color: white;
				svg {
					* {
						fill: white;
					}
				}
			}
		`}
  ${({ iconRight }) =>
		iconRight &&
		css`
			svg {
				margin: 1px 0 0 10px;
			}
		`}
  ${props =>
		props.type === 'pill' &&
		css`
			background-color: transparent;
			color: black;
			border: 1px solid black;
			border-radius: 500px;
			svg {
				* {
					fill: black;
				}
			}
			&:hover {
				background-color: black;
				color: white;
				svg {
					* {
						fill: white;
					}
				}
			}
		`}
  ${props =>
		props.circular &&
		css`
			border-radius: 50%;
			padding: 10px;
			svg {
				margin: 0;
				width: 20px;
				height: 20px;
			}
		`}
  &:disabled, &[disabled] {
		background-color: ${palette.lightGrey};
		border-color: ${palette.lightGrey};
		cursor: not-allowed;
		&:hover {
			background-color: ${palette.lightGrey};
			border-color: ${palette.lightGrey};
			color: white;
			svg {
				* {
					fill: white;
				}
			}
		}
	}
`;
