import PropertyDetailsTypes from './property-details.types';

export const fetchPropertyDetailsStart = uid => ({
	type: PropertyDetailsTypes.FETCH_PROPERTY_DETAILS_START,
	payload: uid,
});

export const fetchPropertyDetailsSuccess = propertyDetails => ({
	type: PropertyDetailsTypes.FETCH_PROPERTY_DETAILS_SUCCESS,
	payload: propertyDetails,
});

export const fetchPropertyDetailsFailure = error => ({
	type: PropertyDetailsTypes.FETCH_PROPERTY_DETAILS_FAILURE,
	payload: error,
});

export const clearCurrentProperty = () => ({
	type: PropertyDetailsTypes.CLEAR_CURRENT_PROPERTY,
});

export const clearSimilarProperties = () => ({
	type: PropertyDetailsTypes.CLEAR_SIMILAR_PROPERTIES,
});

export const fetchSimilarPropertiesStart = ({ region, price }) => ({
	type: PropertyDetailsTypes.FETCH_SIMILAR_PROPERTIES_START,
	payload: { region, price },
});

export const fetchSimilarPropertiesSuccess = properties => ({
	type: PropertyDetailsTypes.FETCH_SIMILAR_PROPERTIES_SUCCESS,
	payload: properties,
});

export const fetchSimilarPropertiesFailure = error => ({
	type: PropertyDetailsTypes.FETCH_SIMILAR_PROPERTIES_FAILURE,
	payload: error,
});

export const getUserData = payload => ({
	type: PropertyDetailsTypes.GET_USER_DATA,
	payload,
});

export const setProperty = payload => ({
	type: PropertyDetailsTypes.SET_PROPERTY,
	payload,
});
