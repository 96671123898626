import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	FormDivider,
	SearchForm,
	SearchFormContainer,
	SearchFormLabel,
	SearchFormWrapper,
	SearchInput,
	SearchSelect,
	ValidationContainer,
} from './searchBarStyles';
import { Flex } from '../../globals/appGlobal';
import Button from '../button/button';
import { Search } from '../../assets/svg/allSvg';
import { v4 } from 'uuid';
import { propertyType, regions } from '../../utils/static-data';
import { gsap } from 'gsap/all';
import { useValidationFunctions } from '../../custom-hooks/validationFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { createStructuredSelector } from 'reselect';
import { fetchSearchResultsStart } from '../../redux/search/search.actions';
import {
	selectCurrentRegion,
	selectSearchResults,
	selectSearchSuccess,
} from '../../redux/search/search.selectors';
import firebase from 'firebase/app';
import { analytics } from '../../firebase/firebase.config';
import { selectPriceRange } from '../../redux/range/range.selectors';
import { urlStringer } from '../../utils/urlStringer';
import { useAutocomplete } from '../../custom-hooks/useAutocomplete';

const SearchBar = ({ show, history, setShow, priceRange }) => {
	const searchBarRef = useRef(null);

	useEffect(() => {
		if (show) {
			searchBarRef.current.classList.add('appear');
			gsap.to(searchBarRef.current, {
				delay: 0.1,
				y: 10,
				scale: 1,
				opacity: 1,
				duration: 0.3,
			});
		} else {
			gsap.to(searchBarRef.current, {
				y: -40,
				opacity: 0,
				scale: 0.4,
				duration: 0.3,
			});
			setTimeout(() => {
				searchBarRef.current &&
					searchBarRef.current.classList.remove('appear');
			}, 300);
		}
	}, [show]);

	const {
		validateSearch,
		setError,
		// blurFunc,
		changeFunc,
	} = useValidationFunctions();

	const [val, setVal] = useState({
		searchTownError: '',
		searchRegionError: '',
	});

	const [searchDetails, setSearchDetails] = useState({
		town: '',
		region: 'select',
		property_type: '',
		no_of_bedrooms: '',
		property_class: [
			{ id: v4(), value: 'Edanra Stay', isChecked: false },
			{ id: v4(), value: 'Regular Ad', isChecked: false },
		],
		price_min: priceRange.min,
		price_max: priceRange.max,
		charge_rate: [
			{ id: v4(), value: 'Per night', isChecked: false },
			{ id: v4(), value: 'Per month', isChecked: false },
		],
		check_in: null,
		check_out: null,
	});

	function searchPropertyItem() {
		// [START analytics_search_property_item]
		// Prepare property params
		const params1 = {
			search_term: searchDetails.town,
			property__item_type: searchDetails.property_type,
			property_item_region: searchDetails.region,
			property_item_class: searchDetails.property_class,
		};

		// Log event
		analytics.logEvent(firebase.analytics.EventName.SEARCH, params1);
		// [END analytics_search_item]
	}

	const startSearch = () => {
		history.push(urlStringer(searchDetails));
		searchPropertyItem();
	};

	const handleSubmit = e => {
		e.preventDefault();
		const isValid = validateSearch(e);
		setError(val, setVal);
		isValid && startSearch();
		isValid && setShow(false);
	};

	const handleChange = (e, code, domElement, lat, lng) => {
		const element = domElement ? domElement : e.target;
		const { name, value } = element;
		setSearchDetails({ ...searchDetails, [name]: value });
		changeFunc(e, val, setVal, undefined, domElement);
	};

	// const handleBlur = e => {
	// 	blurFunc(e, val, setVal);
	// };

	//clear validation after load
	useEffect(() => {
		setVal({ ...val, searchTownError: '', searchRegionError: '' });
		// eslint-disable-next-line
	}, [show]);

	useAutocomplete(handleChange, ['#searchTown'], [['(cities)']], [false]);

	return (
		<SearchFormWrapper ref={searchBarRef}>
			<SearchFormContainer>
				<SearchForm onSubmit={handleSubmit}>
					<Flex>
						<Flex vertical>
							<SearchFormLabel
								htmlFor='searchTown'
								className='town-label'
							>
								Town
							</SearchFormLabel>
							<SearchInput
								type='text'
								placeholder='Location'
								id='searchTown'
								name='town'
								pad
								onChange={handleChange}
							/>
						</Flex>
						<FormDivider />
						<Flex vertical>
							<SearchFormLabel
								htmlFor='searchRegion'
								className='select-label'
							>
								Region
							</SearchFormLabel>
							<SearchSelect
								type='select'
								id='searchRegion'
								name='region'
								value={searchDetails.region}
								onChange={handleChange}
							>
								<option value='select' className='grey-out'>
									Select region
								</option>
								{regions.map(option => {
									return (
										<option key={v4()} value={option}>
											{option} Region
										</option>
									);
								})}
							</SearchSelect>
						</Flex>
						<FormDivider />
						<Flex vertical>
							<SearchFormLabel
								htmlFor='searchPropertyType'
								className='select-label'
							>
								Property type
							</SearchFormLabel>
							<SearchSelect
								type='select'
								id='searchPropertyType'
								name='property_type'
								onChange={handleChange}
								value={searchDetails.property_type}
							>
								<option value='select' className='grey-out'>
									Select property type
								</option>
								{propertyType.map(option => {
									return (
										<option key={v4()} value={option}>
											{option}
										</option>
									);
								})}
							</SearchSelect>
						</Flex>
						<FormDivider />
						<Flex vertical>
							<SearchFormLabel htmlFor='searchBedroom'>
								Bedrooms
							</SearchFormLabel>
							<SearchInput
								onChange={handleChange}
								type='number'
								placeholder='Bedroom no.'
								id='searchBedroom'
								name='no_of_bedrooms'
							/>
						</Flex>
						<Button
							icon={<Search />}
							aria='search icon'
							circular={true}
						/>
					</Flex>
				</SearchForm>
				{val.searchRegionError !== '' && (
					<ValidationContainer second>
						<FontAwesomeIcon icon={faExclamationCircle} />
						{val.searchRegionError}
					</ValidationContainer>
				)}
			</SearchFormContainer>
		</SearchFormWrapper>
	);
};

const mapStateToProps = createStructuredSelector({
	currentRegion: selectCurrentRegion,
	results: selectSearchResults,
	success: selectSearchSuccess,
	priceRange: selectPriceRange,
});

const mapDispatchToProps = dispatch => ({
	search: (filters, currentResults, currentRegion) =>
		dispatch(
			fetchSearchResultsStart({
				filters,
				currentResults,
				currentRegion,
			}),
		),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SearchBar),
);
