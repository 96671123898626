import React from 'react';
import { FooterWrapper, Social } from './desktopFooterStyles';
import { Flex, HorizontalLine, Paragraph } from '../../../globals/appGlobal';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import {
	faFacebookF,
	faInstagram,
	faTwitter,
} from '@fortawesome/free-brands-svg-icons';

const DesktopFooter = () => {
	const date = new Date();
	const year = date.getFullYear();

	return (
		<FooterWrapper>
			<HorizontalLine />
			<Flex spaceBetween>
				<Paragraph>©{year} edanra</Paragraph>
				<Link to='/terms'>
					<Paragraph>Privacy & terms</Paragraph>
				</Link>
				<Social>
					<a href='tel:0553220405' target='_blank' rel='noreferrer'>
						<FontAwesomeIcon icon={faPhoneAlt} />
					</a>
					<a
						href='mailto:yourfriends@edanra.com'
						target='_blank'
						rel='noreferrer'
					>
						<FontAwesomeIcon icon={faEnvelope} />
					</a>
					<a
						href='https://web.facebook.com/lifeatedanra'
						target='_blank'
						rel='noreferrer'
					>
						<FontAwesomeIcon icon={faFacebookF} />
					</a>
					<a
						href='https://twitter.com/lifeatedanra'
						target='_blank'
						rel='noreferrer'
					>
						<FontAwesomeIcon icon={faTwitter} />
					</a>
					<a
						href='https://www.instagram.com/lifeatedanra'
						target='_blank'
						rel='noreferrer'
					>
						<FontAwesomeIcon icon={faInstagram} />
					</a>
				</Social>
			</Flex>
		</FooterWrapper>
	);
};

export default DesktopFooter;
