import ReviewTypes from './review.types';

export const postReviewStart = ({ propertyID, review, checkoutMailData }) => ({
	type: ReviewTypes.POST_REVIEW_START,
	payload: { propertyID, review, checkoutMailData },
});

export const postReviewSuccess = successMessage => ({
	type: ReviewTypes.POST_REVIEW_SUCCESS,
	payload: successMessage,
});

export const postReviewFailure = error => ({
	type: ReviewTypes.POST_REVIEW_FAILURE,
	payload: error,
});

export const requestPaymentStart = payload => ({
	type: ReviewTypes.REQUEST_PAYMENT_START,
	payload,
});

export const requestPaymentSuccess = successMessage => ({
	type: ReviewTypes.REQUEST_PAYMENT_SUCCESS,
	payload: successMessage,
});

export const requestPaymentFailure = error => ({
	type: ReviewTypes.REQUEST_PAYMENT_FAILURE,
	payload: error,
});

export const clearBookingStart = payload => ({
	type: ReviewTypes.CLEAR_BOOKING_START,
	payload,
});

export const clearBookingSuccess = successMessage => ({
	type: ReviewTypes.CLEAR_BOOKING_SUCCESS,
	payload: successMessage,
});

export const clearBookingFailure = error => ({
	type: ReviewTypes.CLEAR_BOOKING_FAILURE,
	payload: error,
});

export const clearReviewSuccess = () => ({
	type: ReviewTypes.CLEAR_REVIEW_SUCCESS,
});

export const clearReviewError = () => ({
	type: ReviewTypes.CLEAR_REVIEW_ERROR,
});

export const removeClearBookingStatus = () => ({
	type: ReviewTypes.REMOVE_CLEAR_BOOKING_STATUS,
});

export const clearTransferStatus = () => ({
	type: ReviewTypes.CLEAR_TRANSFER_STATUS,
});
