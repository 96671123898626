import styled from 'styled-components';
import { Flex } from '../../globals/appGlobal';
import { palette } from '../../utils';

export const ProfileImage = styled(Flex)`
	margin-bottom: 20px;
	img {
		width: 70px;
		height: 70px;
		border-radius: 50%;
		cursor: copy;
	}
	img:hover ~ p {
		color: ${palette.grey};
	}
	button {
		margin: 0 0 0 20px;
	}
	label {
		display: flex;
		align-items: center;
		p {
			margin: 0 0 0 20px;
			cursor: copy;
		}
		p:hover {
			color: ${palette.grey};
		}
	}
`;

export const ProfileInput = styled.input`
	position: absolute;
	left: 0;
	top: 0;
	display: none;
	z-index: 1;
	cursor: copy;
	padding: 0;
	margin: 0;
	min-width: auto;
`;

export const Profile = styled.div`
	display: inline-block;
`;
