import React, { useEffect, useRef } from 'react';
import { BallLoaderWrapper, Ball } from './loginLoaderStyles';
import { gsap, Power3 } from 'gsap/all';

const LoginLoader = ({ left, inline, right }) => {
	const ballWrapper = useRef(null);
	useEffect(() => {
		gsap.to(ballWrapper.current.children, {
			duration: 0.6,
			y: -10,
			yoyo: true,
			repeat: -1,
			repeatDelay: -0.2,
			stagger: 0.2,
			ease: Power3.easeInOut,
		});
	}, []);

	return (
		<BallLoaderWrapper
			ref={ballWrapper}
			center
			left={left}
			right={right}
			inline={inline}
		>
			<Ball />
			<Ball />
			<Ball />
			<Ball />
		</BallLoaderWrapper>
	);
};

export default LoginLoader;
