import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap/all';
import { Flex, Paragraph } from '../../globals/appGlobal';
import { MessageBoxContainer } from './messageBoxStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const MessageBox = ({ icon, message, type, closeIcon, closeFunc, width }) => {
	const messageBoxRef = useRef(null);
	useEffect(() => {
		gsap.to(messageBoxRef.current, {
			duration: 0.8,
			y: -100,
			ease: 'back.out(4)',
		});
	}, []);

	return (
		<MessageBoxContainer type={type} ref={messageBoxRef} width={width}>
			{closeIcon && (
				<Flex
					id='message-box-close'
					style={{ justifyContent: 'flex-end' }}
				>
					<FontAwesomeIcon icon={faTimes} onClick={closeFunc} />
				</Flex>
			)}
			<Flex center id='message-box-message'>
				{icon}
				<Paragraph>{message}</Paragraph>
			</Flex>
		</MessageBoxContainer>
	);
};

export default MessageBox;
