import styled, { css } from 'styled-components';
import { Container, Flex } from '../../globals/appGlobal';
import { palette, typeScale } from '../../utils';

const InputStyle = css`
	height: 100%;
	padding: ${props => (props.pad ? '30px 30px 20px' : '30px 10px 20px')};
	outline: none;
	border-radius: 28px;
	border: none !important;
	width: 15vw;
	max-width: 200px;
`;

export const SearchFormWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 98;
	height: fit-content;
	display: none;

	button {
		margin-left: 20px;
	}

	.DateInput,
	.DateInput_1 {
		width: 100% !important;
		height: auto !important;
		padding: 0 !important;
	}

	.DateInput {
		${InputStyle};
		margin-bottom: 0;
		display: flex;
		align-items: center;
		width: 100%;
		position: relative;
	}

	.DateInput input {
		padding: 30px 10px 20px;
	}

	.DateRangePickerInput {
		grid-template-columns: 1fr 1px 1fr !important;
		grid-column-gap: 0 !important;
	}

	.DateRangePickerInput_arrow {
		display: inline-block !important;
		background-color: ${palette.lightGrey};
		height: 50px;
		margin: auto 0;
		width: 1px;

		svg {
			display: none !important;
		}
	}

	.DateRangePickerInput > div:first-of-type::before,
	.DateRangePickerInput > div:last-of-type::before {
		content: 'Check in';
		font-size: ${typeScale.small};
		color: black;
		font-weight: 600;
		position: absolute;
		top: 13px;
		left: 10px;
		z-index: 2;
	}

	.DateRangePickerInput > div:last-of-type::before {
		content: 'Check out';
	}

	.DateRangePickerInput > div:last-of-type {
		margin-left: 20px;
	}
`;

export const SearchFormContainer = styled(Container)`
	position: relative;
	border-radius: 28px;
	border: 1px solid black;
	height: auto;
	margin-top: 95px;
	background-color: white;
	width: fit-content;
	padding: 0 20px 0 0;
	-webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
`;

export const SearchFormLabel = styled.label`
	font-size: ${typeScale.small};
	color: black;
	font-weight: 600;
	position: absolute;
	top: 13px;
	left: 10px;
`;

export const SearchForm = styled.form`
	> div {
		position: relative;
	}

	.select-label {
		top: 9px !important;
	}

	.town-label {
		left: 30px;
	}
`;

export const SearchInput = styled.input`
	${InputStyle};
`;

export const SearchSelect = styled.select`
	${InputStyle};
	background: white;
	-moz-appearance: none; /* Firefox */
	-webkit-appearance: none; /* Safari and Chrome */
	appearance: none;
	cursor: pointer;
`;

export const FormDivider = styled.div`
	background-color: ${palette.lightGrey};
	height: 50px;
	margin: auto 20px;
	width: 1px;
`;

export const ValidationContainer = styled(Flex)`
	width: 212px;
	padding: 30px;
	background: white;
	margin-top: 5px;
	position: absolute;
	border: 1px solid red;
	top: 100%;
	color: red;
	left: ${({ second }) => (second ? '217px' : '0')};
	border-radius: 28px 0 28px 28px;

	svg {
		margin-right: 10px;
	}
`;
