import { call, all, put, takeEvery } from 'redux-saga/effects';
import ExploreActionTypes from './explore.types';
import { firestore } from '../../firebase/firebase.config';
import {
	fetchRegionPropertiesFailure,
	fetchRegionPropertiesSuccess,
} from './explore.actions';
import { convertPropertySnapshotToMap } from '../../firebase/firebase.helpers';

//  Fetch Region Properties
function* fetchRegionProperties({ payload }) {
	try {
		const propertiesRef = firestore.collection('properties');
		let query;
		if (payload.id === 'edanra') {
			query = yield propertiesRef
				.where('region', '==', payload.region)
				.where('property_class', '==', 'Edanra Stay')
				.where('isComplete', '==', true)
				.orderBy('timestamp', 'desc')
				.limit(4);
		} else {
			query = yield propertiesRef
				.where('region', '==', payload.region)
				.where('isComplete', '==', true)
				.orderBy('timestamp', 'desc')
				.limit(4);
		}
		const snapshot = yield query.get();
		const transformedData = yield call(
			convertPropertySnapshotToMap,
			snapshot,
		);
		yield put(
			fetchRegionPropertiesSuccess({
				[payload.region]: transformedData,
			}),
		);
	} catch (error) {
		yield put(fetchRegionPropertiesFailure(error.message));
	}
}

function* onFetchRegionPropertiesStart() {
	yield takeEvery(
		ExploreActionTypes.FETCH_REGION_PROPERTIES_START,
		fetchRegionProperties,
	);
}

export function* exploreSagas() {
	yield all([call(onFetchRegionPropertiesStart)]);
}
