import styled from 'styled-components';
import { Grid } from '../../globals/appGlobal';

export const ErrorPageWrapper = styled(Grid)`
	padding-top: 50px;
	min-height: 100vh;

	p {
		max-width: 600px;
	}

	button {
		margin-right: 20px;
	}

	@media only screen and (min-width: 992px) {
		padding-top: 90px;
		height: calc(100vh - 115px);
		img {
			width: 100%;
		}
	}
`;
