import React from 'react';
import { LoaderContainer } from './pageLoaderStyles';
import { EdanraLogo } from '../../../assets/svg/allSvg';

const PageLoader = () => {
	return (
		<LoaderContainer center>
			<div>
				<EdanraLogo />
			</div>
		</LoaderContainer>
	);
};

export default PageLoader;
