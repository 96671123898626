export const palette = {
	green: '#218284',
	yellow: '#c7a159',
	red: '#ff0000',
	edanraNss: '#F7505B',
	darkGrey: '#505050',
	grey: '#595959',
	ash: '#9D9D9D',
	lightGrey: '#d6d6d6',
	lightestGrey: 'rgba(222, 222, 222, .3)',
};
