const PaymentActionTypes = {
	UPDATE_LAST_DATE_PAID_START: 'UPDATE_LAST_DATE_PAID_START',
	UPDATE_LAST_DATE_PAID_SUCCESS: 'UPDATE_LAST_DATE_PAID_SUCCESS',
	UPDATE_LAST_DATE_PAID_FAILURE: 'UPDATE_LAST_DATE_PAID_FAILURE',
	FETCH_CHECKOUT_PROPERTY_START: 'FETCH_CHECKOUT_PROPERTY_START',
	FETCH_CHECKOUT_PROPERTY_SUCCESS: 'FETCH_CHECKOUT_PROPERTY_SUCCESS',
	FETCH_CHECKOUT_PROPERTY_FAILURE: 'FETCH_CHECKOUT_PROPERTY_FAILURE',
};

export default PaymentActionTypes;
