import styled, { css } from 'styled-components';
import { palette } from '../../../utils';
import { Flex } from '../../../globals/appGlobal';

export const BallLoaderWrapper = styled(Flex)`
	margin: 5px auto 20px;

	> div:nth-child(2n) {
		background-color: black;
	}

	${({ left }) =>
		left &&
		css`
			justify-content: flex-start;
		`};
	${({ right }) =>
		right &&
		css`
			justify-content: flex-end;
		`}
	${({ inline }) =>
		inline &&
		css`
			width: max-content;
			margin: 0 20px 0 0;
		`}
`;

export const Ball = styled.div`
	width: 12px;
	height: 12px;
	margin: 0 5px;
	background-color: ${palette.green};
	border-radius: 50%;
`;
