import HostProfileTypes from './host-profile.types';

const INITIAL_STATE = {
	hostProfileDetails: {},
	isFetchingHostProfileDetails: true,
	hostProfileError: '',
	hostProfileProperties: [],
	isFetchingHostProfileProperties: true,
	hostPropertiesError: '',
};

const hostProfileReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case HostProfileTypes.FETCH_HOST_PROFILE_DETAILS_START:
			return {
				...state,
				hostProfileDetails: {},
				isFetchingHostProfileDetails: true,
				hostProfileError: '',
			};

		case HostProfileTypes.FETCH_HOST_PROFILE_DETAILS_SUCCESS:
			return {
				...state,
				hostProfileDetails: action.payload,
				isFetchingHostProfileDetails: false,
				hostProfileError: '',
			};

		case HostProfileTypes.FETCH_HOST_PROFILE_DETAILS_FAILURE:
			return {
				...state,
				isFetchingHostProfileDetails: false,
				hostProfileError: action.payload,
			};

		case HostProfileTypes.FETCH_ALL_USER_PROPERTIES_START:
			return {
				...state,
				hostProfileProperties: [],
				isFetchingHostProfileProperties: true,
				hostPropertiesError: '',
			};

		case HostProfileTypes.FETCH_ALL_USER_PROPERTIES_SUCCESS:
			return {
				...state,
				hostProfileProperties: action.payload,
				isFetchingHostProfileProperties: false,
				hostPropertiesError: '',
			};

		case HostProfileTypes.FETCH_ALL_USER_PROPERTIES_FAILURE:
			return {
				...state,
				isFetchingHostProfileProperties: false,
				hostPropertiesError: action.payload,
			};

		default:
			return state;
	}
};

export default hostProfileReducer;
