import { all, put, call, takeLatest } from 'redux-saga/effects';
import ReviewTypes from './review.types';
import { auth, firestore, functions } from '../../firebase/firebase.config';
import {
	clearBookingFailure,
	clearBookingStart,
	clearBookingSuccess,
	postReviewFailure,
	postReviewSuccess,
	requestPaymentFailure,
	requestPaymentSuccess,
} from './review.actions';
import { setGuestBooked } from '../booking-data/booking-data.actions';

//  Post review
function* postReview({ payload }) {
	try {
		const propertyRef = yield firestore
			.collection('properties')
			.doc(payload.propertyID);
		const propertySnapshot = yield propertyRef.get();
		const propertyData = yield propertySnapshot.data();

		const oldReviews = propertyData.reviews || [];
		yield propertyRef.update({
			reviews: [
				...oldReviews,
				{
					username: auth.currentUser.displayName,
					photoURL: auth.currentUser.photoURL,
					reviewDate: new Date().toString(),
					review: payload.review,
				},
			],
		});

		//	Update hasReviewed
		const guestRef = yield firestore
			.collection('users')
			.doc(auth.currentUser.uid);
		const guestSnapshot = yield guestRef.get();
		const guestData = yield guestSnapshot.data();
		const currentlyOccupying = guestData.currentlyOccupying;

		yield guestRef.update({
			currentlyOccupying: {
				hasReviewed: true,
				...currentlyOccupying,
			},
		});

		yield put(postReviewSuccess('Review Submitted'));
		yield put(
			clearBookingStart({
				propertyID: payload.propertyID,
				target: 'guest',
			}),
		);
		const postReviewMail = functions.httpsCallable('checkoutMail');
		yield postReviewMail(payload.checkoutMailData);
	} catch (error) {
		yield put(postReviewFailure(error.message));
	}
}

function* onPostReviewStart() {
	yield takeLatest(ReviewTypes.POST_REVIEW_START, postReview);
}

//	Initialize transfer
function* requestPayment({ payload }) {
	const {
		propertyID,
		userID,
		guest,
		amount,
		account_number,
		bank_code,
	} = payload;
	const initializeTransfer = functions.httpsCallable('initializeTransfer');

	try {
		const propertyRef = firestore.collection('properties').doc(propertyID);
		const propertyDoc = yield propertyRef.get();
		const propertyData = yield propertyDoc.data();

		const otherBookings = propertyData.bookings.filter(
			booking =>
				booking.user_id !== userID &&
				booking.hasCheckedIn === false &&
				booking.hasCheckedOut !== true,
		);
		const [bookingToUpdate] = propertyData.bookings.filter(
			booking =>
				booking.userID === userID &&
				booking.hasCheckedIn === true &&
				booking.hasCheckedOut === true,
		);

		if (!bookingToUpdate.hasPaidOwner) {
			yield initializeTransfer({
				guest,
				amount,
				account_number,
				bank_code,
			});

			yield propertyRef.update({
				bookings: [
					...otherBookings,
					{
						...bookingToUpdate,
						hasPaidOwner: true,
					},
				],
			});

			yield put(
				requestPaymentSuccess(
					'The transfer has successfully been made',
				),
			);
			yield put(clearBookingStart({ propertyID, target: 'owner' }));
		} else {
			yield put(
				requestPaymentSuccess('The transfer has already been made'),
			);
		}
	} catch (error) {
		yield put(requestPaymentFailure(error.message));
	}
}

function* onRequestPaymentStart() {
	yield takeLatest(ReviewTypes.REQUEST_PAYMENT_START, requestPayment);
}

//  Clear booking after payment
function* clearBooking({ payload }) {
	try {
		if (payload.target === 'owner') {
			//  Update property
			const propertyRef = yield firestore
				.collection('properties')
				.doc(payload.propertyID);
			const propertySnapshot = yield propertyRef.get();
			const propertyData = yield propertySnapshot.data();
			const { currentTenant, bookings } = propertyData;

			const otherPropertyBookings = bookings.filter(
				booking => booking.userID !== currentTenant,
			);
			const allUserBookings = bookings.filter(
				booking => booking.userID === currentTenant,
			);
			let remainingPropertyBookings = [];
			let remainingUserBookings;

			if (allUserBookings.length === 1) {
				remainingPropertyBookings = otherPropertyBookings;
			} else if (allUserBookings.length > 1) {
				remainingUserBookings = allUserBookings.filter(
					booking => booking.hasReviewed === false,
				);
				remainingPropertyBookings = [
					...otherPropertyBookings,
					...remainingUserBookings,
				];
			}

			yield propertyRef.update({
				bookings: remainingPropertyBookings,
				currentTenant: null,
			});

			// Update home owner data

			const ownerRef = firestore
				.collection('users')
				.doc(propertyData.user_id);
			const ownerSnapshot = yield ownerRef.get();
			const ownerData = yield ownerSnapshot.data();
			const hostedTenants = ownerData.hostedTenants || 0;

			yield ownerRef.update({
				hostedTenants: hostedTenants + 1,
			});
		} else if (payload.target === 'guest') {
			// Update guest data
			const userRef = yield firestore
				.collection('users')
				.doc(auth.currentUser.uid);
			const userSnapshot = yield userRef.get();
			const userData = yield userSnapshot.data();
			const oldBooked = userData.booked;

			const userBooking = oldBooked.filter(
				booking => booking === payload.propertyID,
			);
			const otherBookings = oldBooked.filter(
				booking => booking !== payload.propertyID,
			);
			let remainingBookings = [];

			if (userBooking.length === 1) {
				remainingBookings = otherBookings;
			} else if (userBooking.length > 1) {
				userBooking.pop();
				remainingBookings = [...otherBookings, ...userBooking];
			}

			yield userRef.update({
				currentlyOccupying: null,
				booked: remainingBookings,
			});

			yield put(setGuestBooked(remainingBookings));
		}

		yield put(clearBookingSuccess('Booking cleared'));
	} catch (error) {
		yield put(clearBookingFailure(error.message));
	}
}

function* onClearBookingStart() {
	yield takeLatest(ReviewTypes.CLEAR_BOOKING_START, clearBooking);
}

export function* reviewSagas() {
	yield all([
		call(onPostReviewStart),
		call(onClearBookingStart),
		call(onRequestPaymentStart),
	]);
}
