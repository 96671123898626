const ManageAdTypes = {
	FETCH_USER_PROPERTIES_START: 'FETCH_USER_PROPERTIES_START',
	FETCH_USER_PROPERTIES_SUCCESS: 'FETCH_USER_PROPERTIES_SUCCESS',
	FETCH_USER_PROPERTIES_FAILURE: 'FETCH_USER_PROPERTIES_FAILURE',
	FETCH_INCOMPLETE_PROPERTIES: 'FETCH_INCOMPLETE_PROPERTIES',
	EDIT_PROPERTY_START: 'EDIT_PROPERTY_START',
	EDIT_PROPERTY_SUCCESS: 'EDIT_PROPERTY_SUCCESS',
	EDIT_PROPERTY_FAILURE: 'EDIT_PROPERTY_FAILURE',
	DELETE_PROPERTY_START: 'DELETE_PROPERTY_START',
	DELETE_PROPERTY_SUCCESS: 'DELETE_PROPERTY_SUCCESS',
	DELETE_PROPERTY_FAILURE: 'DELETE_PROPERTY_FAILURE',
};

export default ManageAdTypes;
