import React, { useEffect, useRef, useState } from 'react';
import { DokaModal, toURL } from '../../react-doka';
import {
	Close,
	HeaderFive,
	ModalBody,
	ModalCard,
	ModalHead,
	Paragraph,
	Small,
} from '../../globals/appGlobal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import Input from '../input/input';
import Button from '../button/button';
import { Profile, ProfileImage, ProfileInput } from './signUpStyles';
import { gsap } from 'gsap/all';
import { useForm } from '../../custom-hooks/useForm';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
	selectError,
	selectLoadingGoogleUser,
	selectLoadingUser,
	selectSignUpSuccessMessage,
} from '../../redux/user/user.selectors';
import {
	clearUserStatus,
	googleSignInStart,
	signUpStart,
} from '../../redux/user/user.actions';
import { faPenAlt } from '@fortawesome/free-solid-svg-icons';
import LoginLoader from '../loaders/login-loader/loginLoader';
import Error from '../error/error';
import { useValidationFunctions } from '../../custom-hooks/validationFunctions';
import firebase from 'firebase/app';
import { analytics } from '../../firebase/firebase.config';
import { closeLogModal, showSignInOnly } from '../../redux/modal/modal.actions';
import {
	selectShowModal,
	selectShowSignIn,
	selectShowSignUp,
} from '../../redux/modal/modal.selectors';

const SignUp = ({
	showSignUp,
	showSignIn,
	closeLogModal,
	showSignInOnly,
	error,
	loadingUser,
	loadingGoogleUser,
	signUpStart,
	googleSignInStart,
	signUpSuccess,
	clearUserStatus,
}) => {
	const signUpRef = useRef(null);
	const [signInMethod, setSignInMethod] = useState('email');

	useEffect(() => {
		showSignUp &&
			gsap.to(signUpRef.current, {
				duration: 0.5,
				delay: 0.2,
				y: '-40%',
			});
	}, [showSignUp]);

	useEffect(() => {
		showSignIn &&
			gsap.to(signUpRef.current, {
				duration: 0,
				y: '150vh',
			});
		clearUserStatus();
	}, [showSignIn, clearUserStatus]);

	useEffect(() => {
		if (signUpSuccess) {
			closeLogModal();
		}
	}, [signUpSuccess, closeLogModal]);

	const [userProfile, setUserProfile] = useState({
		mask: (root, setInnerHTML) => {
			setInnerHTML(
				root,
				`
                    <mask id="my-mask">
                        <rect x="0" y="0" width="100%" height="100%" fill="white"/>
                        <circle cx="50%" cy="50%" r="50%" fill="black"/>
                    </mask>
                    <rect fill="rgba(255,255,255,.3125)" x="0" y="0" width="100%" height="100%" mask="url(#my-mask)"/>
                    <circle cx="50%" cy="50%" r="50%" fill="transparent" stroke-width="1" stroke="#fff"/>
                `,
			);
		},
		profile: {
			enabled: false,
			image: require('../../assets/images/avatar2.png').default,
			src: require('../../assets/images/avatar2.png').default,
			srcPrev: null,
			crop: {
				aspectRatio: 1,
				center: {
					x: 0.5378,
					y: 0.355,
				},
			},
		},
	});

	const [values, setValues] = useState({
		displayName: '',
		email: '',
		password: '',
		confirmPassword: '',
		profile_img: userProfile.profile.image,
	});
	const { form, handleChange, setImg, checkConfirmPassword } = useForm(
		values,
		setValues,
	);

	const continueWithGoogle = () => {
		googleSignInStart();
		setSignInMethod('google');
	};

	const handleToggleProfileEditor = () => {
		setUserProfile({
			...userProfile,
			profile: {
				...userProfile.profile,
				enabled: !userProfile.enabled,
			},
		});
	};

	const handleFileChangeProfile = e => {
		if (!e.target.files.length) return;

		setUserProfile({
			...userProfile,
			profile: {
				...userProfile.profile,
				srcPrev: userProfile.profile.src,
				src: e.target.files[0],
				enabled: true,
			},
		});
	};

	const [fileSelect, setFileSelect] = useState(false);

	const handleDokaConfirmProfile = output => {
		setUserProfile({
			...userProfile,
			profile: {
				...userProfile.profile,
				srcPrev: null,
				enabled: false,
				image: output.file,
				crop: output.data.crop,
			},
		});

		output.file || output.file === ''
			? setFileSelect(true)
			: setFileSelect(false);
		setImg(output);
	};
	const handleDokaCancelProfile = () => {
		setUserProfile({
			...userProfile,
			profile: {
				...userProfile.profile,
				src: userProfile.profile.srcPrev || userProfile.profile.src,
				srcPrev: null,
				enabled: false,
			},
		});
	};

	const switchCards = () => {
		showSignInOnly();
	};

	const { profile, mask } = userProfile;

	const { val, setVal, setError, validateSignUp } = useValidationFunctions();

	useEffect(() => {
		checkConfirmPassword(
			'password',
			'confirmPassword',
			val,
			setVal,
			'signUpCPass',
		);
		// eslint-disable-next-line
	}, [values]);

	function userSignUp() {
		const params1 = {
			user_email: form.displayName,
			method: signInMethod,
		};
		// Log event
		analytics.logEvent(firebase.analytics.EventName.SIGN_UP, params1);
		// [END analytics_sign_up]
	}

	const handleSubmit = e => {
		e.preventDefault();
		const isValid = validateSignUp(e);
		setError(val, setVal);
		isValid && signUpStart(form);
		isValid && userSignUp();
	};

	return (
		<ModalCard ref={signUpRef}>
			<ModalHead>
				<HeaderFive>Sign up</HeaderFive>
				<Close onClick={() => closeLogModal()}>
					<FontAwesomeIcon icon={faTimes} />
				</Close>
			</ModalHead>
			<ModalBody>
				<Paragraph>
					Already have an account?{' '}
					<span className='make-green' onClick={switchCards}>
						Login
					</span>
				</Paragraph>
				<form onSubmit={handleSubmit}>
					<Input
						type='text'
						label='Fullname'
						name='displayName'
						id='signUpName'
						onChange={handleChange}
						val={val}
						setVal={setVal}
						validate={true}
					/>
					<Input
						type='email'
						label='Email'
						name='email'
						id='signUpMail'
						onChange={handleChange}
						val={val}
						setVal={setVal}
						validate={true}
					/>
					<ProfileImage>
						<Profile>
							<label>
								{!profile.enabled && (
									<ProfileInput
										type='file'
										accept='image/*'
										title='Profile image'
										onChange={handleFileChangeProfile}
									/>
								)}
								<img src={toURL(profile.image)} alt='profile' />
								{!fileSelect && (
									<Paragraph>Upload profile image</Paragraph>
								)}
							</label>
						</Profile>

						{!profile.enabled && fileSelect && (
							<Button
								icon={<FontAwesomeIcon icon={faPenAlt} />}
								clickEvent={handleToggleProfileEditor}
							>
								Edit
							</Button>
						)}

						{profile.enabled && (
							<DokaModal
								utils={['crop', 'filter', 'color']}
								src={profile.src}
								cropAspectRatio={1}
								crop={profile.crop}
								cropMask={mask}
								outputData={true}
								onConfirm={handleDokaConfirmProfile}
								onCancel={handleDokaCancelProfile}
							/>
						)}
					</ProfileImage>
					<Input
						type='password'
						label='Password'
						name='password'
						id='signUpPass'
						onChange={handleChange}
						val={val}
						setVal={setVal}
						validate={true}
						special='special-password'
					/>
					<Input
						type='password'
						label='Confirm password'
						name='confirmPassword'
						id='signUpCPass'
						onChange={handleChange}
						val={val}
						setVal={setVal}
						validate={true}
						special='confirm-password'
					/>
					{error && <Error message={error} />}
					<Small style={{ marginBottom: 22 }}>
						By selecting agree and continue below, I agree to
						edanra's{' '}
						<a href='/terms' target='_blank' className='make-green'>
							privacy & terms
						</a>
						.
					</Small>
					{loadingUser ? (
						<LoginLoader />
					) : (
						<Button size='fluid'>Agree and continue</Button>
					)}
				</form>
				{loadingGoogleUser ? (
					<LoginLoader />
				) : (
					<Button
						type='secondary'
						clickEvent={continueWithGoogle}
						size='fluid'
						icon={<FontAwesomeIcon icon={faGoogle} />}
					>
						Continue with Google
					</Button>
				)}
			</ModalBody>
		</ModalCard>
	);
};

const mapStateToProps = createStructuredSelector({
	error: selectError,
	loadingUser: selectLoadingUser,
	loadingGoogleUser: selectLoadingGoogleUser,
	signUpSuccess: selectSignUpSuccessMessage,
	showModal: selectShowModal,
	showSignUp: selectShowSignUp,
	showSignIn: selectShowSignIn,
});

const mapDispatchToProps = dispatch => ({
	signUpStart: userCredentials => dispatch(signUpStart(userCredentials)),
	googleSignInStart: () => dispatch(googleSignInStart()),
	closeLogModal: () => dispatch(closeLogModal()),
	showSignInOnly: () => dispatch(showSignInOnly()),
	clearUserStatus: () => dispatch(clearUserStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
