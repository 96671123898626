import { all, call, put, takeLatest } from 'redux-saga/effects';
import PropertyUploadTypes from './property-upload.types';
import { firestore, timestamp } from '../../firebase/firebase.config';
import {
	createPropertySuccess,
	createPropertyFailure,
	getPropertyFailure,
	getPropertySuccess,
	propertyEditFailure,
	propertyEditSuccess,
	removePropertyFailure,
	removePropertySuccess,
	getBanksFailure,
	getBanksSuccess,
	setFinalUploadSuccessMessage,
} from './property-upload.actions';
import { randomPhoneGenerator } from '../../utils/randomPhoneGenerator';

// Create and edit firestore document when uploading for the first time
function* createProperty({ payload: { propertyDetails, isComplete } }) {
	//  form not complete
	if (!isComplete) {
		//  if details don't have uid, create new doc
		if (!propertyDetails.uid) {
			try {
				const response = yield firestore
					.collection('properties')
					.add(propertyDetails);
				const uid = response.id;
				yield firestore.collection('properties').doc(uid).update({
					uid,
					timestamp,
				});
				yield put(
					createPropertySuccess({
						...propertyDetails,
						uid,
					}),
				);
			} catch (error) {
				yield put(createPropertyFailure(error.message));
			}
		} else {
			try {
				yield firestore
					.collection('properties')
					.doc(propertyDetails.uid)
					.update({
						...propertyDetails,
					});
				yield put(
					createPropertySuccess({
						...propertyDetails,
					}),
				);
			} catch (error) {
				yield put(createPropertyFailure(error.message));
			}
		}
		//  if form is complete
	} else if (isComplete) {
		try {
			//  update
			const mainImageUrl = propertyDetails.property_images[0];

			const lastCollection = firestore.collection('lastRef');
			const lastDoc = lastCollection.doc('hv9RKMH53RxF7M83wF9V');
			const lastRef = yield lastDoc.get();

			yield firestore
				.collection('properties')
				.doc(propertyDetails.uid)
				.update({
					...propertyDetails,
					main_image_url: mainImageUrl,
					price: parseFloat(propertyDetails.price),
					isComplete: true,
					ref: (lastRef.data().last + 1).toString().padStart(6, '0'),
					agent_number: randomPhoneGenerator(),
					subscription_type:
						propertyDetails.property_class === 'Regular Ad'
							? 'free_four'
							: 'edanra_stay',
				});

			yield lastDoc.update({
				last: lastRef.data().last + 1,
			});

			yield put(createPropertySuccess(null));
			yield put(setFinalUploadSuccessMessage('Property uploaded'));
		} catch (error) {
			yield put(createPropertyFailure(error.message));
		}
	}
}

function* onCreateStart() {
	yield takeLatest(PropertyUploadTypes.CREATE_PROPERTY_START, createProperty);
}

//  Fetch ad by Id
function* getProperty({ payload }) {
	try {
		const propertyRef = yield firestore
			.collection('properties')
			.doc(payload.id);
		const doc = yield propertyRef.get();
		yield put(getPropertySuccess(doc.data()));
	} catch (error) {
		yield put(getPropertyFailure(error.message));
	}
}

function* onGetPropertyStart() {
	yield takeLatest(PropertyUploadTypes.GET_PROPERTY_START, getProperty);
}

//  Edit existing ad
function* editProperty({ payload }) {
	const allDetails = { ...payload };

	const { uid, property_images, subscription_type, property_class } = payload;

	const subType = () => {
		if (subscription_type) {
			return subscription_type;
		} else if (!subscription_type && property_class === 'Regular Ad') {
			return 'free_four';
		} else if (!subscription_type && property_class === 'Edanra Stay') {
			return 'edanra_stay';
		}
	};

	try {
		yield firestore
			.collection('properties')
			.doc(uid)
			.update({
				...allDetails,
				main_image_url: property_images[0],
				isComplete: true,
				price: parseFloat(allDetails.price),
				subscription_type: subType(),
				last_modified: new Date().toString(),
			});
		yield put(
			propertyEditSuccess({
				...payload,
			}),
		);
	} catch (error) {
		yield put(propertyEditFailure(error.message));
	}
}

function* onEditPropertyStart() {
	yield takeLatest(PropertyUploadTypes.PROPERTY_EDIT_START, editProperty);
}

//  Delete ad
function* removeProperty({ payload }) {
	try {
		yield firestore.collection('properties').doc(payload).delete();
		yield put(removePropertySuccess('Deleted property'));
	} catch (error) {
		yield put(removePropertyFailure(error.message));
	}
}

function* onRemovePropertyStart() {
	yield takeLatest(PropertyUploadTypes.REMOVE_PROPERTY_START, removeProperty);
}

//	Fetch Banks
function* getBanks() {
	try {
		const data = yield fetch(
			'https://us-central1-edanra-v2.cloudfunctions.net/bankData',
		);
		const { data: bankData } = yield data.json();
		yield put(getBanksSuccess(bankData));
	} catch (error) {
		yield put(getBanksFailure(error.message));
	}
}

function* onFetchBanksStart() {
	yield takeLatest(PropertyUploadTypes.GET_BANKS_START, getBanks);
}

export function* propertyUploadSagas() {
	yield all([
		call(onCreateStart),
		call(onGetPropertyStart),
		call(onEditPropertyStart),
		call(onRemovePropertyStart),
		call(onFetchBanksStart),
	]);
}
