import React, { useEffect } from 'react';
import '../../index.css';
import DesktopHeader from './desktop-header/desktopHeader';
import PhoneHeader from './phone-header/phoneHeader';
import LogModal from '../log-modal/logModal';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { selectShowModal } from '../../redux/modal/modal.selectors';
import {
	clearSignUpSuccess,
	setUserErrorFalse,
} from '../../redux/user/user.actions';
import MessageBox from '../message-box/messageBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';
import { selectSignUpSuccessMessage } from '../../redux/user/user.selectors';
import { usePhoneNavScroll } from '../../custom-hooks/usePhoneNavScroll';

const Header = ({
	showModal,
	setUserErrorFalse,
	signUpSuccess,
	clearSignUpSuccess,
}) => {
	//remove error messages in modal on close
	useEffect(() => {
		!showModal && setUserErrorFalse();
	}, [showModal, setUserErrorFalse]);

	//make html stop scrolling behind modal
	useEffect(() => {
		const html = document.querySelector('html');
		showModal
			? html.classList.add('halt-scroll')
			: html.classList.remove('halt-scroll');
	}, [showModal]);

	const closeMessage = () => {
		clearSignUpSuccess();
	};

	useEffect(() => {
		signUpSuccess &&
			setTimeout(() => {
				clearSignUpSuccess();
			}, 10000);
	}, [signUpSuccess, clearSignUpSuccess]);

	const { scrollEvent } = usePhoneNavScroll();

	useEffect(() => {
		return () => {
			window.removeEventListener('scroll', scrollEvent);
		};
	});

	const x = window.matchMedia('(max-width: 992px)');

	if (x.matches) {
		window.addEventListener('scroll', scrollEvent);

		return (
			<>
				<PhoneHeader />
				{showModal && <LogModal />}
				{signUpSuccess && (
					<MessageBox
						icon={<FontAwesomeIcon icon={faEnvelopeOpen} />}
						message='A mail has been sent to you to verify your account.'
						closeIcon={true}
						closeFunc={closeMessage}
					/>
				)}
			</>
		);
	}
	return (
		<>
			<DesktopHeader />
			{showModal && <LogModal />}
			{signUpSuccess && (
				<MessageBox
					icon={<FontAwesomeIcon icon={faEnvelopeOpen} />}
					message='A mail has been sent to you to verify your account.'
					closeIcon={true}
					closeFunc={closeMessage}
				/>
			)}
		</>
	);
};

const mapStateToProps = createStructuredSelector({
	showModal: selectShowModal,
	signUpSuccess: selectSignUpSuccessMessage,
});

const mapDispatchToProps = dispatch => ({
	setUserErrorFalse: () => dispatch(setUserErrorFalse()),
	clearSignUpSuccess: () => dispatch(clearSignUpSuccess()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
