import PropertyDataActionTypes from './properties-data.types';

export const fetchPropertiesStart = ({ region, limit }) => ({
	type: PropertyDataActionTypes.FETCH_PROPERTIES_START,
	payload: { region, limit },
});

export const fetchPropertiesSuccess = properties => ({
	type: PropertyDataActionTypes.FETCH_PROPERTIES_SUCCESS,
	payload: properties,
});

export const fetchPropertiesFailure = error => ({
	type: PropertyDataActionTypes.FETCH_PROPERTIES_FAILURE,
	payload: error,
});

export const refetchPropertiesStart = ({ region, limit, latestDoc }) => ({
	type: PropertyDataActionTypes.REFETCH_PROPERTIES_START,
	payload: { region, limit, latestDoc },
});

export const refetchPropertiesSuccess = properties => ({
	type: PropertyDataActionTypes.REFETCH_PROPERTIES_SUCCESS,
	payload: properties,
});

export const refetchPropertiesFailure = error => ({
	type: PropertyDataActionTypes.REFETCH_PROPERTIES_FAILURE,
	payload: error,
});

export const setLatestDoc = doc => ({
	type: PropertyDataActionTypes.SET_LATEST_DOC,
	payload: doc,
});

export const setDataEmpty = () => ({
	type: PropertyDataActionTypes.SET_DATA_EMPTY,
});

export const clearProperties = () => ({
	type: PropertyDataActionTypes.CLEAR_PROPERTIES,
});
