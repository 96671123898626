const setRegionPath = reg => {
	return '/' + reg.replace(' ', '-').toLowerCase() + '/';
};

const setDescPath = (nob, type) => {
	if (!(nob === undefined || nob === '')) {
		if (!(type === undefined || type === '')) {
			return nob + '-bedroom-' + type.replace(' ', '-').toLowerCase();
		} else {
			return nob + '-bedroom';
		}
	} else {
		if (!(type === undefined || type === '')) {
			return type.replace(' ', '-').toLowerCase();
		} else {
			return '';
		}
	}
};

const joinQS = arr => {
	return arr.join('&');
};

const setMaxPriceQS = maxprice => {
	return 'max-price=' + maxprice;
};

const setMinPriceQS = minprice => {
	return 'min-price=' + minprice;
};

const setClassQS = _class => {
	if (_class[0].isChecked) {
		if (_class[1].isChecked) {
			return 'class=stay-and-regular';
		} else {
			return 'class=stay';
		}
	} else {
		if (_class[1].isChecked) {
			return 'class=regular';
		} else {
			return '';
		}
	}
};

const setPageQS = _page => {
	return typeof _page !== 'undefined' ? 'page=' + _page : '';
};

const setCheck_inQS = _check_in => {
	return typeof _check_in !== 'undefined'
		? _check_in !== null
			? 'check_in=' + _check_in
			: null
		: null;
};

const setCheck_outQS = _check_out => {
	return typeof _check_out !== 'undefined'
		? _check_out !== null
			? 'check_out=' + _check_out
			: null
		: null;
};

const setRateQS = _rate => {
	if (_rate[0].isChecked) {
		if (_rate[1].isChecked) {
			return 'rate=all';
		} else {
			return 'rate=nightly';
		}
	} else {
		if (_rate[1].isChecked) {
			return 'rate=monthly';
		} else {
			return '';
		}
	}
};

const setQueryQS = q => {
	if (q === undefined) {
	} else {
		return 'q=' + q;
	}
};

export const createPath = Filters => {
	return (
		setRegionPath(Filters.region) +
		setDescPath(Filters.no_of_bedrooms, Filters.property_type)
	);
};

export const createQS = (Filters, price_range = false) => {
	var _class = setClassQS(Filters.property_class);
	var _rate = setRateQS(Filters.charge_rate);
	var _page = setPageQS(Filters.page);
	var _check_in = setCheck_inQS(Filters.check_in);
	var _check_out = setCheck_outQS(Filters.check_out);
	var arr = [setQueryQS(Filters.town)];
	if (price_range) {
		arr.push(setMaxPriceQS(Filters.price_max));
		arr.push(setMinPriceQS(Filters.price_min));
	}
	if (!(_class === '')) {
		arr.push(_class);
	}
	if (!(_rate === '')) {
		arr.push(_rate);
	}
	if (!(_check_in === null || _check_out === null)) {
		arr.push(_check_in);
		arr.push(_check_out);
	}
	if (!(_page === '')) {
		arr.push(_page);
	}
	return joinQS(arr);
};

export const urlStringer = (Filters, price_range = false) => {
	return createPath(Filters) + '?' + createQS(Filters, price_range);
};
