export const usePhoneNavScroll = () => {
	let previousScrollPosition = window.pageYOffset;

	const scrollEvent = () => {
		const header = document.querySelector('#phone-header');
		if (header) {
			let currentScrollPosition = window.pageYOffset;
			if (
				previousScrollPosition > currentScrollPosition ||
				currentScrollPosition < '100'
			) {
				header.style.transform = 'translateY(0)';
			} else {
				header.style.transform = 'translateY(400px)';
			}
			previousScrollPosition = currentScrollPosition;
		}
	};
	return { scrollEvent };
};
