import BookingTypes from './book.types';

const INITIAL_STATE = {
	isBooking: false,
	error: '',
	successMessage: '',
	isCheckingIn: false,
	checkInError: '',
	checkInSuccessMessage: '',
	isCheckingOut: false,
	checkOutError: '',
	checkOutSuccessMessage: '',
	isCancelling: false,
	cancelBookingSuccess: '',
	cancelBookingError: '',
};

const bookingReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case BookingTypes.USER_BOOKING_START:
			return {
				...state,
				isBooking: true,
				error: '',
				successMessage: '',
			};

		case BookingTypes.USER_BOOKING_SUCCESS:
			return {
				...state,
				isBooking: false,
				successMessage: action.payload,
				error: '',
			};

		case BookingTypes.USER_BOOKING_FAILURE:
			return {
				...state,
				isBooking: false,
				successMessage: '',
				error: action.payload,
			};

		case BookingTypes.CHECK_IN_START:
			return {
				...state,
				isCheckingIn: true,
				checkInError: '',
				checkInSuccessMessage: '',
			};

		case BookingTypes.CHECK_IN_SUCCESS:
			return {
				...state,
				isCheckingIn: false,
				checkInError: '',
				checkInSuccessMessage: action.payload,
			};

		case BookingTypes.CHECK_IN_FAILURE:
			return {
				...state,
				isCheckingIn: false,
				checkInError: action.payload,
				checkInSuccessMessage: '',
			};

		case BookingTypes.CHECK_OUT_START:
			return {
				...state,
				isCheckingOut: true,
				checkOutError: '',
				checkOutSuccessMessage: '',
			};

		case BookingTypes.CHECK_OUT_SUCCESS:
			return {
				...state,
				isCheckingOut: false,
				checkOutError: '',
				checkOutSuccessMessage: action.payload,
			};

		case BookingTypes.CHECK_OUT_FAILURE:
			return {
				...state,
				isCheckingOut: false,
				checkOutError: action.payload,
				checkOutSuccessMessage: '',
			};

		case BookingTypes.CLEAR_BOOKING_SUCCESS_MESSAGE:
			return {
				...state,
				successMessage: '',
			};

		case BookingTypes.CANCEL_BOOKING_START:
			return {
				...state,
				isCancelling: true,
				cancelBookingSuccess: '',
				cancelBookingError: '',
			};

		case BookingTypes.CLEAR_CANCEL_BOOKING_SUCCESS:
			return {
				...state,
				cancelBookingSuccess: '',
				cancelBookingError: '',
			};

		case BookingTypes.CANCEL_BOOKING_SUCCESS:
			return {
				...state,
				isCancelling: false,
				cancelBookingSuccess: action.payload,
				cancelBookingError: '',
			};

		case BookingTypes.CANCEL_BOOKING_FAILURE:
			return {
				...state,
				isCancelling: false,
				cancelBookingSuccess: '',
				cancelBookingError: action.payload,
			};

		case BookingTypes.CLEAR_BOOKING_STATUS:
			return {
				...state,
				isCheckingIn: false,
				checkInError: '',
				checkInSuccessMessage: '',
				isCheckingOut: false,
				checkOutError: '',
				checkOutSuccessMessage: '',
			};

		default:
			return state;
	}
};

export default bookingReducer;
