import ReviewTypes from './review.types';

const INITIAL_STATE = {
	isReviewing: false,
	successMessage: '',
	error: '',
	isTransferring: false,
	transferSuccessMessage: '',
	transferError: '',
	isClearingBooking: false,
	clearBookingSuccessMessage: '',
	clearBookingError: '',
};

const reviewReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ReviewTypes.POST_REVIEW_START:
			return {
				...state,
				isReviewing: true,
				successMessage: '',
				error: '',
			};

		case ReviewTypes.POST_REVIEW_SUCCESS:
			return {
				...state,
				error: '',
				successMessage: action.payload,
				isReviewing: false,
			};

		case ReviewTypes.POST_REVIEW_FAILURE:
			return {
				...state,
				error: action.payload,
				isReviewing: false,
			};

		case ReviewTypes.REQUEST_PAYMENT_START:
			return {
				...state,
				isTransferring: true,
				transferSuccessMessage: '',
				transferError: '',
			};

		case ReviewTypes.REQUEST_PAYMENT_SUCCESS:
			return {
				...state,
				isTransferring: false,
				transferSuccessMessage: action.payload,
				transferError: '',
			};

		case ReviewTypes.REQUEST_PAYMENT_FAILURE:
			return {
				...state,
				isTransferring: false,
				transferSuccessMessage: '',
				transferError: action.payload,
			};

		case ReviewTypes.CLEAR_BOOKING_START:
			return {
				...state,
				isClearingBooking: true,
				clearBookingError: '',
				clearBookingSuccessMessage: '',
			};

		case ReviewTypes.CLEAR_BOOKING_SUCCESS:
			return {
				...state,
				isClearingBooking: false,
				clearBookingSuccessMessage: action.payload,
				clearBookingError: '',
				transferSuccessMessage: '',
				transferError: '',
			};

		case ReviewTypes.CLEAR_BOOKING_FAILURE:
			return {
				...state,
				isClearingBooking: false,
				clearBookingSuccessMessage: '',
				clearBookingError: action.payload,
			};

		case ReviewTypes.CLEAR_REVIEW_SUCCESS:
			return {
				...state,
				successMessage: '',
			};

		case ReviewTypes.CLEAR_REVIEW_ERROR:
			return {
				...state,
				error: '',
			};

		case ReviewTypes.REMOVE_CLEAR_BOOKING_STATUS:
			return {
				...state,
				clearBookingSuccessMessage: '',
				clearBookingError: '',
			};

		case ReviewTypes.CLEAR_TRANSFER_STATUS:
			return {
				...state,
				transferSuccessMessage: '',
				transferError: '',
			};

		default:
			return state;
	}
};

export default reviewReducer;
