const PropertyDataActionTypes = {
	FETCH_PROPERTIES_START: 'FETCH_PROPERTIES_START',
	FETCH_PROPERTIES_SUCCESS: 'FETCH_PROPERTIES_SUCCESS',
	FETCH_PROPERTIES_FAILURE: 'FETCH_PROPERTIES_FAILURE',
	REFETCH_PROPERTIES_START: 'REFETCH_PROPERTIES_START',
	REFETCH_PROPERTIES_SUCCESS: 'REFETCH_PROPERTIES_SUCCESS',
	REFETCH_PROPERTIES_FAILURE: 'REFETCH_PROPERTIES_FAILURE',
	SET_LATEST_DOC: 'SET_LATEST_DOC',
	SET_DATA_EMPTY: 'SET_DATA_EMPTY',
	CLEAR_PROPERTIES: 'CLEAR_PROPERTIES',
};

export default PropertyDataActionTypes;
