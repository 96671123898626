import styled from 'styled-components';
import { palette } from '../../../utils';

export const PhoneFooterWrapper = styled.div`
	padding: 20px 5vw;
	color: ${palette.grey};
	p {
		margin: 0;
	}
	a {
		margin: 15px 10px;
		font-weight: 500;
		color: ${palette.green};
	}
	svg {
		margin: 5px 0;
	}
`;
