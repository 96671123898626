import React from 'react';
import { ModalWrapper } from '../../globals/appGlobal';
import SignUp from '../sign-up/signUp';
import SignIn from '../sign-in/signIn';
import { connect } from 'react-redux';
import { closeLogModal } from '../../redux/modal/modal.actions';

const LogModal = ({ closeLogModal }) => {
	const closeModal = e => {
		e.target.id === 'modalWrapper' && closeLogModal();
	};
	return (
		<ModalWrapper onClick={closeModal} id='modalWrapper'>
			<SignIn />
			<SignUp />
		</ModalWrapper>
	);
};

const mapDispatchToProps = dispatch => ({
	closeLogModal: () => dispatch(closeLogModal()),
});

export default connect(null, mapDispatchToProps)(LogModal);
