import { all, put, call, takeLatest } from 'redux-saga/effects';
import { firestore } from '../../firebase/firebase.config';
import AvailabilityTypes from './availability.types';
import {
	removeReservedFailure,
	removeReservedSuccess,
	removeSingleReservedFailure,
	removeSingleReservedSuccess,
	reserveDatesFailure,
	reserveDatesSuccess,
} from './availability.actions';

function* reserveDates({ payload }) {
	const { uid, startDate, endDate } = payload;

	try {
		const propertyRef = yield firestore.collection('properties').doc(uid);
		const propertySnapshot = yield propertyRef.get();
		const propertyData = yield propertySnapshot.data();
		const reservedDates = propertyData.reservedDates ?? [];

		const updatedReservedDates = [
			...reservedDates,
			{
				startDate: String(startDate._d),
				endDate: String(endDate._d),
			},
		];

		yield propertyRef.update({
			reservedDates: updatedReservedDates,
		});

		yield put(reserveDatesSuccess('Reserved dates successfully'));
	} catch (e) {
		yield put(reserveDatesFailure(e.message));
	}
}

function* onReserveDatesStart() {
	yield takeLatest(AvailabilityTypes.RESERVE_DATES_START, reserveDates);
}

function* removeReserved({ payload }) {
	try {
		const propertyRef = yield firestore
			.collection('properties')
			.doc(payload);

		yield propertyRef.update({
			reservedDates: [],
		});

		yield put(removeReservedSuccess('Cleared reserved dates'));
	} catch (e) {
		yield put(removeReservedFailure(e.message));
	}
}

function* onRemoveReservedStart() {
	yield takeLatest(AvailabilityTypes.REMOVE_RESERVED_START, removeReserved);
}

function* removeSingleReserved({ payload }) {
	const { uid, startDate, endDate } = payload;

	try {
		const propertyRef = yield firestore.collection('properties').doc(uid);
		const propertySnapshot = yield propertyRef.get();
		const propertyData = yield propertySnapshot.data();
		const reservedDates = propertyData.reservedDates ?? [];

		if (reservedDates.length > 0) {
			const otherReservedDates = reservedDates.filter(
				date =>
					date.startDate !== String(startDate._id) &&
					date.endDate !== String(endDate._id),
			);
			if (otherReservedDates.length === reservedDates) {
				yield put(
					removeSingleReservedSuccess(
						'The date range you inputted does not match any of your reserved dates',
					),
				);
			} else if (otherReservedDates.length < reservedDates) {
				yield propertyRef.update({
					reservedDates: otherReservedDates,
				});
				yield put(
					removeSingleReservedSuccess(
						'Successfully removed date reservation',
					),
				);
			}
		} else if (reservedDates.length === 0) {
			yield put(
				removeSingleReservedSuccess('You have not reserved any dates'),
			);
		}
	} catch (e) {
		yield put(removeSingleReservedFailure(e.message));
	}
}

function* onRemoveSingleReservedStart() {
	yield takeLatest(
		AvailabilityTypes.REMOVE_SINGLE_RESERVED_START,
		removeSingleReserved,
	);
}

export function* reserveDatesSagas() {
	yield all([
		call(onReserveDatesStart),
		call(onRemoveReservedStart),
		call(onRemoveSingleReservedStart),
	]);
}
