import PropertyUploadTypes from './property-upload.types';

export const createPropertyStart = ({ propertyDetails, isComplete }) => ({
	type: PropertyUploadTypes.CREATE_PROPERTY_START,
	payload: { propertyDetails, isComplete },
});

export const createPropertySuccess = response => ({
	type: PropertyUploadTypes.CREATE_PROPERTY_SUCCESS,
	payload: response,
});

export const createPropertyFailure = error => ({
	type: PropertyUploadTypes.CREATE_PROPERTY_FAILURE,
	payload: error,
});

export const setUploadSuccessMessage = message => ({
	type: PropertyUploadTypes.SET_UPLOAD_SUCCESS_MESSAGE,
	payload: message,
});

export const setFinalUploadSuccessMessage = message => ({
	type: PropertyUploadTypes.SET_FINAL_UPLOAD_SUCCESS_MESSAGE,
	payload: message,
});

export const clearUploadSuccessMessage = () => ({
	type: PropertyUploadTypes.CLEAR_UPLOAD_SUCCESS_MESSAGE,
});

export const getPropertyStart = ({ id, userProperties }) => ({
	type: PropertyUploadTypes.GET_PROPERTY_START,
	payload: { id, userProperties },
});

export const getPropertySuccess = propertyDetails => ({
	type: PropertyUploadTypes.GET_PROPERTY_SUCCESS,
	payload: propertyDetails,
});

export const getPropertyFailure = error => ({
	type: PropertyUploadTypes.GET_PROPERTY_FAILURE,
	payload: error,
});

export const propertyEditStart = propertyDetails => ({
	type: PropertyUploadTypes.PROPERTY_EDIT_START,
	payload: propertyDetails,
});

export const propertyEditSuccess = response => ({
	type: PropertyUploadTypes.PROPERTY_EDIT_SUCCESS,
	payload: response,
});

export const propertyEditFailure = error => ({
	type: PropertyUploadTypes.PROPERTY_EDIT_FAILURE,
	payload: error,
});

export const removePropertyStart = id => ({
	type: PropertyUploadTypes.REMOVE_PROPERTY_START,
	payload: id,
});

export const removePropertySuccess = response => ({
	type: PropertyUploadTypes.REMOVE_PROPERTY_SUCCESS,
	payload: response,
});

export const removePropertyFailure = error => ({
	type: PropertyUploadTypes.REMOVE_PROPERTY_FAILURE,
	payload: error,
});

export const clearCurrentProperty = () => ({
	type: PropertyUploadTypes.CLEAR_CURRENT_PROPERTY,
});

export const getBanksStart = () => ({
	type: PropertyUploadTypes.GET_BANKS_START,
});

export const getBanksSuccess = payload => ({
	type: PropertyUploadTypes.GET_BANKS_SUCCESS,
	payload,
});

export const getBanksFailure = error => ({
	type: PropertyUploadTypes.GET_BANKS_FAILURE,
	payload: error,
});
