import { createSelector } from 'reselect';

const selectUser = state => state.user;

export const selectCurrentUser = createSelector(
	[selectUser],
	user => user.currentUser,
);

export const selectError = createSelector([selectUser], user => user.error);

export const selectSuccessMessage = createSelector(
	[selectUser],
	user => user.successMessage,
);

export const selectEditSuccessMessage = createSelector(
	[selectUser],
	user => user.editSuccessMessage,
);

export const selectResetSuccessMessage = createSelector(
	[selectUser],
	user => user.resetSuccessMessage,
);

export const selectSignUpSuccessMessage = createSelector(
	[selectUser],
	user => user.signUpSuccess,
);

export const selectResettingPassword = createSelector(
	[selectUser],
	user => user.resettingPassword,
);

export const selectLoadingUser = createSelector(
	[selectUser],
	user => user.loadingUser,
);

export const selectLoadingGoogleUser = createSelector(
	[selectUser],
	user => user.loadingGoogleUser,
);

export const selectUpdatingUser = createSelector(
	[selectUser],
	user => user.updatingUser,
);

export const selectDeletingUser = createSelector(
	[selectUser],
	user => user.deletingUser,
);

export const selectSendingVerification = createSelector(
	[selectUser],
	user => user.sendingVerification,
);

export const selectSignedOut = createSelector(
	[selectUser],
	user => user.signedOut,
);
