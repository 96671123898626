import _ from 'lodash';
export const randomPhoneGenerator = () => {
	const newNumbers = ['0553220405', '0502271611'];
	const firstNumber = _.sample(newNumbers);
	const selectedNumberPos = newNumbers.indexOf(firstNumber);
	if (selectedNumberPos > -1) {
		newNumbers.splice(selectedNumberPos, 1);
	}
	return [firstNumber, _.sample(newNumbers)];
};

export const otherAgentNumber = agentNumber => {
	const newNumbers = ['0553220405', '0502271611'];
	const selectedNumberPos = newNumbers.indexOf(agentNumber);
	if (selectedNumberPos > -1) {
		newNumbers.splice(selectedNumberPos, 1);
	}
	return [agentNumber, _.sample(newNumbers)];
};
