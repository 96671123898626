import PaymentActionTypes from './payment.types';

const INITIAL_STATE = {
	isUpdating: false,
	error: '',
	success: '',
	checkoutProperty: null,
	isFetchingCheckoutProperty: true,
	checkoutPropertyError: '',
};

const paymentReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case PaymentActionTypes.UPDATE_LAST_DATE_PAID_START:
			return {
				...state,
				isUpdating: true,
				error: '',
			};

		case PaymentActionTypes.UPDATE_LAST_DATE_PAID_SUCCESS:
			return {
				...state,
				isUpdating: false,
				error: '',
				success: 'Payment successfully made',
			};

		case PaymentActionTypes.UPDATE_LAST_DATE_PAID_FAILURE:
			return {
				...state,
				isUpdating: false,
				error: action.payload,
			};

		case PaymentActionTypes.FETCH_CHECKOUT_PROPERTY_START:
			return {
				...state,
				isFetchingCheckoutProperty: true,
				checkoutPropertyError: '',
			};

		case PaymentActionTypes.FETCH_CHECKOUT_PROPERTY_SUCCESS:
			return {
				...state,
				isFetchingCheckoutProperty: false,
				checkoutPropertyError: '',
				checkoutProperty: action.payload,
			};

		case PaymentActionTypes.FETCH_CHECKOUT_PROPERTY_FAILURE:
			return {
				...state,
				isFetchingCheckoutProperty: false,
				checkoutPropertyError: action.payload,
			};

		default:
			return state;
	}
};

export default paymentReducer;
