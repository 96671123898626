import { all, put, call, takeLatest } from 'redux-saga/effects';
import SavedAdTypes from './save-ad.types';
import { auth, firestore, functions } from '../../firebase/firebase.config';
import {
	fetchSavedFailure,
	fetchSavedSuccess,
	saveAdFailure,
	saveAdSuccess,
} from './save-ad.actions';
import { convertPropertySnapshotToMap } from '../../firebase/firebase.helpers';

//  Save Ad
function* saveAd({ payload: { saved, uid } }) {
	try {
		let updatedSavedAds = [];
		if (saved.includes(uid)) {
			updatedSavedAds = saved.filter(ad => ad !== uid);
		} else {
			updatedSavedAds = [...saved, uid];
		}
		yield firestore.collection('users').doc(auth.currentUser.uid).update({
			saved: updatedSavedAds,
		});
		yield put(saveAdSuccess());
	} catch (error) {
		yield put(saveAdFailure(error.message));
	}
}

function* onSaveAdStart() {
	yield takeLatest(SavedAdTypes.SAVE_AD_START, saveAd);
}

//  Fetch Saved Ads
function* fetchSaved({ payload }) {
	try {
		if (payload.length > 0 && payload.length <= 10) {
			const propertiesRef = firestore.collection('properties');
			const savedAds = yield propertiesRef.where('uid', 'in', payload);
			const savedAdsSnapshot = yield savedAds.get();
			const transformedData = yield call(
				convertPropertySnapshotToMap,
				savedAdsSnapshot,
			);
			yield put(fetchSavedSuccess(transformedData));
		} else if (payload.length > 10) {
			const fetchArrayProperties = functions.httpsCallable(
				'fetchArrayProperties',
			);
			const {
				data: { properties },
			} = yield fetchArrayProperties({ array: payload });
			yield put(fetchSavedSuccess(properties));
		} else {
			yield put(fetchSavedSuccess([]));
		}
	} catch (error) {
		yield put(fetchSavedFailure(error.message));
	}
}

function* onFetchSavedStart() {
	yield takeLatest(SavedAdTypes.FETCH_SAVED_START, fetchSaved);
}

export function* saveAdSagas() {
	yield all([call(onSaveAdStart), call(onFetchSavedStart)]);
}
