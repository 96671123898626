import AvailabilityTypes from './availability.types';

export const reserveDatesStart = datesData => ({
	type: AvailabilityTypes.RESERVE_DATES_START,
	payload: datesData,
});

export const reserveDatesSuccess = success => ({
	type: AvailabilityTypes.RESERVE_DATES_SUCCESS,
	payload: success,
});

export const reserveDatesFailure = error => ({
	type: AvailabilityTypes.RESERVE_DATES_FAILURE,
	payload: error,
});

export const removeReservedStart = uid => ({
	type: AvailabilityTypes.REMOVE_RESERVED_START,
	payload: uid,
});

export const removeReservedSuccess = success => ({
	type: AvailabilityTypes.REMOVE_RESERVED_SUCCESS,
	payload: success,
});

export const removeReservedFailure = error => ({
	type: AvailabilityTypes.REMOVE_RESERVED_FAILURE,
	payload: error,
});

export const removeSingleReservedStart = datesData => ({
	type: AvailabilityTypes.REMOVE_SINGLE_RESERVED_START,
	payload: datesData,
});

export const removeSingleReservedSuccess = success => ({
	type: AvailabilityTypes.REMOVE_SINGLE_RESERVED_SUCCESS,
	payload: success,
});

export const removeSingleReservedFailure = error => ({
	type: AvailabilityTypes.REMOVE_SINGLE_RESERVED_FAILURE,
	payload: error,
});

export const clearReservedStatus = () => ({
	type: AvailabilityTypes.CLEAR_RESERVED_STATUS,
});
