import UserActionTypes from './user.types';

const INITIAL_VALUE = {
	currentUser: null,
	error: '',
	loadingUser: false,
	loadingGoogleUser: false,
	updatingUser: false,
	deletingUser: false,
	resettingPassword: false,
	successMessage: '',
	sendingVerification: false,
	editSuccessMessage: '',
	resetSuccessMessage: '',
	signedOut: true,
	signUpSuccess: '',
};

const userReducer = (state = INITIAL_VALUE, action) => {
	switch (action.type) {
		case UserActionTypes.SIGN_UP_START:
		case UserActionTypes.EMAIL_SIGN_IN_START:
		case UserActionTypes.SIGN_OUT_START:
			return {
				...state,
				loadingUser: true,
				successMessage: '',
				error: '',
			};

		case UserActionTypes.GOOGLE_SIGN_IN_START: {
			return {
				...state,
				loadingGoogleUser: true,
				successMessage: '',
				error: '',
			};
		}

		case UserActionTypes.CHECK_USER_SESSION:
			return {
				...state,
				loadingUser: false,
			};

		case UserActionTypes.SIGN_UP_SUCCESS:
			return {
				...state,
				signUpSuccess: action.payload,
			};

		case UserActionTypes.CLEAR_SIGN_UP_SUCCESS:
			return {
				...state,
				signUpSuccess: '',
			};

		case UserActionTypes.SIGN_IN_SUCCESS:
			return {
				...state,
				currentUser: action.payload,
				error: null,
				loadingUser: false,
				loadingGoogleUser: false,
				successMessage: 'signed in',
				signedOut: false,
			};

		case UserActionTypes.SIGN_OUT_SUCCESS:
			return {
				...state,
				currentUser: null,
				error: null,
				loadingUser: false,
				successMessage: '',
				signedOut: true,
			};

		case UserActionTypes.SIGN_UP_FAILURE:
		case UserActionTypes.SIGN_IN_FAILURE:
		case UserActionTypes.SIGN_OUT_FAILURE:
			return {
				...state,
				error: action.payload,
				loadingUser: false,
				loadingGoogleUser: false,
			};

		case UserActionTypes.PASSWORD_RESET_START:
			return {
				...state,
				resetSuccessMessage: '',
				error: '',
				resettingPassword: true,
			};

		case UserActionTypes.PASSWORD_RESET_SUCCESS:
			return {
				...state,
				resetSuccessMessage: action.payload,
				resettingPassword: false,
			};

		case UserActionTypes.CLEAR_PASSWORD_RESET_SUCCESS:
			return {
				...state,
				resetSuccessMessage: '',
			};

		case UserActionTypes.SEND_VERIFICATION_START:
			return {
				...state,
				error: '',
				successMessage: '',
				sendingVerification: true,
			};

		case UserActionTypes.SEND_VERIFICATION_SUCCESS:
			return {
				...state,
				sendingVerification: false,
				successMessage:
					'A verification mail has been sent. Please check your email and verify',
			};

		case UserActionTypes.PASSWORD_RESET_FAILURE:
		case UserActionTypes.SEND_VERIFICATION_FAILURE:
			return {
				...state,
				error: action.payload,
				sendingVerification: false,
				resetSuccessMessage: '',
				resettingPassword: false,
			};

		case UserActionTypes.EDIT_USER_PROFILE_START:
			return {
				...state,
				updatingUser: true,
				editSuccessMessage: '',
				error: '',
			};

		case UserActionTypes.EDIT_USER_PROFILE_SUCCESS:
			return {
				...state,
				updatingUser: false,
				editSuccessMessage: action.payload,
				error: '',
			};

		case UserActionTypes.EDIT_USER_PROFILE_FAILURE:
			return {
				...state,
				updatingUser: false,
				error: action.payload,
				editSuccessMessage: '',
			};

		case UserActionTypes.CLEAR_EDIT_SUCCESS:
			return {
				...state,
				editSuccessMessage: '',
				error: '',
			};

		case UserActionTypes.DELETE_USER_ACCOUNT_START:
			return {
				...state,
				deletingUser: true,
				successMessage: '',
				error: '',
			};

		case UserActionTypes.DELETE_USER_ACCOUNT_SUCCESS:
			return {
				...state,
				deletingUser: false,
				error: '',
				successMessage: action.payload,
			};

		case UserActionTypes.DELETE_USER_ACCOUNT_FAILURE:
			return {
				...state,
				deletingUser: false,
				error: action.payload,
				successMessage: '',
			};

		case UserActionTypes.SET_USER_ERROR_FALSE:
			return {
				...state,
				error: '',
			};

		case UserActionTypes.CLEAR_USER_STATUS:
			return {
				...state,
				error: '',
			};

		default:
			return state;
	}
};

export default userReducer;
