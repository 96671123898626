import { v4 } from 'uuid';

export const regions = [
	'Ashanti',
	'Ahafo',
	'Bono East',
	'Bono',
	'Central',
	'Eastern',
	'Greater Accra',
	'North East',
	'Northern',
	'Oti',
	'Savannah',
	'Upper East',
	'Upper West',
	'Volta',
	'Western North',
	'Western',
];

export const districts = [
	'Adansi North District',
	'Adansi South District',
	'Afigya-Kwabre District',
	'Ahafo Ano North District',
	'Ahafo Ano South District',
	'Amansie Central District',
	'Amansie West District',
];

export const propertyType = [
	'Apartment',
	'Guest House',
	'Hostel',
	'Hotel',
	'House',
];

export const responseTime = [
	'30 minutes',
	'1 hour',
	'2 hours',
	'3 hours',
	'4 hours',
	'5 hours',
	'6 hours',
	'12 hours',
	'16 hours',
	'1 day',
	'2 days',
	'3 days',
	'4 days',
	'1 week',
];

export const propertyClass = ['Edanra Stay', 'Regular Ad'];

export const propertyRate = ['Per night', 'Per month'];

export const negotiationStatus = ['Negotiable', 'Non-negotiable'];

export const regionDocs = [
	{
		id: v4(),
		region: 'Ashanti Region',
		image: 'ashanti.svg',
		description:
			'Ashanti Region is known for its extremely rich cultural heritage and roots to the bright and dazzling Kente Clothes.  Known as the city of gold. Kumasi is the ancient capital of the Region and Ghana’s second largest city. It has remained the home of the Asantehene (Osei Tutu); Manhyia Palace. Kumasi is known also for its cheap foods and open market at the famous Kejetia.  Kumasi is the place to find the cheapest Fufu in Ghana. As the heartbeat of the country’s culture, the former Ashanti Kingdom is known all across West Africa.',
	},
	{
		id: v4(),
		region: 'Ahafo Region',
		image: 'ahafo.svg',
		description:
			'Ahafo is another region birth from the former Brong-Ahafo Region, with Goaso as its capital. Ahafo  has a low populaton density and is home to multiple water bodies such as Tano river and the black volta. Homes in Ahafo are very cheap and avaialable to everyone. The region was carved out of the south-eastern part of the Brong Ahafo Region and was in fulfillment of a campaign promise made by New Patriotic Party. The Ahafo Region is bordered on the north by the Bono region, the east by Ashanti Region, the west by the Bono region, the south by the Western North Region and is made up of 6 districts.',
	},
	{
		id: v4(),
		region: 'Bono East Region',
		image: 'bono-east.svg',
		description:
			'The Bono East Region is a new region carved out of the Brong Ahafo region. The capital of the new region is Techiman. Bono East covers a land area of 22,952 square kilometres and has a population size of about one million people. Rivers like the Black Volta and river Tano run through the region and provides many economic benefits to the people. Major tourist sites in Bono East are Kintampo waterfalls, Kintampo canopy walkway, fuller falls, Bono Manso Slave Market, Digya National Park. The Bono East region is part of the vegetative belt of Ghana and enjoy a climate that is not harsh.',
	},
	{
		id: v4(),
		region: 'Bono Region',
		image: 'bono.svg',
		description:
			'Created out of the bigger Brong Ahafo Region, the Bono region sits east with Techniman as its capital. Despite it very low population density, the capital is very noted for its open lively market in Techiman.  A few must pass through in Bono include the Bui National Park, Bui Dam and the Black Volta. Bono Region shares a border at the north with the Savannah Region, is bordered on the west by Ghana-Cote d’Ivoire international border, on the east by Bono East, and on the south by Ahafo Region. It has a population of about 1,082,520 according to Ghana statistical service in 2019 census.',
	},
	{
		id: v4(),
		region: 'Central Region',
		image: 'central.svg',
		description:
			'The central region, the house of government of the then GoldCoast has amazing forts and castles which are among the World Heritage Monuments. With Cape Coast as its capital, the region’s culture is depicted through very exciting festivals like Bakatue, Aboakyer. It remains the central point for discovering the historic connections between Africa, America and Europe or roots of African American culture. The region has beautiful places like Kakum National Park,   Cape Coast Castle Musuem and Castles, Posuban Shrine, Traditional fishing villages.',
	},
	{
		id: v4(),
		region: 'Eastern Region',
		image: 'eastern.svg',
		description:
			'The eastern region is known for its mountains from Kwahu, the go to region during the Easter season to Akwapim highlands. Entering the eastern region, one is graced with an amazing view of main Accra landscape.  As a result the main location for paragliding. With Koforidua as its capital, this region is home to the country’s powerhouse, Akosombo Dam.  The region is blessed with mineral resource like Gold, Diamond, potential real estate lands and the great cocoa industry, housing the Cocoa Research Institute at New Tarfo.',
	},
	{
		id: v4(),
		region: 'Greater Accra Region',
		image: 'accra.svg',
		description:
			'Despite its small size, the bustling city is the main entrance via air into Ghana with the West Africa’s best airport. With a very high population density, Accra is the capital of this region. As the central business region, it houses the country’s main harbor in Tema and major headquarters of local and international companies.  It is also home to castle and forts built by the Europeans during the colonial era. Among the great must visit sites in Accra include the square, the resorts, Shai hills reserves, Museums, the ever-lively OSU streets and the coastlines.',
	},
	{
		id: v4(),
		region: 'North East Region',
		image: 'north-east.svg',
		description:
			"As one of the newly created regions it houses the Kwame Nkrumah lodge at Nakpaanduri, Kariminga Eco Village, Gamabaga witches camp and Daboya local textile centre. With a majority Muslim people and low population density with rural setting, it's rivers shake hands with the Volta river. Home in these regions are very cheap. The North East Region is bordered on the north by the Upper East region, on the east by the eastern Ghana-Togo international border, on the south by the Northern region, and on the west by the Upper West region. North East region is made up of 6 districts.",
	},
	{
		id: v4(),
		region: 'Northern Region',
		image: 'northern.svg',
		description:
			'Northern Region formerly called mother of all regions is know for its flat beautiful dry landscape. Unlike the major cities in like Accra, this region is known for its low population density. It prides itself in Greenwich meridian point in Yendi, Sahel, the Sahara, Gambaga escarpment and the Nakpanduri. The famous African baobab and acacia trees thrive within the northern region. Among the northern regions it stands with a very diverse culture and taste music. Ghana’s premier producers of shea butter.',
	},
	{
		id: v4(),
		region: 'Oti Region',
		image: 'oti.svg',
		description:
			'As part of the newly created regions carved from the Northern part of the Volta Region, Oti region is most part of Western Togolese colony with Dambai as its Capital. The Oti Region is made of the Guan people; Lolobi, Likpe, Akpafu, Buem, and Nkonya etc.  Great sites in Oti include the Kyabobo National Park, Lake Volta, Breast Mountain, Chaiso Forest and Hanging Village. Between December and April is the dry season. The wet season is between about May and November with an average annual rainfall of 750 to 1050 mm (30 to 40 inches).',
	},
	{
		id: v4(),
		region: 'Savannah Region',
		image: 'savanna.svg',
		description:
			'As one of the new regions Savannah has 7 districts with Damongo as its capital. Despite its low population density, it has amazing villages like Mognori Eco Village,  Ganja Traditional Cemetery, Daboya waterfall and  Salaga slave.  With majority of residents as Muslims. As sibling to the northern regions, Savanna prides in Mole national park, Larabanga Mosque, Wechiau Hippo Santuary, Bui National Park. The Savannah Region is much drier than southern areas of Ghana, due to its proximity to the Sahel, and the Sahara.',
	},
	{
		id: v4(),
		region: 'Upper East Region',
		image: 'upper-east.svg',
		description:
			'In the north east part of Ghana, it’s marked for its diverse cultures and history and the communal spirit among its communities. With Bolgatanga as its capital, it has a very low a rural population. For mysterious sites in this region the tourists do not miss include, the Rock formation in Tongo Hills, Paga crocodile pond, Sambo Bat sanctuary. The population is primarily rural (79%) and scattered in dispersed settlements. The Upper East Region of Ghana contains 15 districts made up of 2 municipal and 13 ordinary districts',
	},
	{
		id: v4(),
		region: 'Upper West Region',
		image: 'upper-west.svg',
		description:
			'The Upper West Region of Ghana is located in the north-western corner of Ghana and is bordered by Upper East region to the east, Northern region to the south, and Burkina Faso to the west and north. With a majority Muslim population, the region is popular for its special savannah grasslands that stretch far and wide with baobab trees standing strong atop the grassland and Wechiau Hippo sanctuary and Gbelle Game reserve are the main tourist sites that normally attract tourists.',
	},
	{
		id: v4(),
		region: 'Volta Region',
		image: 'volta.svg',
		description:
			'Volta is Ghana’s South-eastern region separated from all other regions by Volta lake. It serves as the entry point for Ghanaians to Togo.  Its non-uniform landscape of Valleys and Hills and Climatic conditions leaves a print in your mind on visit. The region enjoys very attractive gifts from nature like Lagoons, waterfalls and mountains. The capital Ho, example lies between Mount Adaklu and Mount Galenu. Speed boat rides on the Volta River and under the Adomi bridge make you want to come back. ',
	},
	{
		id: v4(),
		region: 'Western North Region',
		image: 'western-north.svg',
		description:
			'Western north created from the former full western region is dominated by the Sefwis ethnic group. The region is also known for its small-scale gold mines and large scale Cocoa and other cash crop farming. Apart from having the highest rainfall in Ghana, the region has several forest reserves that that very great for runaway trips and tourists. There are numerous small and large-scale gold mines companies in the region. The Western North Region of Ghana contains nine ordinary districts',
	},
	{
		id: v4(),
		region: 'Western Region',
		image: 'western.svg',
		description:
			'Currently known for its abundance in Crude Oil at Cape Three Point and birthplace of Dr. Kwame Nkrumah, the region produces 80% of Ghana’s resources, from Timber, Gold to Rubber and with Ghana’s 2nd largest industrial area. The Western Region has a diverse heritage, with several castles and forts; like Fort Fredericksburg, Fort Orange and Fort Antonio, Apollonia along its vast stretch coastlines and serene beaches like Busua to Beyin, in Azim, Nzulenzu. As the gateway for Ghanaians into Ivory Coast it covers 23,921 sq. km with population of ~2,380,021, blessed with Twin Cities, Sekondi-Takoradi.',
	},
];

//dummy data for properties (delete when done)
export const dummyRegions = [
	{
		id: v4(),
		type: 'hostel',
		bedroom: 3,
		town: 'Ashwidiasi',
		host: 'Mr. Mensah',
		price: '233',
		rate: 'night',
		status: 'negotiable',
		class: 'regular',
		image: 'house1.jpg',
		hosted: true,
	},
	{
		id: v4(),
		type: 'house',
		bedroom: 3,
		town: 'Manhyia',
		host: 'Mrs. Dorcas',
		price: '100',
		rate: 'night',
		status: 'non-negotiable',
		class: 'regular',
		saved: ['43454', '64767656', '4765876'],
		image: 'house2.jpg',
		hosted: true,
	},
	{
		id: v4(),
		type: 'house',
		bedroom: 3,
		town: 'Tafo',
		host: 'John Casey',
		price: '830',
		rate: 'month',
		saved: ['43454', '64767656', '4765876'],
		status: 'negotiable',
		class: 'regular',
		image: 'house3.jpg',
		hosted: true,
	},
	{
		id: v4(),
		type: 'apartment',
		bedroom: 3,
		town: 'Suame',
		host: 'Peter Dury',
		price: '800',
		rate: 'month',
		saved: ['43454', '64767656', '4765876'],
		status: 'non-negotiable',
		class: 'edanra',
		image: 'house4.jpg',
		hosted: false,
	},
	{
		id: v4(),
		type: 'apartment',
		bedroom: 3,
		town: 'Asokwa',
		host: 'Yaw Takyi',
		price: '559',
		rate: 'night',
		status: 'negotiable',
		class: 'ordinary',
		image: 'house5.jpg',
	},
	{
		id: v4(),
		type: 'hotel',
		bedroom: 3,
		town: 'Oforikrom,',
		host: 'Kwesi Attah',
		price: '900',
		rate: 'night',
		saved: ['43454', '64767656', '4765876'],
		status: 'non-negotiable',
		class: 'ordinary',
		image: 'house6.jpg',
		hosted: false,
	},
	{
		id: v4(),
		type: 'hostel',
		bedroom: 3,
		town: 'Asawase',
		host: 'Kofi Stone',
		price: '300',
		saved: ['43454', '64767656', '4765876'],
		rate: 'month',
		status: 'negotiable',
		class: 'edanra',
		image: 'house7.jpg',
		hosted: true,
	},
	{
		id: v4(),
		type: 'house',
		bedroom: 3,
		town: 'Bantama',
		host: 'Paul Mensah',
		price: '203',
		rate: 'night',
		saved: ['43454', '64767656', '4765876'],
		status: 'non-negotiable',
		class: 'edanra',
		image: 'house1.jpg',
		hosted: false,
	},
	{
		id: v4(),
		type: 'apartment',
		bedroom: 3,
		town: ' Kwadaso',
		host: 'John Cena',
		price: '1200',
		rate: 'month',
		saved: ['43454', '64767656', '4765876'],
		status: 'non-negotiable',
		class: 'ordinary',
		image: 'house2.jpg',
		hosted: true,
	},
	{
		id: v4(),
		type: 'house',
		bedroom: 3,
		town: 'Nhyiaeso',
		host: 'Mr. Mensah',
		price: '233',
		rate: 'night',
		saved: ['43454', '64767656', '4765876'],
		status: 'negotiable',
		class: 'ordinary',
		image: 'house3.jpg',
		hosted: true,
	},
	{
		id: v4(),
		type: 'hostel',
		bedroom: 3,
		town: 'Subin',
		host: 'Samuel Clarkson',
		price: '552',
		rate: 'month',
		saved: ['43454', '64767656', '4765876'],
		status: 'negotiable',
		class: 'edanra',
		image: 'house4.jpg',
		hosted: false,
	},
	{
		id: v4(),
		type: 'house',
		bedroom: 3,
		town: 'Labone',
		host: 'Tettey Tetteh',
		price: '1384',
		rate: 'month',
		saved: ['43454', '64767656', '4765876'],
		status: 'non-negotiable',
		class: 'edanra',
		image: 'house5.jpg',
		hosted: false,
	},
	{
		id: v4(),
		type: 'apartment',
		bedroom: 3,
		town: 'Cantonments',
		host: 'Paul Afoko',
		price: '1231',
		rate: 'month',
		saved: ['43454', '64767656', '4765876'],
		status: 'negotiable',
		class: 'ordinary',
		image: 'house6.jpg',
		hosted: false,
	},
	{
		id: v4(),
		type: 'hostel',
		bedroom: 3,
		town: 'Burma Camp',
		host: 'Mark Mantey',
		price: '550',
		rate: 'night',
		saved: ['43454', '64767656', '4765876'],
		status: 'non-negotiable',
		class: 'edanra',
		image: 'house7.jpg',
		hosted: true,
	},
	{
		id: v4(),
		type: 'guest house',
		bedroom: 3,
		town: 'Chorkor',
		host: 'Sam Johnson',
		price: '5909',
		rate: 'month',
		saved: ['43454', '64767656', '4765876'],
		status: 'negotiable',
		class: 'edanra',
		image: 'house1.jpg',
		hosted: true,
	},
	{
		id: v4(),
		type: 'guest house',
		bedroom: 3,
		town: 'Mamprobi',
		host: 'Mike Kwame',
		price: '233',
		rate: 'night',
		saved: ['43454', '64767656', '4765876'],
		status: 'negotiable',
		class: 'edanra',
		image: 'house2.jpg',
		hosted: true,
	},
	{
		id: v4(),
		type: 'guest house',
		bedroom: 3,
		town: 'Korle Bu',
		host: 'Tetteh Kwashie',
		price: '4544',
		rate: 'month',
		saved: ['43454', '64767656', '4765876'],
		status: 'non-negotiable',
		class: 'ordinary',
		image: 'house3.jpg',
		hosted: true,
	},
	{
		id: v4(),
		type: 'hotel',
		bedroom: 3,
		town: 'Korle Gonno',
		host: 'Juliet Peprah',
		price: '55',
		rate: 'night',
		saved: ['43454', '64767656', '4765876'],
		status: 'non-negotiable',
		class: 'edanra',
		image: 'house4.jpg',
		hosted: true,
	},
	{
		id: v4(),
		type: 'apartment',
		bedroom: 3,
		town: 'Lartebiokorshie',
		host: 'Mark Tears',
		price: '900',
		rate: 'month',
		saved: ['43454', '64767656', '4765876'],
		status: 'negotiable',
		class: 'ordinary',
		image: 'house5.jpg',
		hosted: true,
	},
	{
		id: v4(),
		type: 'house',
		bedroom: 3,
		town: 'Mataheko',
		host: 'James Brown',
		price: '1002',
		rate: 'month',
		saved: ['43454', '64767656', '4765876'],
		status: 'negotiable',
		class: 'ordinary',
		image: 'house6.jpg',
		hosted: true,
	},
	{
		id: v4(),
		type: 'guest house',
		bedroom: 3,
		town: 'Adenta',
		host: 'Adjoa Kakra',
		price: '2000',
		rate: 'month',
		saved: ['43454', '64767656', '4765876'],
		status: 'non-negotiable',
		class: 'edanra',
		image: 'house7.jpg',
		hosted: true,
	},
	{
		id: v4(),
		type: 'hostel',
		bedroom: 3,
		town: 'Kanda',
		host: 'Judas Iscariot',
		price: '700',
		rate: 'night',
		saved: ['43454', '64767656', '4765876'],
		status: 'negotiable',
		class: 'ordinary',
		image: 'house1.jpg',
		hosted: true,
	},
];

export const reviews = [
	{
		id: v4(),
		name: 'John Doe',
		img: 'john',
		date: 'August 31, 2000',
		review:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
	},
	{
		id: v4(),
		name: 'Jane Doe',
		img: 'jane',
		date: 'January 31, 2000',
		review:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
	},
	{
		id: v4(),
		name: 'Peter Griffith',
		img: 'peter',
		date: 'February 31, 2000',
		review:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
	},
	{
		id: v4(),
		name: 'Queen Latifa',
		img: 'queen',
		date: 'May 31, 2000',
		review:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
	},
	{
		id: v4(),
		name: 'John Cena',
		img: 'cena',
		date: 'July 31, 2000',
		review:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
	},
	{
		id: v4(),
		name: 'Angela Merkel',
		img: 'angela',
		date: 'December 31, 2000',
		review:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
	},
];

export const popularPlaces = [
	{
		id: v4(),
		name: 'Tema',
		region: 'Greater Accra',
		img: 'tema',
	},
	{
		id: v4(),
		name: 'Accra',
		region: 'Greater Accra',
		img: 'accra',
	},
	{
		id: v4(),
		name: 'Takoradi',
		region: 'Western',
		img: 'tadi',
	},
	// {
	// 	id: v4(),
	// 	name: 'Cape Coast',
	// 	region: 'Central',
	// 	img: 'cape',
	// },
	{
		id: v4(),
		name: 'Tamale',
		region: 'Northern',
		img: 'tamale',
	},
	// {
	// 	id: v4(),
	// 	name: 'Elmina',
	// 	region: 'Central',
	// 	img: 'elmina',
	// },
	{
		id: v4(),
		name: 'Kumasi',
		region: 'Ashanti',
		img: 'kumasi',
	},
	{
		id: v4(),
		name: 'Legon',
		region: 'Greater Accra',
		img: 'legon',
	},
	// {
	// 	id: v4(),
	// 	name: 'Koforidua',
	// 	region: 'Eastern',
	// 	img: 'koforidua',
	// },
	// {
	// 	id: v4(),
	// 	name: 'Ho',
	// 	region: 'Volta',
	// 	img: 'ho',
	// },
	// {
	// 	id: v4(),
	// 	name: 'Tarkwa',
	// 	region: 'Western',
	// 	img: 'tarkwa',
	// },
];

export const testimonies = [
	{
		id: 1,
		client: 'Emmanuella',
		title: ' Media Station Staff',
		image:
			'https://firebasestorage.googleapis.com/v0/b/edanra-v2.appspot.com/o/profile-images%2F1625479084970_Adobah%20Samuel%20?alt=media&amp;token=1c725563-791c-420b-99bb-d197e1ba853c',
		quotes:
			'Edanra is the only agency that was able to get me an affordable apartment where the landlord was willing to take just a year advance. I am really satisfied with the good work done. I will always recommend and work with you guys.',
	},
	{
		id: 2,
		client: 'Juliana',
		title: 'National Service Personnel',
		image:
			'https://firebasestorage.googleapis.com/v0/b/edanra-v2.appspot.com/o/profile-images%2F1625479084970_Adobah%20Samuel%20?alt=media&amp;token=1c725563-791c-420b-99bb-d197e1ba853c',
		quotes:
			'I encountered Edanra when I was looking for an apartment to rent for my service. I must say this is one of the best agencies I have encountered; best customer service and best housing options. Thank you Edanra. I love the apartment',
	},
	{
		id: 3,
		client: 'Obed Annan',
		title: 'Client',
		image:
			'https://firebasestorage.googleapis.com/v0/b/edanra-v2.appspot.com/o/profile-images%2Fobed%20annan.png?alt=media&token=b582ef61-af65-4e90-b5dd-5bb2764ab09b',
		quotes:
			'I used Edanra to find a nice honeymoon location. Choosing the best hotel for our get away was a hustle and Edanra came our way.',
	},
	{
		id: 4,
		client: 'Samuel Adobaw',
		title: 'Agent',
		image:
			'https://firebasestorage.googleapis.com/v0/b/edanra-v2.appspot.com/o/profile-images%2F1639122188618_Nana%20Osei%20Acheampong?alt=media&amp;token=d0fa955f-b20d-4904-b541-350a5dc8fe17',
		quotes:
			'My experience has been great so far. I have received lots of verified tenant leads through Edanra.',
	},
	{
		id: 5,
		client: 'Felinda Pomaa',
		title: 'Realtor',
		image:
			'https://firebasestorage.googleapis.com/v0/b/edanra-v2.appspot.com/o/profile-images%2F1636561508900_Keziah%20Amerley%20Amarteyfio%20?alt=media&amp;token=33aedcd3-7714-4e50-aea0-90353cd9a953',
		quotes:
			'As a verified realtor on Edanra I have been able to close a lot more deals than my previous years. Their dashboard helps me manage all my properties on the go.',
	},
	{
		id: 6,
		client: 'Esinam Mawuvi',
		title: 'Realtor',
		image:
			'https://lh3.googleusercontent.com/a-/AOh14GiY-6bD3IZteyTRHuCkPyNrHgfN9XKs-p8Cp24JIA=s96-c',
		quotes:
			'In just a month of putting up properties, my email was flooded with messages and requests for Rentals. I overlooked and thought it was just make-believe till all these requests turned out positive and referrals followed immediately after. I am glad to have met Edanra and will highly recommend it everyone',
	},
];

export const headerList = {
	'terms-of-use': [
		{
			name: 'About us',
			id: 'about-us',
		},
		{
			name: 'Accepting terms',
			id: 'accepting-terms',
		},
		{
			name: 'Age restriction',
			id: 'age-restriction',
		},
		{
			name: 'Terms changes',
			id: 'terms-changes',
		},
		{
			name: 'Site changes',
			id: 'site-changes',
		},
		{
			name: 'Your obligations',
			id: 'your-obligations',
		},
		{
			name: 'Account registration',
			id: 'account-registration',
		},
		{
			name: 'Services & content',
			id: 'services-&-content',
		},
		{
			name: 'Listings & hosts',
			id: 'listings-&-hosts',
		},
		{
			name: 'Cancellation policy',
			id: 'cancellation-policy',
		},
		{
			name: 'Pay out policy',
			id: 'pay-out-policy',
		},
		{
			name: 'Our rights and obligations',
			id: 'our-rights',
		},
		{
			name: 'Ranking & appearance',
			id: 'ranking-&-appearance',
		},
		{
			name: 'Intellectual property',
			id: 'intellectual-property',
		},
		{
			name: 'Prohibited activities',
			id: 'prohibited-activities',
		},
		{
			name: 'Barring from site',
			id: 'barring',
		},
		{
			name: 'Indemnity',
			id: 'indemnity',
		},
		{
			name: 'Applicable law',
			id: 'applicable-law',
		},
		{
			name: 'Dispute resolution',
			id: 'dispute-resolution',
		},
		{
			name: 'Waivers and assignment',
			id: 'waivers',
		},
		{
			name: 'Contact edanra',
			id: 'contact',
		},
	],
	'data-privacy': [
		{
			name: 'Introduction',
			id: 'data-privacy-intro',
		},
		{
			name: 'Application and scope',
			id: 'scope',
		},
		{
			name: 'Updates',
			id: 'updates',
		},
		{
			name: 'Age restriction',
			id: 'age-restriction',
		},
		{
			name: 'What we collect',
			id: 'what-we-collect',
		},
		{
			name: 'Security',
			id: 'security',
		},
		{
			name: 'What we retain',
			id: 'what-we-retain',
		},
		{
			name: 'Contact us',
			id: 'contact-us',
		},
	],
	'cookie-policy': [
		{
			name: 'Introduction',
			id: 'cookie-policy-intro',
		},
		{
			name: 'About Cookies',
			id: 'about-cookies',
		},
		{
			name: 'Why cookies ?',
			id: 'why-cookies',
		},
	],
};
