import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/analytics';

//  Config
const firebaseConfig = {
	apiKey: 'AIzaSyBRF1SAkLUEppxBHPfAMpQ0FAdK6doUiqE',
	authDomain: 'edanra-v2.firebaseapp.com',
	databaseURL: 'https://edanra-v2.firebaseio.com',
	projectId: 'edanra-v2',
	storageBucket: 'edanra-v2.appspot.com',
	messagingSenderId: '351102803916',
	appId: '1:351102803916:web:342b8b702cb47133db4769',
	measurementId: 'G-BWJZRWYN8M',
};

firebase.initializeApp(firebaseConfig);

// const db = firebase.firestore();
// if (window.location.hostname === 'localhost') {
// 	db.useEmulator('localhost', 8080);
// }

//  Services
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const functions = firebase.functions();
export const timestamp = firebase.firestore.FieldValue.serverTimestamp();
export const analytics = firebase.analytics();

//  Google Auth
export const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: 'select_account' });
export const signInWithGoogle = () => auth.signInWithPopup(googleProvider);

export default firebase;
