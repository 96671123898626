const HostProfileTypes = {
	FETCH_HOST_PROFILE_DETAILS_START: 'FETCH_HOST_PROFILE_DETAILS_START',
	FETCH_HOST_PROFILE_DETAILS_SUCCESS: 'FETCH_HOST_PROFILE_DETAILS_SUCCESS',
	FETCH_HOST_PROFILE_DETAILS_FAILURE: 'FETCH_HOST_PROFILE_DETAILS_FAILURE',
	FETCH_ALL_USER_PROPERTIES_START: 'FETCH_ALL_USER_PROPERTIES_START',
	FETCH_ALL_USER_PROPERTIES_SUCCESS: 'FETCH_ALL_USER_PROPERTIES_SUCCESS',
	FETCH_ALL_USER_PROPERTIES_FAILURE: 'FETCH_ALL_USER_PROPERTIES_FAILURE',
};

export default HostProfileTypes;
