import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './user/user.reducer';
import propertyUploadReducer from './property-upload/property-upload.reducer';
import propertiesDataReducer from './properties-data/properties-data.reducer';
import saveAdReducer from './save-ad/save-ad.reducer';
import manageAdReducer from './manage-ad/manage-ad.reducer';
import propertyDetailsReducer from './property-details/property-details..reducer';
import reviewReducer from './review/review.reducer';
import searchReducer from './search/search.reducer';
import exploreReducer from './explore/explore.reducer';
import bookingReducer from './book/book.reducer';
import bookingDataReducer from './booking-data/booking-data.reducer';
import paymentReducer from './payment/payment.reducer';
import modalReducer from './modal/modal.reducer';
import rangeReducer from './range/range.reducer';
import availabilityReducer from './availability/availability.reducer';
import hostProfileReducer from './host-profile/host-profile.reducer';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['property-upload'],
};

const rootReducer = combineReducers({
	user: userReducer,
	propertyUpload: propertyUploadReducer,
	propertiesData: propertiesDataReducer,
	saveAd: saveAdReducer,
	manageAd: manageAdReducer,
	propertyDetails: propertyDetailsReducer,
	review: reviewReducer,
	search: searchReducer,
	explore: exploreReducer,
	book: bookingReducer,
	bookingData: bookingDataReducer,
	payment: paymentReducer,
	modal: modalReducer,
	range: rangeReducer,
	availability: availabilityReducer,
	hostProfile: hostProfileReducer,
});

export default persistReducer(persistConfig, rootReducer);
