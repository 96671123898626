import React, { useEffect } from 'react';
import { HorizontalLine, Paragraph } from '../../../globals/appGlobal';
import { DropDownCard, ProfileDropdownWrapper } from './profileDropdownStyles';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { signOutStart } from '../../../redux/user/user.actions';
import { createStructuredSelector } from 'reselect';
import {
	selectError,
	selectLoadingUser,
} from '../../../redux/user/user.selectors';
import { auth } from '../../../firebase/firebase.config';

const ProfileDropdown = ({
	closeDropdown,
	showProfileDropdown,
	signOutStart,
	path,
}) => {
	const isGoogle =
		auth.currentUser?.providerData[0].providerId === 'google.com';

	const handleSignOut = () => {
		signOutStart();
		closeDropdown();
	};

	const media = window.matchMedia('(min-width: 992px)');

	useEffect(() => {
		const dropProfile = document.querySelector('#drop-profile-wrapper');
		media.matches
			? dropProfile && window.pageYOffset < '50' && path === '/'
				? (dropProfile.style.top = '110px')
				: (dropProfile.style.top = '70px')
			: (dropProfile.style.top = 'initial');
	}, [showProfileDropdown, path, media]);

	return (
		<ProfileDropdownWrapper
			id='drop-profile-wrapper'
			showProfileDropdown={showProfileDropdown}
		>
			<DropDownCard className='drop-profile'>
				<ul>
					<Link to='/dashboard/booked' onClick={closeDropdown}>
						<li>
							<Paragraph>Booked</Paragraph>
						</li>
					</Link>
					<Link to='/dashboard/saved' onClick={closeDropdown}>
						<li>
							<Paragraph>Saved</Paragraph>
						</li>
					</Link>
					<HorizontalLine className='hr' />
					<Link to='/dashboard/properties' onClick={closeDropdown}>
						<li>
							<Paragraph>Manage Properties</Paragraph>
						</li>
					</Link>
					<Link to='/dashboard/booked-hosted' onClick={closeDropdown}>
						<li>
							<Paragraph>Booked hosted properties</Paragraph>
						</li>
					</Link>
					<HorizontalLine className='hr' />
					{!isGoogle && (
						<>
							<Link to='/edit-profile' onClick={closeDropdown}>
								<li>
									<Paragraph>Edit profile</Paragraph>
								</li>
							</Link>
							<HorizontalLine className='hr' />
						</>
					)}
					<li onClick={handleSignOut}>
						<Paragraph style={{ marginBottom: 0 }}>
							Logout
						</Paragraph>
					</li>
				</ul>
			</DropDownCard>
		</ProfileDropdownWrapper>
	);
};

const mapStateToProps = createStructuredSelector({
	error: selectError,
	loadingUser: selectLoadingUser,
});

const mapDispatchToProps = dispatch => ({
	signOutStart: () => dispatch(signOutStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDropdown);
