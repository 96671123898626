import { useHistory } from 'react-router-dom';
import { auth } from '../firebase/firebase.config';
import { useDispatch } from 'react-redux';
import { openLogModal } from '../redux/modal/modal.actions';

export const useGlobalFunctions = () => {
	const history = useHistory();
	const goBack = () => {
		history.goBack();
	};
	const truncate = (source, size) => {
		return source.length > size ? source.slice(0, size - 1) + '…' : source;
	};

	const checkAmenity = arr => {
		let num = 0;
		arr?.forEach(arrItem => {
			arrItem.isChecked && num++;
		});
		return num;
	};

	// date calculator //
	const todayDate = new Date();

	// DATE UTILITY CHECKERS //

	const daysBetween = (firstDate, secondDate) => {
		let firstDate_ms;
		let secondDate_ms;

		// Convert both dates to milliseconds
		firstDate_ms = firstDate.getTime();
		secondDate_ms = secondDate.getTime();

		// Calculate the difference in milliseconds
		let difference_ms = secondDate_ms - firstDate_ms;
		//take out milliseconds
		difference_ms = difference_ms / 1000;
		// let seconds = Math.floor(difference_ms % 60);
		difference_ms = difference_ms / 60;
		// let minutes = Math.floor(difference_ms % 60);
		difference_ms = difference_ms / 60;
		// let hours = Math.floor(difference_ms % 24);
		// return days + ' days, ' + hours + ' hours, ' + minutes + ' minutes, and ' + seconds + ' seconds';
		return Math.floor(difference_ms / 24);
		// add one up to round up the remaining hours and minutes ...
	};

	//set hosted or pending
	const hostedChecker = (renewDate, daysInterval) => {
		renewDate.setDate(renewDate.getDate() + daysInterval);
		return renewDate >= todayDate
			? {
					status: 'Hosted',
					daysLeft: daysBetween(todayDate, renewDate) + 1,
			  }
			: { status: 'Pending', daysLeft: 0 };
	};

	// check free_four promo
	const checkFreePromo = (propertyDate, endDate, promoDays) => {
		let daysLeft;
		// get day for upload date
		const upload_day = propertyDate.getDate();

		// convert property upload date to time (milliseconds)
		const propertyDate_ms = propertyDate.getTime();

		// convert endDate into time (milliseconds)
		const endDate_ms = endDate.getTime();

		// check if promo is still ongoing
		if (propertyDate_ms <= endDate_ms) {
			// find diff between today date and uploaded date
			let diffDate = daysBetween(propertyDate, todayDate);

			// check if the number of days the promo is supposed to last has been exceeded
			if (diffDate < promoDays) {
				propertyDate.setDate(upload_day + promoDays);
				let daysDiff = daysBetween(todayDate, propertyDate);
				daysLeft = daysDiff + 1;
				return { status: 'Hosted', daysLeft: daysLeft };
			} else {
				return { status: 'Pending', daysLeft: 0 };
			}
		} else {
			return { status: 'Pending', daysLeft: 0 };
		}
	};

	// MAIN DATE FUNCTION
	const dateChecker = (property_date, subscription_type, renew_date) => {
		let daysInterval;

		switch (subscription_type.toLowerCase()) {
			case 'free_four':
				daysInterval = 124;
				return checkFreePromo(property_date, renew_date, daysInterval);
			case 'one_month':
				daysInterval = 31;
				return hostedChecker(renew_date, daysInterval);
			case 'three_months':
				daysInterval = 93;
				return hostedChecker(renew_date, daysInterval);
			case 'six_months':
				daysInterval = 186;
				return hostedChecker(renew_date, daysInterval);
			default:
				return '';
		}
	};

	//check redirect
	const dispatch = useDispatch();
	const checkLoginRedirect = direction => {
		!!auth.currentUser ? history.push(direction) : dispatch(openLogModal());
	};

	const checkLoginPopup = func => {
		!!auth.currentUser ? func() : dispatch(openLogModal());
	};

	const setNavUnderLines = linkId => {
		const about = document.querySelector('#about-nav-link');
		const signIn = document.querySelector('#signin-nav-link');
		const host = document.querySelector('#host-nav-link');
		about?.classList.remove('active');
		signIn?.classList.remove('active');
		host?.classList.remove('active');

		linkId && document.querySelector(`#${linkId}`)?.classList.add('active');
	};

	// BANK DETAILS FUNCTIONS //

	// handle bank change
	const handleBankChange = (e, setValues, banks) => {
		const { value } = e.target;
		value === 'select'
			? setValues(e, 'select')
			: banks.forEach(bank => {
					bank.name === value && setValues(e, bank.code);
			  });
	};

	// loop banks for select box
	const loopBanks = banks => {
		const bankArray = [];
		banks.forEach(bank => {
			if (
				bank.name === 'MTN' ||
				bank.name === 'Vodafone' ||
				bank.name === 'AirtelTigo'
			) {
				bankArray.push(bank.name);
			}
		});
		return bankArray;
	};

	const haltHtmlScroll = () => {
		const html = document.querySelector('html');
		html.classList.add('halt-scroll');
	};

	const removeHaltHtmlScroll = () => {
		const html = document.querySelector('html');
		html.classList.remove('halt-scroll');
	};

	const isNumberKey = evt => {
		const charCode = evt.which ? evt.which : evt.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57))
			evt.preventDefault();
	};

	const dateConverter = date => {
		return (
			new Date(date).toLocaleDateString('fr-CA', {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
			}) +
			' ' +
			new Date(date).toLocaleTimeString() +
			' GMT'
		);
	};
	return {
		goBack,
		truncate,
		checkAmenity,
		dateChecker,
		checkLoginRedirect,
		checkLoginPopup,
		setNavUnderLines,
		handleBankChange,
		loopBanks,
		haltHtmlScroll,
		removeHaltHtmlScroll,
		isNumberKey,
		dateConverter,
	};
};
