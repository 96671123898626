import styled from 'styled-components';
import { Flex } from '../../globals/appGlobal';

export const ErrorWrapper = styled(Flex)`
	word-break: break-word;
	color: red;
	margin-bottom: 20px;
	svg {
		margin: 2px 10px 0 0;
	}
`;
