import { takeLatest, put, all, call } from 'redux-saga/effects';
import UserActionTypes from './user.types';
import {
	signUpSuccess,
	signUpFailure,
	signInSuccess,
	signInFailure,
	signOutSuccess,
	signOutFailure,
	passwordResetSuccess,
	passwordResetFailure,
	sendVerificationStart,
	sendVerificationSuccess,
	sendVerificationFailure,
	deleteUserAccountFailure,
	deleteUserAccountSuccess,
	editUserSuccess,
	editUserFailure,
	deleteProfileImageStart,
	deleteProfileImageSuccess,
	deleteProfileImageFailure,
	updateProfileImagesFailure,
	updateProfileImagesSuccess,
	updateProfileImagesStart,
} from './user.actions';
import {
	auth,
	firestore,
	googleProvider,
	storage,
	functions,
} from '../../firebase/firebase.config';
import {
	createUserProfileDocument,
	getCurrentUser,
} from '../../firebase/firebase.helpers';
import { setSavedAds } from '../save-ad/save-ad.actions';
import { setGuestBooked } from '../booking-data/booking-data.actions';

//  Utility functions
function* getSnapshotFromUser(userAuth, additionalData) {
	try {
		const userRef = yield call(
			createUserProfileDocument,
			userAuth,
			additionalData,
		);
		const userSnapshot = yield userRef.get();
		yield put(
			signInSuccess({
				id: userSnapshot.id,
				...userSnapshot.data(),
			}),
		);
		yield put(setSavedAds(userSnapshot.data().saved));
		yield put(setGuestBooked(userSnapshot.data().booked));
	} catch (error) {
		yield put(signInFailure(error.message));
	}
}

//  Sign Up
function* signUp({ payload }) {
	try {
		let downloadUrl = '';
		const { user } = yield auth.createUserWithEmailAndPassword(
			payload.email,
			payload.password,
		);
		const storageRef = storage.ref(
			`profile-images/${Date.now()}_${payload.displayName}`,
		);
		if (typeof payload.profile_img === 'object') {
			const request = yield storageRef.put(payload.profile_img);
			downloadUrl = yield request.ref.getDownloadURL();
		} else {
			const editedProfileString = payload.profile_img.replace(
				'data:image/png;base64,',
				'',
			);
			const request = yield storageRef.putString(
				editedProfileString,
				'base64',
			);
			downloadUrl = yield request.ref.getDownloadURL();
		}

		delete payload.password;
		delete payload.confirmPassword;

		yield auth.currentUser.updateProfile({
			photoURL: downloadUrl,
			displayName: payload.displayName,
		});

		yield put(
			signUpSuccess({
				user,
				additionalData: { ...payload, profile_img: downloadUrl },
				signUpSuccess: 'Signed up',
			}),
		);
	} catch (error) {
		yield put(signUpFailure(error.message));
	}
}

function* signInAfterSignUp({ payload: { user, additionalData } }) {
	yield getSnapshotFromUser(user, additionalData);
	if (!auth.currentUser.emailVerified) {
		yield put(sendVerificationStart(auth.currentUser));
	}

	const addToMailChimp = functions.httpsCallable('addToMailChimp');
	yield addToMailChimp({
		email: additionalData.email,
		displayName: additionalData.displayName,
	});
}

function* onSignUpStart() {
	yield takeLatest(UserActionTypes.SIGN_UP_START, signUp);
}

function* onSignUpSuccess() {
	yield takeLatest(UserActionTypes.SIGN_UP_SUCCESS, signInAfterSignUp);
}

//  Send verification email
function* sendVerificationEmail({ payload }) {
	try {
		yield payload.sendEmailVerification();
		yield put(sendVerificationSuccess());
	} catch (error) {
		yield put(sendVerificationFailure(error));
	}
}

function* onSendVerificationStart() {
	yield takeLatest(
		UserActionTypes.SEND_VERIFICATION_START,
		sendVerificationEmail,
	);
}

//  Check User Session
function* isUserAuthenticated() {
	try {
		const userAuth = yield getCurrentUser();
		if (!userAuth) return;
		yield getSnapshotFromUser(userAuth);
	} catch (error) {
		yield put(signInFailure(error.message));
	}
}

function* onCheckUserSession() {
	yield takeLatest(UserActionTypes.CHECK_USER_SESSION, isUserAuthenticated);
}

//  Sign In With Google
function* signInWithGoogle() {
	try {
		const { user } = yield auth.signInWithPopup(googleProvider);
		yield getSnapshotFromUser(user);
	} catch (error) {
		yield put(signInFailure(error.message));
	}
}

function* onGoogleSignInStart() {
	yield takeLatest(UserActionTypes.GOOGLE_SIGN_IN_START, signInWithGoogle);
}

//  Sign In WIth Email
function* signInWithEmail({ payload: { email, password } }) {
	try {
		const { user } = yield auth.signInWithEmailAndPassword(email, password);
		yield getSnapshotFromUser(user);
	} catch (error) {
		yield put(signInFailure(error.message));
	}
}

function* onEmailSignInStart() {
	yield takeLatest(UserActionTypes.EMAIL_SIGN_IN_START, signInWithEmail);
}

//  Sign Out
function* signOut() {
	try {
		yield auth.signOut();
		yield put(signOutSuccess());
	} catch (error) {
		yield put(signOutFailure(error.message));
	}
}

function* onSignOutStart() {
	yield takeLatest(UserActionTypes.SIGN_OUT_START, signOut);
}

//  Reset password
function* resetPassword({ payload }) {
	try {
		yield auth.sendPasswordResetEmail(payload);
		yield put(
			passwordResetSuccess(
				`A link to help reset your password has been sent to ${payload}`,
			),
		);
	} catch (error) {
		yield put(passwordResetFailure(error.message));
	}
}

function* onResetPasswordStart() {
	yield takeLatest(UserActionTypes.PASSWORD_RESET_START, resetPassword);
}

//  Edit User
function* editUser({ payload: { userDetails, user } }) {
	try {
		let downloadUrl = '';
		const {
			profile_img,
			displayName,
			email,
			password,
			oldPassword,
		} = userDetails;

		//  if image changed
		if (profile_img !== user.photoURL) {
			const storageRef = storage.ref(
				`profile-images/${Date.now()}_${displayName}`,
			);
			if (typeof profile_img === 'object') {
				const uploadTask = yield storageRef.put(profile_img);
				downloadUrl = yield uploadTask.ref.getDownloadURL();
				// yield put(deleteProfileImageStart(user.photoURL));
			} else if (profile_img) {
				const uploadTask = yield storageRef.putString(
					profile_img,
					'base64',
				);
				downloadUrl = yield uploadTask.ref.getDownloadURL();
				// yield put(deleteProfileImageStart(user.photoURL));
			}
			yield auth.currentUser.updateProfile({
				photoURL: downloadUrl ? downloadUrl : user.photoURL,
			});
		}

		//  if email changed
		if (email !== user.email) {
			yield auth.signInWithEmailAndPassword(user.email, oldPassword);
			yield auth.currentUser.updateEmail(email);
			if (!auth.currentUser.emailVerified) {
				yield put(sendVerificationStart(auth.currentUser));
			}
		}

		//  if password changed
		if (password) {
			yield auth.signInWithEmailAndPassword(user.email, oldPassword);
			yield auth.currentUser.updatePassword(password);
		}

		if (displayName !== user.displayName) {
			yield auth.currentUser.updateProfile({
				displayName,
			});
		}

		// store updated information in firestore
		yield firestore
			.collection('users')
			.doc(user.uid)
			.update({
				displayName,
				email,
				profile_img: downloadUrl ? downloadUrl : user.photoURL,
			});

		yield put(editUserSuccess('Profile updated successfully'));
		if (downloadUrl) {
			yield put(
				updateProfileImagesStart({
					id: user.uid,
					downloadUrl: user.photoURL,
				}),
			);
		}
	} catch (error) {
		yield put(editUserFailure(error.message));
	}
}

function* onEditUserStart() {
	yield takeLatest(UserActionTypes.EDIT_USER_PROFILE_START, editUser);
}

//  Update Urls
function* updateProfileImagesUrls({ payload: { id, downloadUrl } }) {
	try {
		const batch = firestore.batch();
		const propertiesRef = firestore.collection('properties');
		const snapshot = yield propertiesRef.where('user_id', '==', id).get();
		for (let doc of snapshot.docs) {
			const propertyRef = propertiesRef.doc(doc.id);
			batch.update(propertyRef, { profile_img: downloadUrl });
		}
		yield batch.commit();
		yield put(updateProfileImagesSuccess('Urls changed successfully'));
	} catch (error) {
		yield put(updateProfileImagesFailure(error.message));
	}
}

function* onUpdateUrls() {
	yield takeLatest(
		UserActionTypes.UPDATE_PROPERTY_PROFILE_IMAGES_START,
		updateProfileImagesUrls,
	);
}

//  Delete profile image
function* deleteProfileImage({ payload }) {
	try {
		const storageRef = storage.refFromURL(payload);
		yield storageRef.delete();
		yield put(deleteProfileImageSuccess('Profile image deleted'));
	} catch (error) {
		yield put(deleteProfileImageFailure(error.message));
	}
}

function* onDeleteProfileImageStart() {
	yield takeLatest(
		UserActionTypes.DELETE_USER_PROFILE_IMAGE_START,
		deleteProfileImage,
	);
}

//  Delete user account
function* deleteUserAccount({ payload: { user, password } }) {
	try {
		yield auth.signInWithEmailAndPassword(user.email, password);
		yield put(deleteProfileImageStart(user.photoURL));
		yield user.delete();
		yield put(deleteUserAccountSuccess('User account deleted'));
	} catch (error) {
		yield put(deleteUserAccountFailure(error.message));
	}
}

function* onDeleteUserAccountStart() {
	yield takeLatest(
		UserActionTypes.DELETE_USER_ACCOUNT_START,
		deleteUserAccount,
	);
}

export function* userSagas() {
	yield all([
		call(onSignUpStart),
		call(onSignUpSuccess),
		call(onCheckUserSession),
		call(onSignOutStart),
		call(onGoogleSignInStart),
		call(onEmailSignInStart),
		call(onResetPasswordStart),
		call(onSendVerificationStart),
		call(onDeleteUserAccountStart),
		call(onDeleteProfileImageStart),
		call(onEditUserStart),
		call(onUpdateUrls),
	]);
}
