import React, { useContext, useEffect } from 'react';
import { NavDiv, NavItem, PhoneHeaderWrapper } from './phoneHeaderStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faSearch,
	faHeart,
	faHome,
	faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { EdanraLogo } from '../../../assets/svg/allSvg';
import { Flex, ProfileAvatar, Small } from '../../../globals/appGlobal';
import ProfileDropdown from '../profile-dropdown/profileDropdown';
import { useProfileDropdownFunctions } from '../../../custom-hooks/profileDropdownFunctions';
import { AuthContext } from '../../../contexts/authIsLoaded';
import { toggleLogModal } from '../../../redux/modal/modal.actions';
import { connect } from 'react-redux';
import { useGlobalFunctions } from '../../../custom-hooks/globalFunctions';

const PhoneHeader = ({ toggleLogModal }) => {
	// use dropdown-hook
	const {
		showProfileDropdown,
		toggleDropDown,
		closeDropdown,
		hideDropDownOnClick,
	} = useProfileDropdownFunctions();

	hideDropDownOnClick('drop-profile', closeDropdown);

	// import user from context
	const { currentUser } = useContext(AuthContext);

	const { haltHtmlScroll, removeHaltHtmlScroll } = useGlobalFunctions();

	useEffect(() => {
		showProfileDropdown ? haltHtmlScroll() : removeHaltHtmlScroll();
		// eslint-disable-next-line
	}, [showProfileDropdown]);

	return (
		<>
			<PhoneHeaderWrapper id='phone-header'>
				<NavItem to='/' exact>
					<Flex vertical>
						<EdanraLogo />
						<Small>Home</Small>
					</Flex>
				</NavItem>
				<NavItem to='/search'>
					<Flex vertical>
						<FontAwesomeIcon icon={faSearch} />
						<Small>Search</Small>
					</Flex>
				</NavItem>
				{currentUser && (
					<NavItem to='/dashboard/saved'>
						<Flex vertical>
							<FontAwesomeIcon icon={faHeart} />
							<Small>Saved</Small>
						</Flex>
					</NavItem>
				)}
				{currentUser && (
					<NavItem to='/host-property'>
						<Flex vertical>
							<FontAwesomeIcon icon={faHome} />
							<Small>Host</Small>
						</Flex>
					</NavItem>
				)}
				{currentUser ? (
					<NavDiv onClick={toggleDropDown} className='drop-profile'>
						<Flex vertical className='drop-profile'>
							<ProfileAvatar
								src={currentUser.photoURL}
								size='37px'
								className='drop-profile'
							/>
							<Small className='drop-profile'>Profile</Small>
						</Flex>
					</NavDiv>
				) : (
					<NavDiv onClick={toggleLogModal}>
						<Flex vertical>
							<FontAwesomeIcon icon={faUserCircle} />
							<Small>Login</Small>
						</Flex>
					</NavDiv>
				)}
			</PhoneHeaderWrapper>
			<ProfileDropdown
				closeDropdown={closeDropdown}
				showProfileDropdown={showProfileDropdown}
			/>
		</>
	);
};

const mapDispatchToProps = dispatch => ({
	toggleLogModal: () => dispatch(toggleLogModal()),
});

export default connect(null, mapDispatchToProps)(PhoneHeader);
