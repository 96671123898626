const PropertyDetailsTypes = {
	FETCH_PROPERTY_DETAILS_START: 'FETCH_PROPERTY_DETAILS_START',
	FETCH_PROPERTY_DETAILS_SUCCESS: 'FETCH_PROPERTY_DETAILS_SUCCESS',
	FETCH_PROPERTY_DETAILS_FAILURE: 'FETCH_PROPERTY_DETAILS_FAILURE',
	CLEAR_CURRENT_PROPERTY: 'CLEAR_CURRENT_PROPERTY',
	CLEAR_SIMILAR_PROPERTIES: 'CLEAR_SIMILAR_PROPERTIES',
	FETCH_SIMILAR_PROPERTIES_START: 'FETCH_SIMILAR_PROPERTIES_START',
	FETCH_SIMILAR_PROPERTIES_SUCCESS: 'FETCH_SIMILAR_PROPERTIES_SUCCESS',
	FETCH_SIMILAR_PROPERTIES_FAILURE: 'FETCH_SIMILAR_PROPERTIES_FAILURE',
	GET_USER_DATA: 'GET_USER_DATA',
	SET_PROPERTY: 'SET_PROPERTY',
};

export default PropertyDetailsTypes;
