import React, { Component } from 'react';
import { Container } from '../../globals/appGlobal';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../../utils/scrollToTop';
import ErrorBoundaryMain from './error-boundary-main';

class ErrorBoundary extends Component {
	constructor() {
		super();

		this.state = {
			hasErrored: false,
		};
	}

	static getDerivedStateFromError(error) {
		return {
			hasErrored: true,
		};
	}

	componentDidCatch(error, errorInfo) {
		console.log(error);
	}

	render() {
		if (this.state.hasErrored) {
			return (
				<Container>
					<Helmet>
						<title>Something went wrong - Edanra</title>
					</Helmet>
					<ScrollToTop />
					<ErrorBoundaryMain />
				</Container>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
