import styled from 'styled-components';
import { ShadowCard } from '../../globals/appGlobal';
import { palette } from '../../utils';

export const MessageBoxContainer = styled(ShadowCard)`
	border-radius: 8px;
	border: 2px solid
		${({ type }) =>
			type === 'success'
				? palette.green
				: type === 'error'
				? palette.red
				: 'black'};
	position: fixed;
	bottom: -50px;
	left: 50%;
	transform: translateX(-50%);
	background: white;
	z-index: 1;
	padding: 0;
	max-width: ${({ width }) => (width ? width : '350px')};

	p {
		margin: 0;
	}

	svg {
		margin-right: 10px;
		font-size: 2rem;
		color: ${({ type }) =>
			type === 'success'
				? palette.green
				: type === 'error'
				? palette.red
				: 'black'};
	}

	#message-box-close {
		padding: 25px 25px 20px 25px;
		background: ${palette.lightestGrey};
		svg {
			font-size: 1.2rem;
			margin: 0;
			cursor: pointer;
		}
	}

	#message-box-message {
		padding: 25px;
	}

	@media only screen and (max-width: 900px) {
		width: calc(100% - 10vw);
		bottom: 0;
	}
`;
