const ReviewTypes = {
	POST_REVIEW_START: 'POST_REVIEW_START',
	POST_REVIEW_SUCCESS: 'POST_REVIEW_SUCCESS',
	POST_REVIEW_FAILURE: 'POST_REVIEW_FAILURE',
	REQUEST_PAYMENT_START: 'REQUEST_PAYMENT_START',
	REQUEST_PAYMENT_SUCCESS: 'REQUEST_PAYMENT_SUCCESS',
	REQUEST_PAYMENT_FAILURE: 'REQUEST_PAYMENT_FAILURE',
	CLEAR_BOOKING_START: 'CLEAR_BOOKING_START',
	CLEAR_BOOKING_SUCCESS: 'CLEAR_BOOKING_SUCCESS',
	CLEAR_BOOKING_FAILURE: 'CLEAR_BOOKING_FAILURE',
	CLEAR_REVIEW_SUCCESS: 'CLEAR_REVIEW_SUCCESS',
	CLEAR_REVIEW_ERROR: 'CLEAR_REVIEW_ERROR',
	REMOVE_CLEAR_BOOKING_STATUS: 'REMOVE_CLEAR_BOOKING_STATUS',
	CLEAR_TRANSFER_STATUS: 'CLEAR_TRANSFER_STATUS',
};

export default ReviewTypes;
