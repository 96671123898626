import modalTypes from './modal.types';

const INITIAL_STATE = {
	showModal: false,
	showSignUp: false,
	showSignIn: false,
};

const modalReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case modalTypes.OPEN_LOG_MODAL:
			return {
				...state,
				showModal: true,
				showSignIn: true,
				showSignUp: false,
			};
		case modalTypes.CLOSE_LOG_MODAL:
			return {
				...state,
				showModal: false,
			};
		case modalTypes.TOGGLE_LOG_MODAL:
			return {
				...state,
				showModal: !state.showModal,
			};
		case modalTypes.SHOW_SIGN_UP_ONLY:
			return {
				...state,
				showSignUp: true,
				showSignIn: false,
			};
		case modalTypes.SHOW_SIGN_IN_ONLY:
			return {
				...state,
				showSignIn: true,
				showSignUp: false,
			};
		default:
			return state;
	}
};

export default modalReducer;
