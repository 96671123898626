import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	Brand,
	HeaderLink,
	HeaderText,
	HeaderWrapper,
	Nav,
	SearchButton,
	UserButton,
} from './desktopHeaderStyles';
import Logo from '../../../assets/images/edanra.png';
import {
	Container,
	HeaderFive,
	ProfileAvatar,
} from '../../../globals/appGlobal';
import '../../../index.css';
import { Search } from '../../../assets/svg/allSvg';
import Button from '../../button/button';
import { useLocation } from 'react-router-dom';
import SearchBar from '../../search-bar/searchBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';
import ProfileDropdown from '../profile-dropdown/profileDropdown';
import { useProfileDropdownFunctions } from '../../../custom-hooks/profileDropdownFunctions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../../redux/user/user.selectors';
import { AuthContext } from '../../../contexts/authIsLoaded';
import { openLogModal } from '../../../redux/modal/modal.actions';
import { useGlobalFunctions } from '../../../custom-hooks/globalFunctions';

const DesktopHeader = ({ openLogModal }) => {
	const { currentUser } = useContext(AuthContext);

	// use dropdown-hook
	const {
		showProfileDropdown,
		toggleDropDown,
		closeDropdown,
		hideDropDownOnClick,
	} = useProfileDropdownFunctions();

	const searchRef = useRef(null);
	const location = useLocation();
	const headerRef = useRef(null);

	//states to control search bar
	const [searchBar, setSearchBar] = useState(false);

	const searchButtonClick = () => {
		setSearchBar(!searchBar);
	};

	// for search animation
	const showHideSearchButton = useCallback(() => {
		if (
			location.pathname === '/' &&
			headerRef.current &&
			searchRef.current
		) {
			if (window.pageYOffset > '288') {
				searchRef.current.classList.add('show-search');
			} else {
				searchRef.current.classList.remove('show-search');
				setSearchBar(false);
			}
		} else {
			searchRef.current.classList.add('show-search');
		}
	}, [location.pathname]);

	// for safety-bar bar
	// for drop down

	const profileDropDownRef = document.querySelector('#drop-profile-wrapper');

	window.onscroll = () => {
		if (headerRef.current) {
			if (window.pageYOffset > '15') {
				headerRef.current.classList.add('shadow');
			} else {
				headerRef.current.classList.remove('shadow');
			}
			showHideSearchButton();
		}
		setSearchBar(false);

		if (profileDropDownRef) {
			if (location.pathname === '/' && window.pageYOffset > '50') {
				profileDropDownRef.style.top = '70px';
			} else if (location.pathname === '/' && window.pageYOffset < '50') {
				profileDropDownRef.style.top = '110px';
			} else {
				profileDropDownRef.style.top = '70px';
			}
		}
	};

	useEffect(() => {
		headerRef.current && showHideSearchButton();
	}, [location.pathname, showHideSearchButton]);

	useEffect(() => {
		if (location.pathname === '/' && headerRef.current) {
			headerRef.current.classList.add('sticky-nav');
		} else {
			headerRef.current.classList.remove('sticky-nav');
		}
	}, [location.pathname]);

	hideDropDownOnClick('drop-profile', closeDropdown);

	//check if user has logged in to take to the host property page
	const { checkLoginRedirect } = useGlobalFunctions();

	const x = window.matchMedia('(min-width: 1408px)');

	return (
		<>
			<HeaderWrapper ref={headerRef}>
				<Container>
					<Brand to='/'>
						<img src={Logo} alt='edanra logo' />
						{x.matches && <HeaderFive>edanra</HeaderFive>}
					</Brand>
					<SearchButton ref={searchRef} onClick={searchButtonClick}>
						Find a home
						<Button aria='search icon' circular icon={<Search />} />
					</SearchButton>
					<Nav>
						<HeaderLink to='/about' id='about-nav-link'>
							About
						</HeaderLink>
						<HeaderLink to='/edanra-nss' id='nss-nav-link'>
							edanra nss
						</HeaderLink>
						{!currentUser && (
							<HeaderText
								onClick={openLogModal}
								id='signin-nav-link'
							>
								Sign in
							</HeaderText>
						)}
						<HeaderLink
							to={'/host-property'}
							id='host-nav-link'
							onClick={() => checkLoginRedirect('/host-property')}
						>
							Host property
						</HeaderLink>
						{!!currentUser && (
							<UserButton
								onClick={toggleDropDown}
								className='drop-profile'
							>
								<ProfileAvatar
									src={currentUser.photoURL}
									size='2.4rem'
								/>
								<FontAwesomeIcon
									icon={faChevronCircleDown}
									className='drop-profile'
								/>
							</UserButton>
						)}
					</Nav>
				</Container>
			</HeaderWrapper>
			<SearchBar show={searchBar} setShow={setSearchBar} />
			{showProfileDropdown && (
				<ProfileDropdown
					closeDropdown={closeDropdown}
					path={location.pathname}
				/>
			)}
		</>
	);
};

const mapStateToProps = createStructuredSelector({
	currentUser: selectCurrentUser,
});

const mapDispatchToProps = dispatch => ({
	openLogModal: () => dispatch(openLogModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DesktopHeader);
