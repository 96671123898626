import { useState } from 'react';
import {
	checkImagesLength,
	errorObject,
	validateConfirmPassword,
	validateContact,
	validateDescription,
	validateEMail,
	validateName,
	validateNameStrict,
	validateNumber,
	validatePassword,
	validateSelection,
	validateSpecialPassword,
} from '../utils/validation';

export const useValidationFunctions = id => {
	//validation
	const [val, setVal] = useState({
		[`${id}Error`]: '',
	});

	const setError = (val, setVal) => {
		let error = errorObject.error;
		let message = errorObject.message;
		setVal({ ...val, [error]: message });
	};

	const validateMail = (e, val, setVal) => {
		const error = e.target.id + 'Error';
		validateEMail(e.target, error);
		setError(val, setVal);
	};

	const validatePass = (e, val, setVal) => {
		const error = e.target.id + 'Error';
		validatePassword(e.target, error);
		setError(val, setVal);
	};

	const validatePhone = (e, val, setVal) => {
		const error = e.target.id + 'Error';
		validateContact(e.target, error);
		setError(val, setVal);
	};

	const validateText = (e, val, setVal, special, domElement) => {
		const element = domElement ? domElement : e.target;
		const error = element.id + 'Error';
		special === 'raw' ||
		special === 'confirm-password' ||
		special === 'special-password' ||
		element.special === 'raw'
			? validateName(element, error)
			: validateNameStrict(element, error);
		setError(val, setVal);
	};

	const validateSpecialPass = (e, val, setVal) => {
		const error = e.target.id + 'Error';
		validateSpecialPassword(e.target, error);
		setError(val, setVal);
	};

	const validateConfirmPass = (e, val, setVal) => {
		const error = e.target.id + 'Error';
		validateConfirmPassword(e.target, error);
		setError(val, setVal);
	};

	const validateSelect = (e, val, setVal) => {
		const error = e.target.id + 'Error';
		validateSelection(e.target, error);
		setError(val, setVal);
	};

	const validateNumberInput = (e, val, setVal) => {
		const error = e.target.id + 'Error';
		validateNumber(e.target, error);
		setError(val, setVal);
	};

	const validateDescribe = (e, val, setVal) => {
		const error = e.target.id + 'Error';
		validateDescription(e.target, error);
		setError(val, setVal);
	};

	const validateChecker = (e, val, setVal, special, domElement) => {
		const element = domElement ? domElement : e.target;
		element.type === 'email' && validateMail(e, val, setVal);
		element.type === 'text' &&
			special !== 'special-password' &&
			validateText(e, val, setVal, special, domElement);
		element.type === 'text' &&
			special === 'special-password' &&
			validateSpecialPass(e, val, setVal, special);
		if (element.type === 'textarea') {
			switch (element.name) {
				case 'description':
					validateDescribe(e, val, setVal);
					break;
				default:
					validateText(e, val, setVal, 'raw');
			}
		}

		element.type === 'tel' && validatePhone(e, val, setVal, special);
		element.type === 'select-one' && validateSelect(e, val, setVal);
		element.type === 'number' && validateNumberInput(e, val, setVal);
		if (element.type === 'password') {
			switch (special) {
				case 'special-password':
					validateSpecialPass(e, val, setVal);
					break;
				case 'confirm-password':
					validateConfirmPass(e, val, setVal);
					break;
				default:
					validatePass(e, val, setVal);
			}
		}
	};

	const blurFunc = (e, val, setVal, special, domElement) => {
		validateChecker(e, val, setVal, special, domElement);
	};

	const changeFunc = (e, val, setVal, special, domElement) => {
		validateChecker(e, val, setVal, special, domElement);
	};

	const checkAmenitiesError = (amenitiesNum, setAmenityError, amenityId) => {
		if (amenitiesNum < 1) {
			const focusElement = document.querySelector(`#${amenityId}`);
			setAmenityError('Please select at least one amenity');
			focusElement && focusElement.focus();
			return false;
		} else {
			setAmenityError('');
			return true;
		}
	};

	// function for when a particular validation should be ignored for edanra stay properties
	// but included for regular ads
	const ignoreEdanraValidation = (index, edanra, validationFunc, e) => {
		return edanra
			? true
			: validationFunc(
					e.target[!edanra && index],
					e.target[!edanra && index].id + 'Error',
					focusSetter,
			  );
	};

	// function for when a particular validation should be ignored for regular ads properties
	// but included for edanra stay properties
	const ignoreRegularValidation = (index, edanra, validationFunc, e) => {
		return !edanra
			? true
			: validationFunc(
					e.target[edanra && index],
					e.target[edanra && index].id + 'Error',
					focusSetter,
			  );
	};

	//focus setter
	const focusSetter = true;

	// MAIN VALIDATORS
	const validateSignIn = e => {
		return (
			validateEMail(e.target[0], e.target[0].id + 'Error', focusSetter) &&
			validatePassword(e.target[1], e.target[1].id + 'Error', focusSetter)
		);
	};

	const validateSignUp = e => {
		let num = 3;
		!(e.target[3].type === 'password' || e.target[3].type === 'text') &&
			num++;
		return (
			validateName(e.target[0], e.target[0].id + 'Error', focusSetter) &&
			validateEMail(e.target[1], e.target[1].id + 'Error', focusSetter) &&
			validateSpecialPassword(
				e.target[num],
				e.target[num].id + 'Error',
				focusSetter,
			) &&
			validateConfirmPassword(
				e.target[num + 1],
				e.target[num + 1].id + 'Error',
				focusSetter,
			)
		);
	};

	const validateSearch = e => {
		return validateSelection(
			e.target[1],
			e.target[1].id + 'Error',
			focusSetter,
		);
	};

	const validateHostDetails = e => {
		return (
			validateNameStrict(
				e.target[0],
				e.target[0].id + 'Error',
				focusSetter,
			) &&
			validateContact(
				e.target[1],
				e.target[1].id + 'Error',
				focusSetter,
			) &&
			validateEMail(e.target[2], e.target[2].id + 'Error', focusSetter) &&
			validateName(e.target[3], e.target[3].id + 'Error', focusSetter)
		);
	};

	const validateHostBookDetails = e => {
		return (
			validateSelection(
				e.target[0],
				e.target[0].id + 'Error',
				focusSetter,
			) &&
			validateName(e.target[1], e.target[1].id + 'Error', focusSetter)
		);
	};

	const validatePropertyDetails = (e, propertyClass) => {
		return propertyClass === 'Edanra Stay'
			? validateSelection(
					e.target[0],
					e.target[0].id + 'Error',
					focusSetter,
			  ) &&
					validateSelection(
						e.target[1],
						e.target[1].id + 'Error',
						focusSetter,
					) &&
					validateNumber(
						e.target[2],
						e.target[2].id + 'Error',
						focusSetter,
					) &&
					validateSelection(
						e.target[3],
						e.target[3].id + 'Error',
						focusSetter,
					) &&
					validateName(
						e.target[4],
						e.target[4].id + 'Error',
						focusSetter,
					) &&
					validateName(
						e.target[5],
						e.target[5].id + 'Error',
						focusSetter,
					)
			: validateSelection(
					e.target[0],
					e.target[0].id + 'Error',
					focusSetter,
			  ) &&
					validateSelection(
						e.target[1],
						e.target[1].id + 'Error',
						focusSetter,
					) &&
					validateNumber(
						e.target[2],
						e.target[2].id + 'Error',
						focusSetter,
					) &&
					validateSelection(
						e.target[3],
						e.target[3].id + 'Error',
						focusSetter,
					) &&
					validateName(
						e.target[4],
						e.target[4].id + 'Error',
						focusSetter,
					);
	};

	const validatePropertyLook = (e, len, imageError) => {
		return (
			validateDescription(
				e.target[0],
				e.target[0].id + 'Error',
				focusSetter,
			) && checkImagesLength(len, 4, imageError)
		);
	};

	const validatePropertyStatus = e => {
		return (
			validateNumber(
				e.target[0],
				e.target[0].id + 'Error',
				focusSetter,
			) &&
			validateSelection(
				e.target[1],
				e.target[1].id + 'Error',
				focusSetter,
			) &&
			validateSelection(
				e.target[2],
				e.target[2].id + 'Error',
				focusSetter,
			)
		);
	};

	const validateEditProfile = e => {
		return (
			validateName(e.target[0], e.target[0].id + 'Error', focusSetter) &&
			validateEMail(e.target[1], e.target[1].id + 'Error', focusSetter)
		);
	};

	const validateEditWithPass = e => {
		return (
			validateName(e.target[0], e.target[0].id + 'Error', focusSetter) &&
			validateEMail(e.target[1], e.target[1].id + 'Error', focusSetter) &&
			validatePassword(e.target[2], e.target[2].id + 'Error', focusSetter)
		);
	};

	const validateBookingDetails = e => {
		return (
			validateName(e.target[0], e.target[0].id + 'Error', focusSetter) &&
			validateEMail(e.target[1], e.target[1].id + 'Error', focusSetter) &&
			validateContact(
				e.target[2],
				e.target[2].id + 'Error',
				focusSetter,
			) &&
			validateNumber(e.target[3], e.target[3].id + 'Error', focusSetter)
		);
	};

	const validateEditListing = (e, len, imageError, edanra, isComplete) => {
		return !isComplete
			? validateNameStrict(
					e.target[0],
					e.target[0].id + 'Error',
					focusSetter,
			  ) &&
					validateContact(
						e.target[1],
						e.target[1].id + 'Error',
						focusSetter,
					) &&
					validateEMail(
						e.target[2],
						e.target[2].id + 'Error',
						focusSetter,
					) &&
					validateName(
						e.target[3],
						e.target[3].id + 'Error',
						focusSetter,
					) &&
					validateNumber(
						e.target[edanra ? 16 : 4],
						e.target[edanra ? 16 : 4].id + 'Error',
						focusSetter,
					) &&
					validateSelection(
						e.target[edanra ? 17 : 5],
						e.target[edanra ? 17 : 5].id + 'Error',
						focusSetter,
					) &&
					ignoreEdanraValidation(6, edanra, validateSelection, e) &&
					ignoreRegularValidation(18, edanra, validateSelection, e) &&
					ignoreRegularValidation(19, edanra, validateName, e) &&
					validateSelection(
						e.target[edanra ? 20 : 7],
						e.target[edanra ? 20 : 7].id + 'Error',
						focusSetter,
					) &&
					validateSelection(
						e.target[edanra ? 21 : 8],
						e.target[edanra ? 21 : 8].id + 'Error',
						focusSetter,
					) &&
					validateNumber(
						e.target[edanra ? 22 : 9],
						e.target[edanra ? 22 : 9].id + 'Error',
						focusSetter,
					) &&
					validateSelection(
						e.target[edanra ? 23 : 10],
						e.target[edanra ? 23 : 10].id + 'Error',
						focusSetter,
					) &&
					validateName(
						e.target[edanra ? 24 : 11],
						e.target[edanra ? 24 : 11].id + 'Error',
						focusSetter,
					) &&
					ignoreRegularValidation(24, edanra, validateName, e) &&
					validateDescription(
						e.target[edanra ? 26 : 13],
						e.target[edanra ? 26 : 13].id + 'Error',
						focusSetter,
					) &&
					checkImagesLength(len, 4, imageError)
			: validateNameStrict(
					e.target[0],
					e.target[0].id + 'Error',
					focusSetter,
			  ) &&
					validateContact(
						e.target[1],
						e.target[1].id + 'Error',
						focusSetter,
					) &&
					validateEMail(
						e.target[2],
						e.target[2].id + 'Error',
						focusSetter,
					) &&
					validateName(
						e.target[3],
						e.target[3].id + 'Error',
						focusSetter,
					) &&
					validateNumber(
						e.target[edanra ? 16 : 4],
						e.target[edanra ? 16 : 4].id + 'Error',
						focusSetter,
					) &&
					validateSelection(
						e.target[edanra ? 17 : 5],
						e.target[edanra ? 17 : 5].id + 'Error',
						focusSetter,
					) &&
					ignoreEdanraValidation(6, edanra, validateSelection, e) &&
					ignoreRegularValidation(18, edanra, validateSelection, e) &&
					ignoreRegularValidation(19, edanra, validateName, e) &&
					validateSelection(
						e.target[edanra ? 20 : 7],
						e.target[edanra ? 20 : 7].id + 'Error',
						focusSetter,
					) &&
					validateNumber(
						e.target[edanra ? 21 : 8],
						e.target[edanra ? 21 : 8].id + 'Error',
						focusSetter,
					) &&
					validateSelection(
						e.target[edanra ? 22 : 9],
						e.target[edanra ? 22 : 9].id + 'Error',
						focusSetter,
					) &&
					validateName(
						e.target[edanra ? 23 : 10],
						e.target[edanra ? 23 : 10].id + 'Error',
						focusSetter,
					) &&
					ignoreRegularValidation(24, edanra, validateName, e) &&
					validateDescription(
						e.target[edanra ? 25 : 12],
						e.target[edanra ? 25 : 12].id + 'Error',
						focusSetter,
					) &&
					checkImagesLength(len, 4, imageError);
	};

	const validateCancelBooking = e => {
		return (
			validateSelection(
				e.target[0],
				e.target[0].id + 'Error',
				focusSetter,
			) &&
			validateName(e.target[1], e.target[1].id + 'Error', focusSetter)
		);
	};

	const validateChangePassword = e => {
		return (
			validatePassword(
				e.target[0],
				e.target[0].id + 'Error',
				focusSetter,
			) &&
			validateSpecialPassword(
				e.target[1],
				e.target[1].id + 'Error',
				focusSetter,
			) &&
			validateConfirmPassword(
				e.target[2],
				e.target[2].id + 'Error',
				focusSetter,
			)
		);
	};

	const validateEdanraNss = target => {
		return (
			validateNameStrict(
				target[0],
				target[0].id + 'Error',
				focusSetter,
			) &&
			validateSelection(target[1], target[1].id + 'Error', focusSetter) &&
			validateSelection(target[2], target[2].id + 'Error', focusSetter) &&
			validateContact(target[3], target[3].id + 'Error', focusSetter) &&
			validateEMail(target[4], target[4].id + 'Error', focusSetter) &&
			validateName(target[5], target[5].id + 'Error', focusSetter) &&
			validateSelection(target[6], target[6].id + 'Error', focusSetter) &&
			validateSelection(target[7], target[7].id + 'Error', focusSetter)
		);
	};

	return {
		val,
		setVal,
		validateSignIn,
		changeFunc,
		blurFunc,
		setError,
		validateSignUp,
		validateSearch,
		validateHostDetails,
		validatePropertyDetails,
		validatePropertyLook,
		validatePropertyStatus,
		validateEditProfile,
		validateEditWithPass,
		validateBookingDetails,
		validateHostBookDetails,
		validateEditListing,
		checkAmenitiesError,
		validateCancelBooking,
		validateChangePassword,
		validateEdanraNss,
	};
};
