import { all, put, call, takeLatest } from 'redux-saga/effects';
import ManageAdTypes from './manage-ad.types';
import { auth, firestore } from '../../firebase/firebase.config';
import { convertPropertySnapshotToMap } from '../../firebase/firebase.helpers';
import {
	deletePropertyFailure,
	deletePropertySuccess,
	fetchIncompleteProperties,
	fetchUserPropertiesFailure,
	fetchUserPropertiesSuccess,
} from './manage-ad.actions';
import { fetchHomeOwnerBookedSuccess } from '../booking-data/booking-data.actions';

//  Fetch User's Ads
function* fetchProperties() {
	try {
		const propertiesRef = firestore.collection('properties');
		const userProperties = yield propertiesRef.where(
			'user_id',
			'==',
			auth.currentUser.uid,
		);
		const userPropertiesSnapshot = yield userProperties.get();
		const transformedData = yield call(
			convertPropertySnapshotToMap,
			userPropertiesSnapshot,
		);
		const completeProperties = transformedData.filter(
			property => property.isComplete === true,
		);
		const inCompleteProperties = transformedData.filter(
			property => property.isComplete === false,
		);
		const homeOwnerBookedProperties = completeProperties.filter(
			property => {
				const propertyClass = property.property_class || 'Regular Ad';
				const bookings = property.bookings || [];
				if (propertyClass === 'Edanra Stay' && bookings.length >= 1) {
					return property;
				}

				return null;
			},
		);
		yield put(fetchUserPropertiesSuccess(completeProperties));
		yield put(fetchIncompleteProperties(inCompleteProperties));
		yield put(fetchHomeOwnerBookedSuccess(homeOwnerBookedProperties));
	} catch (error) {
		yield put(fetchUserPropertiesFailure(error.message));
	}
}

function* onFetchUserProperties() {
	yield takeLatest(
		ManageAdTypes.FETCH_USER_PROPERTIES_START,
		fetchProperties,
	);
}

//  Delete Property
function* deleteProperty({ payload }) {
	try {
		yield firestore.collection('properties').doc(payload).delete();
		yield put(deletePropertySuccess());
	} catch (error) {
		yield put(deletePropertyFailure(error.message));
	}
}

function* onDeletePropertyStart() {
	yield takeLatest(ManageAdTypes.DELETE_PROPERTY_START, deleteProperty);
}

export function* manageAdSagas() {
	yield all([call(onFetchUserProperties), call(onDeletePropertyStart)]);
}
