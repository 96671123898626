export const useForm = (values, setValues) => {
	const defaultImage = require('../assets/images/avatar2.png');
	const editedDefaultImageUrl = defaultImage.default.replace(
		'data:image/png;base64,',
		'',
	);

	const handleChange = e => {
		const { name, value, type, multiple } = e.target;
		if (type === 'checkbox') {
			e.target.checked
				? setValues({
						...values,
						[name]: value,
				  })
				: setValues({ ...values, [name]: '' });
		} else if (type !== 'file') {
			setValues({
				...values,
				[name]: value,
			});
		} else {
			if (multiple) {
				const files = e.target.files;
				setValues({
					...values,
					[name]: Array.from(files),
				});
			} else {
				const file = e.target.files[0];
				setValues({
					...values,
					[name]: file,
				});
			}
		}
	};

	const resetImg = () => {
		setValues({
			...values,
			profile_img: editedDefaultImageUrl,
		});
	};

	const setImg = output => {
		setValues({
			...values,
			profile_img: output.file,
		});
	};

	const removeConfirmPasswordError = (
		passwordName,
		confirmPasswordName,
		val,
		setVal,
		id,
	) => {
		setVal({ ...val, [`${id}Error`]: '' });
		document.querySelector(`#${id}`).classList.remove('error-border');
	};

	const addConfirmPasswordError = (
		passwordName,
		confirmPasswordName,
		val,
		setVal,
		id,
	) => {
		setVal({ ...val, [`${id}Error`]: 'Passwords do not match' });
		document.querySelector(`#${id}`).classList.add('error-border');
	};
	// check if confirm password matches
	const checkConfirmPassword = (
		passwordName,
		confirmPasswordName,
		val,
		setVal,
		id,
	) => {
		values[passwordName] === values[confirmPasswordName] &&
			removeConfirmPasswordError(
				passwordName,
				confirmPasswordName,
				val,
				setVal,
				id,
			);
		!!values[confirmPasswordName] &&
			values[passwordName] !== values[confirmPasswordName] &&
			addConfirmPasswordError(
				passwordName,
				confirmPasswordName,
				val,
				setVal,
				id,
			);
	};

	return {
		form: values,
		handleChange,
		setImg,
		resetImg,
		checkConfirmPassword,
	};
};
